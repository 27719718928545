import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button as ButtonSemantic,
  Icon,
  SemanticSIZES,
  ButtonProps,
} from "semantic-ui-react";

interface Props {
  message?: string;
  icon?: any;
  disabled?: boolean;
  color?: any;
  children?: any;
  onClick(): any;
  size?: SemanticSIZES;
  autoLoading?: boolean;
}

export const Button: React.FC<Props & ButtonProps> = (props) => {
  const [selfLoading, setSelfLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ButtonSemantic
        color={props.color ? props.color : !props.negative ? "red" : undefined}
        icon={props.icon ? true : undefined}
        labelPosition={props.icon ? "left" : undefined}
        size={props.size || "massive"}
        className="w-100 my-3"
        disabled={props.disabled}
        onClick={async () => {
          props.autoLoading && setSelfLoading(true);
          await props.onClick();
          props.autoLoading && setSelfLoading(false);
        }}
        loading={props.loading || selfLoading}
      >
        {props.icon !== undefined && <Icon name={props.icon} />}
        {props.message ? t(props.message) : props.children}
      </ButtonSemantic>
    </>
  );
};
