import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";
import {
  AuthorizationModel,
  defaultsAuthorizationModel,
} from "../../models/Authorization";
import { fetchMSGraph } from "../../util/auth";
import AuthLoading from "../Auth/AuthLoading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { authConfig } from "../../configs/authConfig";

interface LoginProps {}

const Login: FC<LoginProps> = ({}: LoginProps): ReactElement => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState<AuthorizationModel>(
    defaultsAuthorizationModel
  );

  useEffect(() => {
    (async () => {
      try {
        await fetch(
          `${authConfig.adfs.identity.authority}oauth2/authorize/?client_id=${authConfig.adfs.identity.client_id}&redirect_uri=http%3A%2F%2Fhttps://proud-cliff-093531c03.2.azurestaticapps.net%3A3000%2Fsignin-callback.html&response_type=id_token%20token&scope=api%3A%2F%2Fbackoffice-api.in2tant.cloud&state=64969b1541474987947d45e0da016b19&nonce=3bcd3edb9e424e80ad6f5276aca28a19&client-request-id=fb140e4a-4f6e-456a-8004-0080000000e0&RedirectToIdentityProvider=AD+AUTHORITY`
        );
        const response = await authContext.getAccount();
        const aux: AuthorizationModel = JSON.parse(response);
        setUser(aux);

        if (user.id_token.length === 0) {
          authContext.onSignIn();
        }
      } catch {
        toast(t("home.login.error"), { type: "error"});
      }
    })();
  }, [authContext]);

  return (
    <Fragment>
      {user.id_token !== "" ? (
        <AuthLoading />
      ) : (
        <div style={{ background: "#333" }}></div>
      )}
    </Fragment>
  );
};

export default Login;
