import moment from "moment";
import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import i18n from "../../../configs/i18n";
import { es, ca } from "date-fns/locale";
import { FaCalendarAlt } from "react-icons/fa";


interface IAreaAdministrationServiceDetailsMeetingFormDate {
    date: any,
    setDate: any
}




const AreaAdministrationServiceDetailsMeetingFormDate = (props: IAreaAdministrationServiceDetailsMeetingFormDate) => {

    const [startDate, setStartDate] = useState(new Date());

    const occitano = ca;
    const getLocale = () => {
        switch (i18n.language) {
            case "es":
                return es;
            case "ca":
                return ca;
            case "oc":
                return occitano;
            case "en":
                return undefined;
            default:
                return ca;
        }
    };

    const CustomInput = ({ value, onClick }: any) => (
        <div className="date-picker-input" onClick={onClick}>
            <span className="date-picker-value">{value}</span>
            <FaCalendarAlt className="date-picker-icon" />
            <style>
                {`
              .date-picker-input {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 7px;
                border: 1px solid #ccc;
                border-radius: 4px;
                background-color: #fff;
                width: 100%;
                height: 100%;
                min-height: 41px;
              }
      
              .date-picker-value {
                margin-right: 5px;
              }
      
              .date-picker-icon {
                margin-left: auto;
              }
              .react-datepicker {
                font-size: 1em;
              }
      
              .react-datepicker__month {
                margin: 0.4em 1em;
              }
              .react-datepicker__day-name, .react-datepicker__day {
                width: 1.9em;
                line-height: 1.9em;
                margin: 0.166em;
              }
              .react-datepicker__current-month {
                font-size: 1em;
              }
            `}
            </style>
        </div>
    );

    const handleDateChange = (newDate: Date) => {
        setStartDate(newDate);
        props.setDate(moment(newDate).format("YYYY-MM-DD"));
    };

    const handleDayChange = (days: number) => {
        const updatedDate = moment(startDate).add(days, "days").toDate();
        handleDateChange(updatedDate);
    };

    return (
        <div id="area__details__form__meeting">

            <Button
                icon
                color="red"
                onClick={() => handleDayChange(-1)}
            >
                <Icon name="caret left" />
            </Button>
            <DatePicker
                selected={startDate}
                onChange={(date) => handleDateChange(date as Date)}
                locale={getLocale()}
                dateFormat="P"
                customInput={<CustomInput />}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
            <Button
                icon
                color="red"
                onClick={() => handleDayChange(1)}
            >
                <Icon name="caret right" />
            </Button>
        </div>
    )
}

export default AreaAdministrationServiceDetailsMeetingFormDate;