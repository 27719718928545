import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

import { EEventInTime, EMeetingStatus, IEventDetailAdapter } from "../../../models/event.models";

import Modal from "../../../components/Modal/Modal";
import { Button, Icon } from "semantic-ui-react";
import { cancelMeeting } from "../controllers/area_service_details.controller";

interface IProps{
    meeting: IEventDetailAdapter,
    shared: string,
    setReload: any
}

const AreaAdministrationServiceDetailsMeetingCancel = ({ meeting, shared, setReload }: IProps) =>{ 
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    return (
        <Modal 
            size={"mini"}
            open={open}
            setOpen={setOpen}
            title={t("detail.meeting.delete.title")}
            content={
                <>
                    <span>{t("detail.meeting.delete.question")}</span>
                    <div style={{
                        background: '#ffe8e6',
                        color: '#db2828',
                        boxShadow: 'inset 0 0 0 1px #db2828, 0 0 0 0 transparent',
                        margin: '1em 0',
                        padding: '1em 1.5em',
                        lineHeight: '1.4285em',
                        borderRadius: '.28571429rem',
                        textAlign: 'center'
                    }}>
                        {meeting.Code}
                    </div>
                </>
            }
            trigger={
                <Button 
                    icon
                    color="red"
                    disabled={meeting.InTime !== EEventInTime.EarlyAccess || meeting.Status === EMeetingStatus.CANCELLED}    
                >
                    <Icon name="cancel" />
                </Button>
            }
            actions={
                <>
                <Button color="grey" onClick={() => setOpen(false)}>{t('btn.cancel')}</Button>
                <Button color="red" onClick={() => {
                    cancelMeeting(meeting.Code, meeting.Slot, shared, dispatch, callEndpoint)
                    setOpen(false)
                    setReload(true)
                }}>{t('btn.delete')}</Button>
                </>
            }
        />
    )
}

export default AreaAdministrationServiceDetailsMeetingCancel;
