import React, {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../../context/LayoutContext";
import {
  fetchGetAllUserInOrganitzations,
  selectOrganizationsAllUsers,
} from "../../../assets/organizations";
import { AuthContext } from "../../../context/AuthContext";
import Layout from "../../Layout/LayoutContainer";
import { Button } from "../../Button/Button";
import {
  Dimmer,
  Form,
  Input,
  InputOnChangeData,
  Loader,
  Segment,
} from "semantic-ui-react";
import { UserInfo } from "../../../models/User";
import TableOrganitzationUser from "./TableOrganitzationUser";

interface RouterParams {
  organitzationCode: string;
}
interface OrganitzationUserProps extends RouteComponentProps<RouterParams> {}

const OrganitzationUser: FC<OrganitzationUserProps> = ({
  match,
}: OrganitzationUserProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const arrayUsers = useSelector(selectOrganizationsAllUsers);
  const { setTitle, setBreadcrumb } = layoutContext;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchUser, setSearchUser] = useState<string>("");
  const [resultsUsers, setResultsUsers] = useState<UserInfo[]>([]);
  const [token] = useState<string>(authContext.account.access_token);
  const [organitzationCode] = useState<string>(match.params.organitzationCode);
  const history = useHistory();

  useEffect(() => {
    setTitle(t("organitzation.user.panel.title"));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("organitzation.user.panel.title"),
        link: `/organitzation/${organitzationCode}/user`,
      },
    ]);
  }, [t, setTitle, setBreadcrumb]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchGetAllUserInOrganitzations(token));
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const results = arrayUsers.filter((user: UserInfo) => {
      if(user.name !== null && user.name.length > 0)
        return user.name.toLowerCase().includes(searchUser.toLocaleLowerCase())
    });
    setResultsUsers(results);
    setIsLoading(false);
  }, [searchUser, arrayUsers]);

  const arrayButtons = [
    {
      icon: "user plus",
      message: t("organitzation.user.panel.btn.add"),
      onClick: () =>
        history.push(
          `/organitzation/${authContext.account.profile.organization}/addUser`
        ),
    },
    {
      icon: "user plus",
      message: t("organitzation.user.panel.btn.csv"),
      onClick: () =>
        history.push(
          `/organitzation/${authContext.account.profile.organization}/addUsers`
        ),
    },
    {
      icon: "arrow left",
      message: t("go_back"),
      onClick: () => history.push(`/organitzation`),
    },
  ];

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setSearchUser(data.value);
  };

  return (
    <Fragment>
      <Layout.Main>
        <div id={"co-user"} className="justify-content-center">
          <Input
            label={{ icon: "search" }}
            labelPosition={"right"}
            className={"d-flex"}
            onChange={handleChange}
            value={searchUser}
            placeholder={t("organitzation.user.panel.placeholder")}
          />
          {isLoading ? (
            <Segment padded={"very"} size={"massive"}>
              <Dimmer active inverted>
                <Loader size="medium">{t("loading.defect")}</Loader>
              </Dimmer>
            </Segment>
          ) : (
            <TableOrganitzationUser arrayUsers={resultsUsers} />
          )}
        </div>
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
    </Fragment>
  );
};

export default OrganitzationUser;
