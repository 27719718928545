import { useState } from "react";
import {  Modal as SemanticModal } from "semantic-ui-react";

interface IProps{
    title: string
    content: any
    open: boolean,
    setOpen: any,
    actions?: any,
    trigger?: any
    size?: string
}

const Modal = ({ title, trigger, content, actions, open, setOpen, size }: IProps) => {

    return (
        <SemanticModal
            size={size}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
        >
            <SemanticModal.Header>{title}</SemanticModal.Header>
            <SemanticModal.Content>{content}</SemanticModal.Content>
            <SemanticModal.Actions>{actions}</SemanticModal.Actions>
        </SemanticModal>
    )
}
export default Modal;