import React, { FC, ReactElement, useEffect, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import BOModalAssignUser from "../BOModalAssignUser/BOModalAssignUser";
import { MeetingQueueTable } from "../BOTableMeetingQueue";
import { User } from "../../../../../models/User";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { EnumMeetingStatus } from "../../../../../enum/EnumMeetingStatus";
import { useSelector } from "react-redux";
import { selectServiceUsers } from "../../../../../assets/services";
import { EventMeeting } from "../../../../../models/EventMeeting";
import { statusMeeting } from "../../../../../util/meeting";

interface BOPopupUserProps {
  meeting: EventMeeting;
  servicesRefresh?: () => void;
}


const BOPopupUser: FC<BOPopupUserProps> = ({
  meeting,
  servicesRefresh,
}: BOPopupUserProps): ReactElement => {
  const { t } = useTranslation();
  const [iconColor, setIconColor] = useState<SemanticCOLORS>("red");
  const [css, setCSS] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const serviceUsers = useSelector(selectServiceUsers);

  useEffect(() => {
    switch (meeting.assignedTo) {
      default:
        if (
          meeting.status === t("detail.status.cancelled") ||
          meeting.status === t("detail.status.finalized")
        ) {
          setIconColor("black");
          setCSS("justify-content-center");
          setUser(t("detail.meeting.user_assigned_cannot"));
        } else {
          setIconColor("green");
          setCSS("justify-content-between align-items-center");
          serviceUsers.map((useru: User) => {
            var trobat : boolean = false;
            for (let i = 0; i <  user.length; i++) {
              if (user[i] === useru.name) trobat = true;
            }
            if (useru.code === meeting.assignedTo && !trobat) {
                setUser(useru.name);
            }
          });
        }
        break;

      case "NotAssigned":
        setIconColor("red");
        setCSS("justify-content-between align-items-center");
        setUser(t("detail.meeting.user_assigned_no"));
        break;
    }
  }, [meeting]);
  return (
    <div className={"d-flex " + css}>
      <Popup
        trigger={<Icon circular inverted color={iconColor} name={"user"} />}
        position="right center"
      >
        <Popup.Header>{t("detail.meeting.title_assigned")}</Popup.Header>
        <Popup.Content>{user}</Popup.Content>
      </Popup>
      {meeting.status !== t(statusMeeting("Cancelled")) &&
        meeting.status !== t(statusMeeting("Finalized")) && (
          <BOModalAssignUser
            meeting={meeting}
            servicesRefresh={servicesRefresh}
          />
        )}
    </div>
  );
};
export default BOPopupUser;
