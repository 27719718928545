import { EnumRol } from "../enum/EnumRol";
import {
  Authorization,
  AuthorizationModel,
  defaultsAuthorizationModel,
} from "../models/Authorization";

export class AuthorizationBase implements Authorization {
  account: AuthorizationModel;
  authenticated: boolean;
  licence: string;
  roles: EnumRol[];
  scopes: string[];
  accountStatus: any;
  pushNotificationToken: string;

  constructor() {
    this.licence = "";
    this.authenticated = false;
    this.roles = [];
    this.scopes = [];
    this.accountStatus = "";
    this.pushNotificationToken = "";
    this.account = defaultsAuthorizationModel;
  }

  onSignIn() { }
  onSignOut() { }

  licenceHealthCheck() { }

  setLicenceToken = (licence: string) => {
    this.licence = licence;
  };


  setPushNotificationToken = (pushNotificationToken: string) => {
    this.pushNotificationToken = pushNotificationToken;
  };
  async getAccount() {
    return "";
  }

  async getTokenForScopes() {
    return { backoffice: "", shared: "" };
  }

  async onAccountStatus(status: string) { }
}
