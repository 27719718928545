import React, { Fragment, useContext, useEffect } from "react";
import Layout from "../../Layout/LayoutContainer";
import { Button, Checkbox, Dimmer, Form, Loader } from "semantic-ui-react";
import { LayoutContext } from "../../../context/LayoutContext";
import { useTranslation } from "react-i18next";
import RichTextEditorField from "./components/RichTextEditorField";
import TextField from "./components/TextField";
import { emailEventType } from "./types";
import TabListView from "../../TabListView";
import TabEmail from "./components/TabEmail";
import TabPreferences from "./components/TabPreferences";
import TabSMS from "./components/TabSMS";
import TabNotification from "./components/TabNotification";

interface EntityForm {
  file: File;
  entityName: string;
  entityEmailConfirmationBody: string;
  entityEmailLinkBody: string;
  entitySmsConfirmationBody: string;
}

interface Props {
  emailEventFields: emailEventType;
  onChangeFields: (x: any) => void;
  decodeMessages: (x: emailEventType) => void;
  init: () => void;
  loading: boolean;
  values: any;
  organitzationURL: string;
  setOrganitzationURL: (x: string) => void;
  organitzationURLInformers: string;
  setOrganitzationURLInformers: (x: string) => void;
  isRecording: boolean;
  updateRecordingsForOrganization: () => void;
  historyHandleClick: () => void;
  sendExtern: boolean;
  updateSendExternNotifications: () => void;
  handleSubmit: () => void;
  sendReminders: boolean;
  updateSendingReminders: () => void;
  setReminderTime: (x: string) => void;
  reminderTime: string;
}

export const EntityManagement = (props: Props) => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  useEffect(() => {
    setTitle(t("organitzation.personalitzation.title"));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("organitzation.personalitzation.title"),
        link: "/organitzation/settings",
      },
    ]);
    props.init();
  }, [t, setTitle, setBreadcrumb]);

  const {
    entityEmailConfirmationBody,
    entityEmailLinkBody,
    entitySmsConfirmationBody,
  } = props.values as EntityForm;
  return (
    <>
      {props.loading ? (
        <Loader active inline="centered" size="medium">
          {t("loading.defect")}
        </Loader>
      ) : (
        <Layout.FullScreen>
          <Form>
            <TabListView
              tabsConfig={[
                {
                  title: t("organitzation.personalitzation.tab_notification"),
                  accessor: "notification",
                  contentView: (
                    <TabNotification
                      onChangeFields={props.onChangeFields}
                      sendExtern={props.sendExtern}
                      updateSendExternNotifications={props.updateSendExternNotifications}
                    />
                  ),
                },
                {
                  title: t("organitzation.personalitzation.tab_email"),
                  accessor: "email",
                  contentView: (
                    <TabEmail
                      onChangeFields={props.onChangeFields}
                      emailEventFields={props.emailEventFields}
                    />
                  ),
                },
                {
                  title: t("organitzation.personalitzation.tab_sms"),
                  accessor: "sms",
                  contentView: (
                    <TabSMS
                      onChangeFields={props.onChangeFields}
                      emailEventFields={props.emailEventFields}
                    />
                  ),
                },
                {
                  title: t("organitzation.personalitzation.tab_preferences"),
                  accessor: "preferences",
                  contentView: (
                    <TabPreferences
                      onChangeFields={props.onChangeFields}
                      organitzationURL={props.organitzationURL}
                      setOrganitzationURL={props.setOrganitzationURL}
                      organitzationURLInformers={props.organitzationURLInformers}
                      setOrganitzationURLInformers={props.setOrganitzationURLInformers}
                      isRecording={props.isRecording}
                      updateRecordingsForOrganization={props.updateRecordingsForOrganization}
                      sendReminders={props.sendReminders}
                      updateSendingReminders={props.updateSendingReminders}
                      setReminderTime={props.setReminderTime}
                      reminderTime={props.reminderTime}
                    />
                  ),
                },
              ]}
            />

            <Button
              negative
              content={t("go_back")}
              icon="left arrow"
              labelPosition="left"
              onClick={props.historyHandleClick}
            />

            <Button type="submit" positive onClick={props.handleSubmit}>
              {t("organitzation.personalitzation.button")}
            </Button>
          </Form>
        </Layout.FullScreen>
      )}
    </>
  );
};
