import { useState } from "react";

export const useForm = (initialState: any = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }: any, data: any) => {
    if (target.value != undefined && target.name) {
      setValues({
        ...values,
        [target.name]: target.value,
                
      });
    } else if (data.name && data.checked != undefined) {
      setValues({
        ...values,
        [data.name]: data.checked,
      });
    }
  };

  return [values, setValues, handleInputChange, reset];
};
