import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Input, Placeholder } from "semantic-ui-react";
import { EnumSearchOption } from "../../../../../../pages/Dashboard/components/MeetingContainer";
interface Props {
  onHandleChange: (x: any) => void;
}

const FilterMeeting = (props: Props) => {
  const { t } = useTranslation();
  const defaultFilter = [EnumSearchOption.ALL, EnumSearchOption.CodeMeeting];

  const getOption = (option: string) => ({
    key: option,
    text: t(`home.search.label.${option.toLowerCase()}`),
    value: option,
  });
  const options: any = /*props.contentFilter || */ defaultFilter.map(getOption);
  const [form, setForm] = useState({
    typeFilter: options[0].key,
    valueFilter: "",
  });
  // const options = [
  //   {
  //     key: EnumSearchOption.ALL,
  //     text: t("home.search.label.all"),
  //     value: EnumSearchOption.ALL,
  //   },
  //   {
  //     key: EnumSearchOption.CodeMeeting,
  //     text: t("detail.meeting.title.code"),
  //     value: EnumSearchOption.CodeMeeting,
  //   },
  // ];
  return (
    <Input
      className={"pt-3"}
      label={
        <Dropdown
          defaultValue={EnumSearchOption.ALL}
          options={options}
          onChange={(event, data) => {
            const typeFilter: any = data.value;
            const newForm = { ...form, typeFilter };
            setForm(newForm);
            props.onHandleChange(newForm);
          }}
        />
      }
      labelPosition={"right"}
      onChange={(event) => {
        const valueFilter = event.target.value;
        const newForm = { ...form, valueFilter };
        setForm(newForm);
        props.onHandleChange(newForm);
      }}
      value={form.valueFilter}
      placeholder={Placeholder}
    />
  );
};
export default FilterMeeting;
