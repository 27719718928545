import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
type Area = {
  key: number;
  value: string;
  text: string;
};
interface Props {
  header: string;
  areas: Area[];
  areaSelect: string;
  onChange: (x: any, y?: any) => void;
  classNameContainer?: string;
  styleContainer?: any;
}

export default (props: Props) => {
  return (
    <div
      className={`d-col px-0 mt-2 align-items-center mr-2 ${
        props.classNameContainer || ""
      }`}
      style={props.styleContainer}
    >
      <label>{props.header}</label>
      <Dropdown
        className={"dropdown-area br04 w-100"}
        selection
        clearable
        multiple
        options={props.areas}
        onChange={props.onChange}
        value={props.areaSelect}
      />
    </div>
  );
};
