import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List as ListUI, Popup, Table } from "semantic-ui-react";
import { documentMeeting } from '../../../../../../../../../pages/AreaAdministrationServicesDetails/controllers/area_service_details.controller';
import { AuthContext } from '../../../../../../../../../context/AuthContext';
import useFetchAndLoad from '../../../../../../../../../hooks/useFetchAndLoad';
import { toast } from 'react-toastify';
interface Props {
    meeting: any;
};

const DownloadInfoAction = (props: Props) => {
    const { t } = useTranslation();
    const { callEndpoint } = useFetchAndLoad();

    const authContext = useContext(AuthContext);
  
    const getInfoData = async () => {
        documentMeeting(props.meeting.code, authContext.account.access_token, callEndpoint).then(response => {
            const url = window.URL.createObjectURL(new Blob([(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.meeting.code+'.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
    },
    error=> {
        console.log(error);
        toast(t("document.toast"), { type: "error"});

    })
}





    return (<Popup
        trigger={
            <Button
                color={"red"}
                size={"small"}
                onClick={getInfoData}
                icon={"file pdf outline"}
            />
        }
        position={"right center"}
        >
            <Popup.Content>
                {t("document.label")}
            </Popup.Content>
        </Popup>)
}

export default DownloadInfoAction