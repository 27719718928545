import React, { useContext, useEffect, useState } from "react";
import Screen from "./Screen";
import { useTranslation } from "react-i18next";
import { useError } from "../../../hooks/useError";
import { AuthContext } from "../../../context/AuthContext";
import { LayoutContext } from "../../../context/LayoutContext";
import { useForm } from "../../../hooks/useForm";
import { toast } from "react-toastify";
import {
  addOrganization,
  editOrganization,
  getAdminOrganization,
} from "../../../assets/api";
import { useHistory } from "react-router-dom";
import {
  getOrganization,
  getOrganizationAdministrators,
  getOrganizations,
} from "../../../assets/personalitzation";
import { Mailbox } from "react-bootstrap-icons";
import organizations from "../../../assets/organizations";
interface Props {
  match: any;
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const userCode = authContext.account.profile.code;
  const userEmail = authContext.account.profile.email;
  const token = authContext.account.access_token;
  const { setTitle, setBreadcrumb } = layoutContext;
  const { error, setError } = useError();
  const { t } = useTranslation();
  const history = useHistory();


  const updateAdministrators = async () => {
    const administrators = await getOrganizationAdministrators(
      organitzationCode,
      token
    ).then((_) => _.json());
    setAdministrators(administrators);
  };

  const updateConsultors = async () => {
    console.log("JSON")
    try {
    const consultors = await getOrganizations(
      userCode,
      token
    ).then((res) => res.json());
    }
    catch (error) {
      console.log("error", error);
    }
    console.log("Cons", consultors);
    setConsultors(consultors);
  };

  const defaultForm: any = {
    displayNameCA: "",
    displayNameES: "",
    displayNameEN: "",
    displayNameOC: "",
    email: "",
    phone: "",
    url: "",
    active: false,
  };

  const [form, setForm, handleInputChange, reset] = useForm(defaultForm);
  const [organization, setOrganization] = useState<any>({});
  const [administrators, setAdministrators] = useState<any>([]);
  const [consultors, setConsultors] = useState<any>([]);
  const [organitzationCode] = useState(props.match.params.organizationCode);
  useEffect(() => {
    (async () => {
      const organization = await getOrganization(organitzationCode, token).then(
        (_) => _.json()
      );
      setOrganization(organization);
      setForm({
        displayNameCA:
          organization.languages.find((_: any) => _.culture == "ca")
            ?.displayName || "",
        displayNameES:
          organization.languages.find((_: any) => _.culture == "es")
            ?.displayName || "",
        displayNameEN:
          organization.languages.find((_: any) => _.culture == "en")
            ?.displayName || "",
        displayNameOC:
          organization.languages.find((_: any) => _.culture == "oc")
            ?.displayName || "",
        email: organization.mail,
        phone: organization.phone,
        url: organization.url,
        
       
        active: organization.active,
      });
      updateAdministrators();
      updateConsultors();
    })();
  }, []);

  useEffect(() => {
    setTitle(t("administration.edit_organization.title"));
    setBreadcrumb([
      {
        name: t("administration.title"),
        link: "/administration",
      },
      {
        name: t("administration.edit_organization.title"),
      },
    ]);
  }, [t, setTitle, setBreadcrumb]);
  const handleSubmit = async () => {
    const getCulture = (id: number) => {
      switch (id) {
        case 0:
          return "ca";
        case 1:
          return "es";
        case 2:
          return "en";
        case 3:
          return "oc";
        default:
          return "ca";
      }
    };
    const {
      email,
      phone,
      url,
      active,
      displayNameCA,
      displayNameES,
      displayNameEN,
      displayNameOC,
    } = form;
    const languages = [displayNameCA, displayNameES, displayNameEN, displayNameOC];
    const formatedLanguages = languages.map((displayName, index) => ({
      id: organization.languages.find(
        (_: any) => _.culture == getCulture(index)
      )?.id,
      culture: getCulture(index),
      displayName,
    }));
    const bodyCreateOrganization = {
      ...organization,
      code: organitzationCode,
      email,
      phone,
      url,
      active,
      displayName: displayNameCA,
      languages: formatedLanguages,
      recordMeetingEnabled: true,
    };
    const token = authContext.account.access_token;
    try {
      const response = await editOrganization(
        organitzationCode,
        bodyCreateOrganization,
        token
      );
      if (!response.ok) {
        throw Error;
      }
      toast(t("administration.edit_organization.success"), { type: "success"});
      history.push(`/administration`);
    } catch (error) {
      toast(t("administration.edit_organization.error"), { type: "error"});
    }
  };

  const getFormat = (key: string) => {
    if (key == "phone") return "phone";
    else if (key == "email") return "email";
    else if (key == "active") return "boolean";
    else return "text";
  };

  const getRequired = (key: string) => {
    if (key == "code") return true;
    else if (key == "displayNameCA") return true;
    else if (key == "displayNameES") return true;
    else if (key == "displayNameEN") return true;
    else if (key == "displayNameOC") return true;
    else if (key == "name") return true;
    else if (key == "email") return true;
    else return false;
  };
  const getLabelInfo = (key: string) => {
    return {
      format: getFormat(key),
      required: getRequired(key),
      label: key == "phone" ? "+34" : undefined,
    };
  };

  const formatedCreated = (form: any) => {
    return Object.entries(form).map(([key, value]: any) => {
      const labelInfo: any = getLabelInfo(key);
      return {
        ...labelInfo,
        key,
        value,
      };
    });
  };
  return (
    <Screen
      {...props}
      handleSubmit={handleSubmit}
      form={formatedCreated(form)}
      handleInputChange={handleInputChange}
      organitzationCode={organitzationCode}
      administrators={administrators}
      updateAdministrators={updateAdministrators}
      consultors={consultors}
      updateConsultors={updateConsultors}
    />
  );
};
