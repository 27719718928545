import React from "react";
import Screen from "./Screen";
interface Props {
  header: string;
  children: any;
}

export default (props: Props) => {
  return <Screen {...props} />;
};
