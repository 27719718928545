import { EnumAPI } from "../../../enum/EnumAPI";
import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { ResultAPI, fetch } from "./API";

const URL_ORGANIZATIONS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Organizations}`;

export const postOrganizations = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_ORGANIZATIONS}`;
  const organizationPOST = {
    email: "",
    phone: "",
    url: "",
    recordMeetingEnabled: true,
    languages: [
      {
        culture: "ca",
        displayName: "Test",
      },
    ],
    active: true,
    displayName: "Test",
    code: "T001",
  };
  const body = JSON.stringify(organizationPOST);
  const result = fetch(
    urlAPI,
    token,
    "Crear Organizacion",
    "Error Crear Organizacion",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};

export const putOrganizations = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_ORGANIZATIONS}/T001`;
  const organitzationPUT = {
    email: "test@in2.com",
    phone: "+34666445533",
    url: "https://test.cloud",
    languages: [],
    areas: [],
    active: true,
    displayName: "Test",
    code: "T001",
  };
  const body = JSON.stringify(organitzationPUT);
  const result = fetch(
    urlAPI,
    token,
    "Modificar Organizacion",
    "Error Modificar Organizacion",
    EnumHTTPMethod.PUT,
    body
  );
  return result;
};

export const deleteOrganizations = async (
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_ORGANIZATIONS}/T001`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar Organizacion",
    "Error Eliminar Organizacion",
    EnumHTTPMethod.DELETE
  );
  return result;
};

export const addUserToOrganizations = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_ORGANIZATIONS}/T001/administrators`;
  const body = JSON.stringify({ code: userCode });
  const result = fetch(
    urlAPI,
    token,
    "Asignar Admin Organizacion",
    "Error Asignar Admin Organizacion",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};

export const deleteUserToOrganizations = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_ORGANIZATIONS}/T001/administrators/${userCode}`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar Admin Organizacion",
    "Error Eliminar Admin Organizacion",
    EnumHTTPMethod.DELETE
  );
  return result;
};
