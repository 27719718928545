import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button, List as ListUI, Popup, Table } from "semantic-ui-react";
import { ChangeHistory } from "../../../../../../../models/ChangeHistory";
import { statusMeeting } from "../../../../../../../util/meeting";
interface Props {
  index?: number;
  meeting: any;
  trigger: string;
}

const StatusCell = (props: Props) => {
  const { t } = useTranslation();
  const finalizedReason = (reasons: ChangeHistory[]) => {
    let p = "";
    reasons.forEach((r) => {
      p += r.changeInfo;
    });
    return p;
  };

  return (
    <>
        {props.trigger === 'Not Attendeed' || props.trigger === 'NotAttendeed' ? t('detail.status.not_attended') : props.trigger}
    </>
  );
};

export default StatusCell
