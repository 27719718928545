import { EnumRol } from "../enum/EnumRol";

export interface Authorization {
  account: AuthorizationModel;
  accountStatus: any;
  authenticated: boolean;
  licence: string;
  roles: EnumRol[];
  scopes: string[];
  onSignIn: () => void;
  onSignOut: () => void;
  getAccount: () => Promise<any>;
  onAccountStatus: (status: string) => void;
  getTokenForScopes: () => Promise<{}>;
  setLicenceToken: (T: string) => void;
  licenceHealthCheck: () => void;
}

export interface AuthorizationModel {
  id_token: string;
  session_state?: string;
  access_token: string;
  token_type?: string;
  scope?: string;
  profile: Profile;
  expires_at?: number;
}

export interface Profile {
  code: string;
  name: string;
  email: string;
  organization: string;
  roles: EnumRol[];
}

export const defaultsAuthorizationModel: AuthorizationModel = {
  id_token: "",
  access_token: "",
  profile: {
    code: "",
    name: "",
    email: "",
    organization: "",
    roles: [],
  },
};

export interface AccountMSAL {
  accountIdentifier: string;
  homeAccountIdentifier: string;
  userName: string;
  name: string;
  idToken: IDToken;
  idTokenClaims: IDToken;
  environment: string;
}

export interface IDToken {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  aio: string;
  groups: string[];
  name: string;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  roles: string[];
  sub: string;
  tid: string;
  uti: string;
  ver: string;
}

export interface UserInfoAPI {
  active: boolean;
  code: string;
  isAreaAdmin: boolean;
  isOrganizationAdmin: boolean;
  isCrossReader: boolean;
  isRoot: boolean;
  isTenantAdmin: boolean;
  mail: string;
  name: string;
  organizationCode: string;
  tenantCode: string;
}
