const ACTION = "action";
const createTypeAction = (content: any[]) => {
  return {
    type: ACTION,
    content,
  };
};
export const CONTENT_MEETING_SUB_TYPE = {
  ACTION: {
    MORE_INFO: "MORE_INFO",
    REFUSE: "refuse",
    DOCUMENT: "document",
    ASSIGNED_ATTENDERS: "ASSIGNED_ATTENDERS",
    CANCEL: "cancel",
    COMMENT_INFO: "comment",
    SELF_ASSIGN: "SELF_ASSIGN",
    REFUSE_SELF: "REFUSE_SELF",
    CANCEL_SELF: "CANCEL_SELF",
    DOWNLOAD_INFO: "DOWNLOAD_INFO",
    DOCUMENTS_SELF: "DOCUMENTS_SELF",
  },
};
export const CONTENT_MEETING_TYPE = {
  START: "start",
  FULL_START: "full-start",
  CODE: "code",
  DNI: "dni",
  STATUS: "status",
  SERVICE_CODE: "serviceCode",
  AREA_CODE: "area",
  ATTENDEE: "attendees",
  IS_QUEUED: "isQueued",
  ASSIGNED: "assignedTo",
  BASIC_ACTION: {
    type: ACTION,
    content: [
      CONTENT_MEETING_SUB_TYPE.ACTION.MORE_INFO,
    ],
  },
  ACTION: {
    type: ACTION,
    content: [
      // CONTENT_MEETING_SUB_TYPE.ACTION.MORE_INFO,
      CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL,
      CONTENT_MEETING_SUB_TYPE.ACTION.ASSIGNED_ATTENDERS,
      CONTENT_MEETING_SUB_TYPE.ACTION.DOWNLOAD_INFO,
      CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENTS_SELF
    ],
  },
  createTypeAction,
};
