import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Button as ButtonSemantic, Modal as UIModal  } from "semantic-ui-react";
import { Documentation } from "../../../../../models/DocumentationObject";
import { AuthContext } from "../../../../../context/AuthContext";
import { getMeetingDocument } from "../../../../../assets/api";
import { saveAs } from 'file-saver';

interface Props {
  headerText?: string;
  codeText?: string;
  documents: Documentation[];
  meeting:any;
  isSelf:any;
}

const Screen = (props: Props) => {
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);
    const userCode = authContext?.account?.profile?.code;
  const isSelfAssigned = props.meeting.assignedTo === userCode

    const download = async (info: any) => {
        try {
            const { shared } = await authContext.getTokenForScopes();
            const documentationInfo: Response = await getMeetingDocument(info, shared);
            const blob = await documentationInfo.blob();
            saveAs(blob, info.split('/')[info.split('/').length - 1]);
        } catch (error) {
            console.error("Error downloading document:", error);
        }
    };


  const view = async (info: any) => {
    const { shared } = await authContext.getTokenForScopes();
    const documentationInfo: Response = await getMeetingDocument(
      info,
      shared
    );
    const file = await documentationInfo.blob();
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow!.location.href = fileURL;
  }
  const { t } = useTranslation();
  return (
    <UIModal
      id={"modal-assign-user"}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button        
      disabled={(!props.meeting.isDocumentationAvailable)}
      color={"red"} icon={"file"} />}
    >
            <UIModal.Header>
        <h2>{t("home.documentModel.header")}</h2>
      </UIModal.Header>
      <UIModal.Content>
        {props.codeText && (
          <p>
            {t("home.documentModel.subheader")}
            <strong style={{ color: "red" }}>{props.codeText}</strong>
          </p>
        )}
        <ul>
          {props.documents
            ?.slice(0)
            .map((document) => {
              return (

                <div>·{document.name}
                  <Button onClick={() => download(document.link)} floated="right" inverted positive icon={"download"}></Button>
                  <Button onClick={() => view(document.link)} floated="right" inverted positive icon={"eye"}></Button>
                  <hr /></div>
              )
            })}
        </ul>
      </UIModal.Content>
      <UIModal.Actions>
        <ButtonSemantic
          negative
          content={t("home.documentModel.cancel")}
          onClick={() => setOpen(false)}
        />
      </UIModal.Actions>
      </UIModal>
  );
};

export default Screen;
