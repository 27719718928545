import React from "react";
import Card from "../../../../components/Card/Card";
import { ServiceList } from "../ServiceList";
import { Button as SemButton } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
interface Props {
  services: any;
  finish: any;
  service: any;
  refreshServicesList: any;
  isLoading: boolean;
  servicesWithQueue: any;
  getSelectService: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      {!props.services.length ? (
        <Card>
          <Card.Body>
            <h2>{t("home.no_services")}</h2>
          </Card.Body>
        </Card>
      ) : (
        <div>
          <SemButton
            negative
            content={t("refresh")}
            icon={"refresh"}
            onClick={props.refreshServicesList}
          />
          <ServiceList
            services={props.servicesWithQueue}
            serviceSelected={props.getSelectService}
            isLoading={props.isLoading}
          />
        </div>
      )}
    </div>
  );
};
