import { RouteComponentProps } from "react-router-dom";
import { AuthContextType } from "./auth";

export type Service = {
  key: string;
  text: string;
  value: string;
};

export type Meeting = {
  meetingID: string;
  typeOfMeeting: string;
  meetingURL: string;
  meetingStart: string;
};

export type MeetingListType = {
  meetingStart: string;
  meetingId: string;
  userId: string;
};

export type Queue = {
  VisibleFromTimeUTC: string;
  IsVisibleToServants: boolean;
  AssignedTo: string;
  MeetingID: string;
  TypeOfMeeting: string;
  MeetingURL: string;
  MeetingStart: string;
};

export interface QueuedMeeting {
  MeetingID: string;
  TypeOfMeeting: string;
  MeetingURL: string;
  MeetingStart: Date;
  MeetingStartUTC: Date;
}

export type Attendee = {
  fullName: string;
  culture: string;
  email: string;
  phone: string;
  type: string;
  isOrganizer: boolean;
  personId: string;
  observations?: string;
  comment?: string;
};

export interface AttendeeExpress {
  isOrganizer: boolean;
  type: string;
  name: string;
  surname: string;
  secondSurname: string;
  email: string;
  phone: string;
  id: string;
  culture: string;
  comment: string;
  observations?: string;
  isSendOTP?: boolean,
}

export interface ExpressMeeting {
  start: string;
  durations: number;
  serviceCode: string;
  comment?: string;
  zipCode: string;
  attendees: AttendeeExpress[];
  isOtpAvailable: boolean;
  isProgrammed?: boolean;
}
export interface AddUser {
  culture: string;
  fullName: string;
  email: string;
  phone: string;
}

export const defaultQueue: Queue = {
  AssignedTo: "",
  IsVisibleToServants: false,
  MeetingID: "",
  MeetingStart: "",
  MeetingURL: "",
  TypeOfMeeting: "",
  VisibleFromTimeUTC: "",
};

export const defaultAttendee: Attendee = {
  culture: "",
  email: "",
  fullName: "",
  isOrganizer: false,
  personId: "",
  phone: "",
  type: "",
  comment: "",
};

export const defaultQueueMeeting: QueuedMeeting = {
  MeetingID: "",
  MeetingStart: new Date(),
  MeetingStartUTC: new Date(),
  MeetingURL: "",
  TypeOfMeeting: "",
};

export const defaultList: MeetingListType = {
  meetingId: "",
  meetingStart: "",
  userId: "",
};
