export const formatDate = (date: Date, separate: string): string => {
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();

  day = day.length === 1 ? "0" + day : day;
  month = month.length === 1 ? "0" + month : month;

  const full: string = year + separate + month + separate + day;
  return full;
};
export const formarDateMoment = (date: any, separate: string): string => {
  let day = date.get("date").toString();
  let month = (date.get("month") + 1).toString();
  const year = date.get("year");

  day = day.length === 1 ? "0" + day : day;
  month = month.length === 1 ? "0" + month : month;

  const full = year + separate + month + separate + day;
  return full;
};

export const formatSlotCalendar = (date: string): string => {
  let data = "";
  const day = date.slice(0, 8);
  const time = date.slice(8, 12);
  data = day + " " + time;
  return data;
};
