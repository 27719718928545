import React, { useContext, useEffect, useState } from "react";
import { getMeetingDocumentation } from "../../../../../assets/api";
import { AuthContext } from "../../../../../context/AuthContext";
import { EventMeeting } from "../../../../../models/EventMeeting";
import Screen from "./Screen";
import 'react-toastify/dist/ReactToastify.css';
import { DocumentApiResponse, defaultDocumentApiResponse } from "../../../../../models/DocumentationObject";
import { IEventDetailAdapter, IEventDetailAdapterIndex, IEventDetailResponse } from "../../../../../models/event.models";

interface Props {
  meeting: EventMeeting | IEventDetailAdapter;
  servicesRefresh?: () => void;
  isSelf:any;
}

function processInterface(obj: IEventDetailAdapterIndex | IEventDetailResponse | undefined)  {
  if ('Code' in obj!) {
    obj.code = obj.Code;
}
}

const ModalDocumentMeeting = (props: Props) => {
  const [documentationInfo, setDocumentationInfo] = useState<DocumentApiResponse>(defaultDocumentApiResponse);

  const authContext = useContext(AuthContext);


  useEffect(() => {
    (async () => {
    const { shared } = await authContext.getTokenForScopes();
    processInterface (props.meeting);
    const documentationInfo: DocumentApiResponse = await getMeetingDocumentation(
      props.meeting!.code,
      shared
    );

    setDocumentationInfo(documentationInfo);

  })()},[authContext, props.meeting]);

  return (
    <Screen
      {...props}
      codeText={props.meeting?.code} // Pasar el código generado como prop
      documents={documentationInfo.result}
    />
  );
};

export default ModalDocumentMeeting;
