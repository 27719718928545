import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { AutoGridLabelType } from "./type";
interface Props {
  labels: AutoGridLabelType[][];
  numRows: number;
  renderCell: (label: AutoGridLabelType) => any;
}

export default (props: Props) => {
  return (
    <>
      {props.labels.map((rowLabels) => (
        <Grid.Row className={""}>
          {rowLabels.map((cell) => (
            <Grid.Column className={"flex-fill"}>
              {props.renderCell(cell)}
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </>
  );
};
