import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "semantic-ui-react";
import {
  createLicensesOrganization,
  createLimitMeetingsOrganization,
  getLicensesOrganization,
  getLimitMeetingsOrganization,
  updateLicensesOrganization,
  updateLimitMeetingsOrganization,
} from "../../../../assets/api";
import ButtonSubmit from "../../../../components/Field/components/ButtonSubmit";

import Field from "../../../../components/Field/Field";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";
interface Props {
  isVisible: boolean;
  onClose: () => void;
  organizationCode: string;
  onSubmit: Function;
}

const ModalUpdateNumMeetings = (props: Props) => {
  const { t } = useTranslation();
  const [form, setForm, handleInputChange] = useForm({
    meetings: "",
  });
  const [currentLimitMeetings, setCurrentLimitMeetings] = useState(0);

  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  useEffect(() => {
    (async () => {

      const licensesOrganizations: any[] = await getLimitMeetingsOrganization(token)
      .then(response => response.json())
      .then(response => response["Value"]);

      const licenseInfo = licensesOrganizations.find( (license: any) => license.key.split("-")[1] === props.organizationCode)

      const numLicenses = licenseInfo ? parseInt(licenseInfo.value) : -1;
      setCurrentLimitMeetings(numLicenses);
    })();
  }, [props.isVisible]);

  const formFormated = Object.entries(form).map(([key, value]) => ({
    key,
    value,
    format: "number",
    required: true,
  }));
  return (
    <Modal
      id={"modal-add-admin-organization"}
      size={"mini"}
      open={props.isVisible}
      onClose={props.onClose}
    >
      <Modal.Header>
        {t(`administration.update_meetings_organization.title`)}
      </Modal.Header>
      <Modal.Content>
        <Form className="border-0" style={{ boxShadow: "none" }}>
          {formFormated.map((_: any) => {
            return (
              <Field
                format={_.format}
                required={_.required}
                label={_.label}
                title={t(
                  `administration.update_meetings_organization.form.${_.key}`
                )}
                name={_.key}
                value={_.value}
                onChange={handleInputChange}
                textError={t(
                  `administration.update_meetings_organization.form.${_.key}_error`
                )}
              />
            );
          })}
        </Form>
        <p>
          {t("administration.update_meetings_organization.current_meetings", {
            meetings: currentLimitMeetings >= 0 ? currentLimitMeetings : 0,
          })}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          negative
        >
          {t("btn.cancel")}
        </Button>
        <ButtonSubmit
          title={t(
            "administration.update_meetings_organization.buttons.submit"
          )}
          form={formFormated}
          handleSubmit={async () => {
            const newForm = {
              key: `DailyMeeting/Max-${props.organizationCode}`,
              value: form.meetings,
            };
            try {
              let response;
              if (currentLimitMeetings >= 0) {
                response = await updateLimitMeetingsOrganization(
                  newForm,
                  token
                );
              } else {
                response = await createLimitMeetingsOrganization(
                  newForm,
                  token
                );
              }
              if (!response.ok) throw new Error("Bad Response");
              toast(
                t("administration.update_meetings_organization.success"), { type: "success"}
              );
              props.onSubmit && props.onSubmit();
              props.onClose();
            } catch (error) {
              toast(
                t("administration.update_meetings_organization.error"), { type: "error"}
              );
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalUpdateNumMeetings;