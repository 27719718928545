import React, { useContext, useEffect, useState } from "react";
import { getService } from "../../../../../../../assets/api";
import { AuthContext } from "../../../../../../../context/AuthContext";
import i18n from "i18next";
import { Service } from "../../../../../../../types/service";
import Text from "../atomic_components/Text";

const getLanguage = () => {
  const lang: string =
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    "ca";
  return lang.split("-")[0];
};
interface Props {
  serviceCode: string;
}

const ServiceCell = (props: Props) => {
  const [serviceName, setServiceName] = useState("");
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    (async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      const service: Service = await getService(
        props.serviceCode,
        backoffice
      ).then((_) => _.json());
      var userLang = getLanguage();
      const language = service?.languages?.find((_) => _.culture == userLang);
      const serviceName = language ? language.displayName : service.displayName;
      setServiceName(serviceName);
    })();
  }, [props.serviceCode]);

  return <Text>{serviceName}</Text>;
};

export default ServiceCell;