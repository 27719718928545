import { EnumAPI } from "../../../enum/EnumAPI";
import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { ResultAPI, fetch } from "./API";

const URL_AREAS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Areas}`;

export const postAreas = async (
  mailUser: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_AREAS}`;
  const areaPOST = {
    organizationCode: "",
    email: "",
    languages: [
      {
        culture: "ca",
        displayName: "Area Test",
      },
      {
        culture: "es",
        displayName: "Area Test",
      },
      {
        culture: "en",
        displayName: "Area Test",
      },
      {
        culture: "oc",
        displayName: "Area Test",
      },
    ],
    active: true,
    displayName: "Area Test",
    code: "AT01",
  };
  const body = JSON.stringify(areaPOST);
  const result = fetch(
    urlAPI,
    token,
    "Crear Area",
    "Error Crear Area",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};

export const deleteAreas = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_AREAS}/AT01`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar Area",
    "Error Eliminar Area",
    EnumHTTPMethod.DELETE
  );
  return result;
};

export const addAdminToAreas = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_AREAS}/AT01/administrators/${userCode}`;
  const result = fetch(
    urlAPI,
    token,
    "Asignar Admin Area",
    "Error Asignar Admin Area",
    EnumHTTPMethod.PUT
  );
  return result;
};

export const deleteAdminToAreas = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_AREAS}/AT01/administrators/${userCode}`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar Admin Area",
    "Error Eliminar Admin Area",
    EnumHTTPMethod.DELETE
  );
  return result;
};
