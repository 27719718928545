import React, { FC, ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";
import { FaUserClock } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import { ServiceCount } from "./ListService";
import { useEffect } from "react";
import { getNameServiceLanguage } from "../../../util/languages";
import { getService } from "../../../assets/api";
import { AuthContext } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { ServiceInfo } from "../../../models/ServiceInfo";
import { toast } from "react-toastify";
import { useState } from "react";

interface ItemServiceProps {
  service: ServiceCount;
  areaCode: string;
}

const ItemService: FC<ItemServiceProps> = ({
  service,
  areaCode,
}: ItemServiceProps): ReactElement => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [serviceName, setServiceName] = useState<string>("");

  useEffect(() => {
    (async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      try {
        let r: Response = await getService(service.code, backoffice);
        if (r.ok) {
          let s: ServiceInfo = await r.json();
          let l = getNameServiceLanguage(s);
          setServiceName(l.displayName);
        }
      } catch (error) {}
    })();
  }, [service]);
  return (
    <List.Item key={service.code} className="">
      <List.Content floated="right">
        <Link to={`/services/${service.code}/edit`}>
          <RiEdit2Fill size="22" />
        </Link>
      </List.Content>
      <Link
        className="font-black"
        to={`/area/${areaCode}/services/${service.code}/detail`}
      >
        <List.Content floated="right">
          <FaUserClock size="22" />
        </List.Content>
        <List.Content floated="right">
          <div className="list-number">{service.count}</div>
        </List.Content>
        <List.Content className="">{serviceName.toUpperCase()}</List.Content>
      </Link>
    </List.Item>
  );
};

export default ItemService;
