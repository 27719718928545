import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import { eventsGetInfo, serviceGetInfo } from "../../../assets/api";
import { ServiceInfo } from "../../../models/ServiceInfo";
import { Languages, defaultLanguages } from "../../../models/Languages";
import { getNameServiceLanguage } from "../../../util/languages";
import { EventObject } from "../../../models/EventObject";
import { RiEyeCloseFill } from "react-icons/ri";

interface ServiceTitleProps {
  meetingID: string;
  serviceCode: string;
}

const ServiceTitle: React.FC<ServiceTitleProps> = ({
  meetingID,
  serviceCode,
}: ServiceTitleProps) => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const [language, setLenguage] = useState<Languages>(defaultLanguages);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      let responseLenguages: Languages = defaultLanguages;
      const { shared } = await authContext.getTokenForScopes();
      if (meetingID !== "") {
        const event: EventObject = await eventsGetInfo(meetingID, shared);
        const service: ServiceInfo = await serviceGetInfo(
          event.serviceCode,
          shared
        );
        responseLenguages = await getNameServiceLanguage(service);
      } else if (serviceCode !== "") {
        const service: ServiceInfo = await serviceGetInfo(serviceCode, shared);
        responseLenguages = await getNameServiceLanguage(service);
      }

      await setLenguage(responseLenguages);
    })();
  }, [meetingID, serviceCode]);
  return (
    <div className="row mx-0 px-0 mb-3">
      <div className="col mx-0 px-0">
        <h2 className={"text-capitalize"}>
          {`${t("home.service")} ${language.displayName.toLowerCase()}`}
        </h2>
      </div>
    </div>
  );
};
export default ServiceTitle;
