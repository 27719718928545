import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useError } from "../../hooks/useError";
import { AuthorizationModel } from "../../models/Authorization";
import { AuthContext } from "../../context/AuthContext";
import LayoutContainer from "../../components/Layout/LayoutContainer";
import { LayoutContext } from "../../context/LayoutContext";
import {
  Queue,
  QueuedMeeting,
  MeetingListType,
  defaultQueue,
  defaultQueueMeeting,
} from "../../types/dashboard";
import {
  getUserServices,
  getQueueStatus,
  getNextQueue,
  getUserDetails,
  endMeeting,
  discardMeeting,
  serviceGetInfo,
  eventsGetInfo,
  getMeetingFromQueue,
  assignMeetingQueue,
} from "../../assets/api";
import CardMeeting from "./components/CardMeeting/CardMeeting";
import { ModalExpressMeeting } from "./components/Modals/ModalExpressMeeting/ModalExpressMeeting";
import ModalFinalizeMeeting from "./components/Modals/ModalFinalizeMeeting/ModalFinalizeMeeting";
import ModalDiscardMeeting from "./components/Modals/ModalDiscardMeeting";
import ServiceTitle from "./components/ServiceTitle";
import { Button as SemButton } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ServiceInfo } from "../../models/ServiceInfo";
import { EventObject } from "../../models/EventObject";
import { Attendee, defaultAttendee } from "../../models/Attendee";
import { EnumMeetingStatus } from "../../enum/EnumMeetingStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEventsWithServiceDayApi,
  getEvent,
  getEventsWithServiceDay,
  selectEvent,
  selectEventsMeetings,
} from "../../assets/events";
import ButtonsListDashboard from "./components/ButtonsList/ButtonsListDashboard";
import ButtonsListMeetingCreated from "./components/ButtonsList/ButtonsListMeetingCreated";
import ButtonsListService from "./components/ButtonsList/ButtonsListService";

import Services from "./components/Services";
import Meeting from "./components/Meeting";
import moment from "moment";
import AssignedMeetings from "./components/AssignedMeetings";
import {
  CONTENT_MEETING_SUB_TYPE,
  CONTENT_MEETING_TYPE,
} from "../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting/components/BOTableMeeting/type/contentMeeting";
import AssignedMeetingsService from "./components/AssignedMeetingsService";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import ModalDocumentMeeting from "./components/Modals/ModalDocumentMeeting";

export type UserService = {
  active: boolean;
  areaCode: string;
  code: string;
  displayName: string;
};

interface Button {
  icon?: string;
  message: string;
  onClick: any;
  disabled?: boolean;
}

export interface renderList {
  service: UserService;
  count: number;
}

interface Props { }

let updateMeetingsAndServices = () => { };

export const Dashboard = (props: Props) => {
  const TypeButtonList = {
    DASHBOARD: "DASHBOARD",
    MEETING_CREATED: "MEETING_CREATED",
    SERVICE: "SERVICE",
  };
  const TypeModal = {
    FINALIZE: "FINALIZE",
    EXPRESS: "EXPRESS",
    PROGRAMMED: "PROGRAMMED",
    DISCARD: "DISCARD",
  };
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const userCode = authContext.account.profile.code;
  const { setTitle, setBreadcrumb } = layoutContext;
  const { error, setError } = useError();
  const { t } = useTranslation();
  const [meetingsList, setMeetingsList] = useState<MeetingListType[]>([]);
  const [meetingsAssignedList, setMeetingsAssignedList] = useState<any[]>([]);
  const [queueCounter, setQueueCounter] = useState(0);
  const [queueMeeting, setQueueMeeting] = useState(defaultQueueMeeting);
  const [attendee, setAttendee] = useState<Attendee>(defaultAttendee);

  const history = useHistory();
  const [modal, setModal] = useState<any>({
    type: "",
    isVisible: false,
  });
  const [startMeeting, setStartMeeting] = useState("");
  const [reason, setReason] = useState("");
  const [typeButtonList, setTypeButtonList] = useState(TypeButtonList.SERVICE);
  const [usersArea, setUsersArea] = useState<string>("");
  const [service, setService] = useState("");
  const serviceSelected = useRef("");

  // TODO: Separar de aqui
  const setDefaultValuesFromMeeting = async () => {
    setTypeButtonList(TypeButtonList.SERVICE);
    await setQueueMeeting((meeting) => {
      meeting.MeetingID = "";
      meeting.MeetingStart = new Date();
      meeting.MeetingStartUTC = new Date();
      meeting.MeetingURL = "";
      meeting.TypeOfMeeting = "";

      return meeting;
    });
    await setStartMeeting("");
  };

  const setDefaultValuesFromMeetingFinalize = async () => {
    setQueueCounter(queueCounter - 1);
    setDefaultValuesFromMeeting();
  };

  const resetServices = async () => {
    try {
      await setDefaultValuesFromMeeting();

      serviceSelected.current = "";

      setService("");
      setMeetingsList([]);
      setUserList([]);
      setQueueCounter(0);
      deleteButtons();

    } catch (error) {
      console.warn(error);
    }
  };

  const deleteButtons = () => {
    setTypeButtonList(TypeButtonList.SERVICE);
  };

  const orderByTime = (a: any, b: any) => {
    if (a.meetingStart < b.meetingStart) {
      return 1;
    }
    if (a.meetingStart > b.meetingStart) {
      return -1;
    }
    return 0;
  };

  const setUserList = async (response: Queue[], codeService?: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    let array: MeetingListType[] = [];

    await Promise.all(
      response.map(async (meeting) => {
        if (
          meeting.IsVisibleToServants === true &&
          meeting.AssignedTo === "NotAssigned"
        ) {
          let value: MeetingListType = await fetchUserDetails(
            meeting,
            meeting.MeetingID,
            backoffice
          );
          array.push(value);
        }
      })
    );

    const token = authContext.account.access_token;

    if (codeService) {
      const form = {
        service: codeService,
      };

      const events = await getEventsWithServiceDay(form, token);

      setMeetingsList(
        array
          .sort(orderByTime)
          .filter((_) => discardCancelledMeetings(_, events))
          .filter((_) => discardMeetingsEndeds(_))
          .map((_) => ({
            ..._,
            ...events.find((event: any) => _.meetingId === event.code),
          }))
        );
    } else {
      setMeetingsList(array);
    }
  };

  const fetchUserDetails = async (
    meeting: any,
    meetingId: string,
    token: string
  ): Promise<MeetingListType> => {
    return await getUserDetails(meetingId, token)
      .then((response) => {
        console.log("Get Users Details", response);
        return response.json()
      })
      .then((data) => {
        const attendee = data.attendees ? data.attendees[0] : {};
        const meetingTemp: MeetingListType = {
          meetingStart: meeting.MeetingStart,
          meetingId: meeting.MeetingID,
          userId: attendee.PersonId,
        };
        return meetingTemp;
      });
  };

  const selectMeeting = async (meeting?: any, assignFunction?: Function) => {
    const meetingId = meeting.meetingId;

    console.log("Response Queue", meetingId, service, usersArea);

    const data: Queue[] | any = await getQueueData(service, usersArea);
    const meetingInfo = data.find((_: any) => _.MeetingID === meetingId);

    if (!meetingInfo) {
      toast(t("home.error.assign_meeting_unexpected"), { type: "error"});
    } else if (
      meetingInfo.AssignedTo !== "NotAssigned" &&
      meetingInfo.AssignedTo !== userCode
    ) {
      toast(t("home.error.assign_meeting"), { type: "error"});
    } else {
      toast(t("home.success.assign_meeting"), { type: "success"});
      assignFunction &&
        assignFunction(
          meeting.AreaCode,
          meetingId,
          authContext.account.profile.code,
          token
        )
          .then(() => {
            getNextEvent({ ...meeting, meetingID: meetingId });
          })
          .catch((e: any) => { });
    }
  };

  const getMeetingQueue = async (
    meetingId: string
  ) => {
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));

    const name = account.profile.code;
    let areaCurrent: string = "";

    setUsersArea((state) => {
      areaCurrent = state;
      return state;
    });

    try{
      const service = serviceSelected.current;
      const { shared } = await authContext.getTokenForScopes();

      const getMeeting = await getMeetingFromQueue(
        service,
        areaCurrent,
        name,
        meetingId,
        shared
      ).then(async(response) => {
        const { Value } = await response.json();
        return Value;
      })
      .catch((error) => {
        console.log(error);
      });

      setService(service);

      await setQueueMeeting(getMeeting);
      console.log("Hola", getMeeting)

      setTypeButtonList(TypeButtonList.MEETING_CREATED);

      const startHour = getMeeting.MeetingStart.toString().split("T")[1];
      const startHourSplitted = startHour.split(":");  
      const startHourWithoutSeconds = `${startHourSplitted[0]}:${startHourSplitted[1]}`;

      setStartMeeting(startHourWithoutSeconds);

      const expirationTimeIn5 = moment(getMeeting.meetingStart)
          .add(15, "minutes")
          .format()
          .toString()
          .split("T")[1]
          .split("+")[0];
      const expirationTime = moment(getMeeting.meetingStart)
          .add(20, "minutes")
          .format()
          .toString()
          .split("T")[1]
          .split("+")[0];
          const localDate = new Date();
          localDate.toLocaleString();
          const localTime = localDate.toLocaleTimeString("es", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
  
          const { backoffice } = await authContext.getTokenForScopes();
  
          await getUserDetails(getMeeting.meetingID, backoffice)
            .then((response) => {
              if (response.ok) return response.json();
  
              throw new Error(t("home.error.userDetails"));
            })
            .then(({ attendees }) => {
              setAttendee((prevAttendee) => {
                prevAttendee = attendees[0];
                return prevAttendee;
              });
            });
          return getMeeting;  
    }catch(error){
      toast((error), { type: "error"});
    }

  }

  const getMeetingAndAssign = async() => {
    try{
      const { meetingID } = await getNextEvent(null, true, false, true);
      
      const account: AuthorizationModel = await authContext
        .getAccount()
        .then((response) => JSON.parse(response));
      
      const name = account.profile.code;
      const { shared } = await authContext.getTokenForScopes();
      
      await assignMeetingQueue(usersArea, meetingID, name, shared);
    }catch(error){
      toast(t("home.error.queue"), { type: "error"});
    }
  }

  const getNextEvent = async (
    meeting?: any,
    isSort?: boolean,
    isSimulated?: boolean,
    getQueuesWithoutServantId?: boolean
  ) => {

    console.log("Meeting Event", meeting);

    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));
      
    const name = account.profile.code;
    let areaCurrent: string = "";
    
    setUsersArea((state) => {
      areaCurrent = state;
      return state;
    });
    
    try {
      const service = serviceSelected.current || meeting.TypeOfMeeting;
      const { shared } = await authContext.getTokenForScopes();

      const nextQueuedMeeting = await getNextQueue(
        service,
        areaCurrent || meeting.areaCode,
        name,
        shared,
        getQueuesWithoutServantId
      )
        .then((response) => {
          if (response) return response;
          throw new Error(t("home.error.queue"));
        })
        .catch((error) => {
          console.log(error);
        });
      
      const meetingsAssigned = nextQueuedMeeting;

      setService(service);

      let filteredMeetings = meetingsAssigned.filter((_: any) =>
        meeting && meeting.MeetingID
          ? _.MeetingID === meeting.MeetingID
          : _.MeetingStart.toString() >=
          moment().subtract(2, "hours").format("YYYY-MM-DDTHH:mm:ss")
      );
      
      if (isSort) filteredMeetings = filteredMeetings.sort(orderByTime);
      const queuedMeeting = filteredMeetings[filteredMeetings.length - 1];

      if (!isSimulated) {
        if (meetingsAssigned.length === 0) {
          toast(t("home.error.queue"), { type: "error"});
          throw new Error(t("home.error.queue"));
        }
        if (queuedMeeting === undefined) throw new Error(t("home.error.queue"));
        await setQueueMeeting(queuedMeeting);

        setTypeButtonList(TypeButtonList.MEETING_CREATED);
        const startHour = queuedMeeting.MeetingStart.toString().split("T")[1];
        const startHourSplitted = startHour.split(":");
        const startHourWithoutSeconds = `${startHourSplitted[0]}:${startHourSplitted[1]}`;

        setStartMeeting(startHourWithoutSeconds);

        const expirationTimeIn5 = moment(queuedMeeting.MeetingStart)
          .add(15, "minutes")
          .format()
          .toString()
          .split("T")[1]
          .split("+")[0];
        const expirationTime = moment(queuedMeeting.MeetingStart)
          .add(20, "minutes")
          .format()
          .toString()
          .split("T")[1]
          .split("+")[0];

        const localDate = new Date();
        localDate.toLocaleString();
        const localTime = localDate.toLocaleTimeString("es", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        const { backoffice } = await authContext.getTokenForScopes();

        await getUserDetails(queuedMeeting.MeetingID, backoffice)
          .then((response) => {
            if (response.ok) return response.json();

            throw new Error(t("home.error.userDetails"));
          })
          .then(({ attendees }) => {
            setAttendee((prevAttendee) => {
              prevAttendee = attendees[0];
              return prevAttendee;
            });
          });
        return queuedMeeting;
      }
      return queuedMeeting;
    } catch (error) {
      console.error("error", error);
      return undefined;
    }
  };

  const handleChangeReason = (r: string) => {
    setReason(r);
  };

  const handleFinalizeMeeting = async () => {
    setModal({ type: TypeModal.FINALIZE, isVisible: true });
    const { shared } = await authContext.getTokenForScopes();
    const meetingID = queueMeeting.MeetingID;

    try {
      await endMeeting(meetingID, reason, shared).then(async (response) => {
        if (response.ok) {
          await setDefaultValuesFromMeetingFinalize();

          setTypeButtonList(TypeButtonList.SERVICE);
          const newMeetingList = meetingsList
            .filter((f) => f.meetingId !== meetingID)
            .filter((_) => discardCancelledMeetings(_));
          setMeetingsList(newMeetingList);
          return response.json();
        }
        throw new Error(t("home.error.endMeeting"));
      });
    } catch (error) {
      toast((error), { type: "error"});
    }
  };

  const queueCurrentEvent = async () => {
    const meetingID = queueMeeting.MeetingID;
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));
    const name = account.profile.code;

    try {
      const { shared } = await authContext.getTokenForScopes();
      const event: EventObject = await eventsGetInfo(meetingID, shared);
      const service: ServiceInfo = await serviceGetInfo(
        event.serviceCode,
        shared
      );

      await discardMeeting(meetingID, service.areaCode, name, shared)
        .then((response) => {
          if (response.ok) return response.json();

          throw new Error(t("home.error.discard"));
        })
        .then(async (response) => {
          await setDefaultValuesFromMeeting();

          setTypeButtonList(TypeButtonList.SERVICE);
        });
    } catch (error) {
      toast((error), { type: "error"});
    }
    setService("");
  };

  useEffect(() => {
    setTitle(t("home.title"));
    setBreadcrumb([
      {
        name: t("home.title"),
      },
    ]);

    return () => {
      (async () => {
        await queueCurrentEvent();
        queueMeeting.MeetingURL = "";
      })();
    };
  }, [t, setTitle, setBreadcrumb]);

  const handleSearchClick = () => {
    history.push("/home/search");
  };

  useEffect(() => {
    setService((prevService) => prevService);
  }, [service, setService]);

  const getQueueData = async (
    codeService: string,
    codeArea: string
  ) => {
    try {
      const { shared } = await authContext.getTokenForScopes();
      const responseQueue = await getQueueStatus(codeService, codeArea, shared);

      return responseQueue;
    } catch (error) {
      toast((error), { type: "error"});
    }
  };
  const refreshMeetingList = async (codeService: string, codeArea: string) => {
    const data = await getQueueData(
      codeService,
      codeArea
    );

    console.log("ResponseQueue RefreshMeetingList", data);
    await setUserList(data, codeService);
  };
  const token = authContext.account.access_token;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (service && token) {
        await dispatch(fetchEventsWithServiceDayApi({ service }, token));
      }
    })();
  }, [service]);

  const expandedMeetings = useSelector(selectEventsMeetings);

  const discardCancelledMeetings = (meeting: any, localEvents?: any) => {
    const events = localEvents ? localEvents : expandedMeetings;
    const meetingExpanded: any =
      events &&
      events.find(
        (_: any) => _.code === meeting.meetingID || _.code === meeting.meetingId
      );
    if (meetingExpanded) {
      return (
        meetingExpanded.status !== EnumMeetingStatus.CANCELLED &&
        meetingExpanded.status !== EnumMeetingStatus.FINALIZED
      );
    } else {
      return false;
    }
  };

  const discardMeetingsEndeds = (meeting: any) => {
    const start = moment(new Date(meeting.meetingStart)).add(-10, 'minutes').toDate()
    const end = moment(new Date(meeting.meetingStart)).add(30, 'minutes').toDate();
    const now = moment().toDate();

    return ( now < end && now > start && meeting )
  }

  const handleClickAssignedMeeting = (meeting: any) => {
    if (meeting.MeetingID) {
      getNextEvent(meeting);
      setUsersArea(meeting.AreaCode);
    }
  };

  const onHandleChangeVisibleModal = (
    isVisible: boolean,
    type?: string,
    onAccept?: any,
    extra?: any
  ) => {
    if (onAccept) {
      if (type === TypeModal.DISCARD) {
        if (queueMeeting.MeetingID) {
          setModal({
            type: type ? type : isVisible ? modal.type : "",
            isVisible: isVisible,
            onAccept,
            ...extra,
          });
        } else if (onAccept) {
          onAccept();
        }
      }
    } else {
      setModal({
        type: type ? type : isVisible ? modal.type : "",
        isVisible: isVisible,
        ...extra,
      });
    }
  };

  const arrayButtonsArea = [
    {
      icon: "search",
      message: "area.meeting_search",
      onClick: handleSearchClick,
    },]

  return (
    <>
      {!error.show && (
        <>
          <LayoutContainer.Main>
            {typeButtonList === TypeButtonList.SERVICE && (
              <>
              <AssignedMeetings
                meetings={meetingsAssignedList}
                contentMeeting={[
                  CONTENT_MEETING_TYPE.START,
                  CONTENT_MEETING_TYPE.CODE,
                  CONTENT_MEETING_TYPE.SERVICE_CODE,
                  CONTENT_MEETING_TYPE.IS_QUEUED,
                  CONTENT_MEETING_TYPE.createTypeAction([
                    // CONTENT_MEETING_SUB_TYPE.ACTION.MORE_INFO,
                    CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE,
                    CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL,
                    CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENT,
                  ]),
                ]}
                updateMeetingAssigned={updateMeetingsAndServices}
                onClickMeeting={handleClickAssignedMeeting}
                disabledMeeting={(meeting: any) => meeting.isQueued === false}
              />
              </>
            )}
            <ServiceTitle
              meetingID={queueMeeting.MeetingID}
              serviceCode={serviceSelected.current}
            />
            {typeButtonList !== TypeButtonList.SERVICE && (
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <SemButton
                  content={t("home.all_services")}
                  icon="chevron left"
                  labelPosition="left"
                  onClick={() => resetServices()}
                />
              </div>
            )}
            {typeButtonList === TypeButtonList.SERVICE && (
              <>
              <Services
                service={service}
                serviceSelected={serviceSelected}
                setMeetingsAssignedList={setMeetingsAssignedList}
                setAttendee={setAttendee}
                setService={setService}
                setUsersArea={setUsersArea}
                setQueueMeeting={setQueueMeeting}
                setStartMeeting={setStartMeeting}
                setTypeButtonList={setTypeButtonList}
                setQueueCounter={setQueueCounter}
                setUserList={setUserList}
                discardCancelledMeetings={discardCancelledMeetings}
                referenceUpdate={(ref: any) =>
                  (updateMeetingsAndServices = ref)
                }
              />
              </>
            )}
            <hr />
            {typeButtonList === TypeButtonList.MEETING_CREATED && (
              <CardMeeting
                queueMeeting={queueMeeting}
                start={startMeeting}
                meetingDate={new Date()}
              />
            )}
            {typeButtonList === TypeButtonList.DASHBOARD && (
              <AssignedMeetingsService
                meetings={meetingsAssignedList}
                service={service}
                getNextEvent={getNextEvent}
                onClickMeeting={handleClickAssignedMeeting}
                updateMeetingAssigned={updateMeetingsAndServices}
                refreshMeetingList={() =>
                  refreshMeetingList(service, usersArea)
                }
                contentMeeting={[
                  CONTENT_MEETING_TYPE.START,
                  CONTENT_MEETING_TYPE.CODE,
                  CONTENT_MEETING_TYPE.SERVICE_CODE,
                  CONTENT_MEETING_TYPE.IS_QUEUED,
                  CONTENT_MEETING_TYPE.createTypeAction([
                    // CONTENT_MEETING_SUB_TYPE.ACTION.MORE_INFO,
                    CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE,
                    CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL,
                    CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENT,
                  ]),
                ]}
                disabledMeeting={(meeting: any) => meeting.isQueued === false}
              />
            )}
            {typeButtonList === TypeButtonList.DASHBOARD && (
              <Meeting
                meetingsList={meetingsList}
                usersArea={usersArea}
                service={service}
                getNextEvent={selectMeeting}
                refreshMeetingList={refreshMeetingList}
              />
            )}
          </LayoutContainer.Main>
          <LayoutContainer.Article>
            {arrayButtonsArea.map((button: any) => (
              <Button
                message={button.message}
                icon={button.icon}
                onClick={button.onClick}
              />
            ))}
            {typeButtonList === TypeButtonList.SERVICE && (
              <ButtonsListService />
            )}
            {typeButtonList === TypeButtonList.DASHBOARD && (
              <ButtonsListDashboard
                service={serviceSelected.current}
                area={usersArea}
                meetings={meetingsAssignedList.filter(
                  (_) => _.serviceCode === service
                )}
                setModalCreate={(isVisible) =>
                  onHandleChangeVisibleModal(isVisible, TypeModal.EXPRESS)
                }
                setProgramedCreate={(isVisible) =>
                  onHandleChangeVisibleModal(isVisible, TypeModal.PROGRAMMED)
                }
                getMeetingAndAssign={getMeetingAndAssign}
              />
            )}
            {typeButtonList === TypeButtonList.MEETING_CREATED && (
              <ButtonsListMeetingCreated
                setModalDiscard={(isVisible) =>
                  onHandleChangeVisibleModal(
                    isVisible,
                    TypeModal.DISCARD,
                    queueCurrentEvent
                  )
                }
                setModalFinalize={(isVisible) =>
                  onHandleChangeVisibleModal(isVisible, TypeModal.FINALIZE)
                }
              />
            )}
          </LayoutContainer.Article>
          {modal.type === TypeModal.FINALIZE && (
            <ModalFinalizeMeeting
              setModal={onHandleChangeVisibleModal}
              modal={modal.isVisible}
              handleChangeReason={handleChangeReason}
              handleSubmitReason={handleFinalizeMeeting}
            />
          )}
          {(modal.type === TypeModal.EXPRESS || modal.type === TypeModal.PROGRAMMED) && (
            <ModalExpressMeeting
              handleOpenModal={onHandleChangeVisibleModal}
              type = {modal.type}
              count={queueCounter}
              queueCount={setQueueCounter}
              getNextEvent={getMeetingQueue}
              modal={modal.isVisible}
              area={usersArea}
              service={service}
            />
          )}
          {modal.type === TypeModal.DISCARD && (
            <ModalDiscardMeeting
              setModal={onHandleChangeVisibleModal}
              modal={modal.isVisible}
              onAccept={modal.onAccept}
              headerText={modal.headerText}
            />
          )}
        </>
      )}
    </>
  );
};
