import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { fetchMSGraph } from "../../../util/auth";

export type ResultAPI = {
  message: string;
  data: boolean;
};

export const fetch = async (
  url: string,
  token: string,
  successful: string,
  error: string,
  http?: EnumHTTPMethod,
  data?: any
): Promise<ResultAPI> => {
  let result: ResultAPI = {
    data: true,
    message: successful,
  };
  try {
    const response: Response = await fetchMSGraph(url, token, http, data);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
    } else {
      const data = await response.json();
      console.log(data);
      result = { data: false, message: error };
    }
  } catch (e) {
    result = { data: false, message: error };
  }
  return result;
};
