import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Popup, List } from "semantic-ui-react";
import { Attendee } from "../../../../../types/dashboard";
interface Props {
  attendee: Attendee;
  index?: number;
}

export default (props: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Props Attendee", props)
  }, [])


  return (
    <li>
      <p>
        {props.attendee.phone}, {props.attendee.fullName},{" "}
        {props.attendee.email}
      </p>
    </li>
  );
};
