import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";

export enum EnumTypeErrorLogin {
  Licencia = "Licencia",
  Area = "Area",
  Organization = "Organization",
  API = "API",
  Other = "Other",
}

interface ErrorLoadingProps {
  typeError: EnumTypeErrorLogin;
}

const ErrorLoading: FC<ErrorLoadingProps> = ({
  typeError,
}: ErrorLoadingProps) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    switch (typeError) {
      case "Licencia":
        setMessage(t("error_login.license"));
        break;
      case "Area":
        setMessage(t("error_login.area"));
        break;
      case "Organization":
        setMessage(t("error_login.organization"));
        break;
      case "API":
        setMessage(t("error_login.api"));
        break;
      case "Other":
        setMessage(t("error_login.other"));
        break;

      default:
        break;
    }
  }, [typeError]);

  return (
    <Dimmer id={"error-login"} active>
      <h2 className={""}>ERROR LOGIN</h2>
      <p className={""}>{message}</p>
      <Button
        className={"btn-error"}
        size={"big"}
        onClick={() => {
          authContext.onSignOut();
        }}
      >
        {t("btn.back")}
      </Button>
    </Dimmer>
  );
};

export default ErrorLoading;
