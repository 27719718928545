import React from "react";
import { useTranslation } from "react-i18next";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
import { Checkbox, Form } from "semantic-ui-react";
interface Props {
  onChangeFields: any;
  mailConfirmation: any;
  updateMailConfirmation: any;
  smsConfirmation: any;
  updateSmsConfirmation: any;
  mailLink: any;
  updateMailLink: any;
  smsLink: any;
  updateSmsLink: any;
  mailCancelation: any;
  updateMailCancelation: any;
  mailReminder: any;
  updateMailReminder: any;
  smsReminder: any;
  updateSmsReminder: any;
  areaNotification: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_mail_confirmation")}
          labelPlacement="start"
          toggle
          defaultChecked={props.mailConfirmation}
          disabled={!props.areaNotification}
          onChange={props.updateMailConfirmation}
        />
      </Form.Field>
      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_sms_confirmation")}
          labelPlacement="start"
          toggle
          defaultChecked={props.smsConfirmation}
          disabled={!props.areaNotification}
          onChange={props.updateSmsConfirmation}
        />
      </Form.Field>
      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_mail_link")}
          labelPlacement="start"
          toggle
          defaultChecked={props.mailLink}
          disabled={!props.areaNotification}
          onChange={props.updateMailLink}
        />
      </Form.Field>
            <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_sms_link")}
          labelPlacement="start"
          toggle
          defaultChecked={props.smsLink}
          disabled={!props.areaNotification}
          onChange={props.updateSmsLink}
        />
      </Form.Field>
      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_mail_cancelation")}
          labelPlacement="start"
          toggle
          defaultChecked={props.mailCancelation}
          disabled={!props.areaNotification}
          onChange={props.updateMailCancelation}
        > </Checkbox>
      </Form.Field>

      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_mail_reminder")}
          labelPlacement="start"
          toggle
          defaultChecked={props.mailReminder}
          disabled={!props.areaNotification}
          onChange={props.updateMailReminder}
        > </Checkbox>
      </Form.Field>

      <Form.Field>


        <Checkbox
          label={t("detail.personalitzation.send_sms_reminder")}
          labelPlacement="start"
          toggle
          defaultChecked={props.smsReminder}
          disabled={!props.areaNotification}
          onChange={props.updateSmsReminder}
        > </Checkbox>
      </Form.Field>
      
    </div>
  );
};
