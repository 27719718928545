import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { MODAL_TYPES } from "../types/modal";
import ModalAssignConsultor from "./ModalAssignConsultor";
import {
    removeOrganizationConsultor,
  } from "../../../../assets/api";

interface AddConsultorFormProps {
    type: string;
  organizationCode: string;
  className?: string;
  title: string;
  updateConsultors: () => void;
  consultors:any[];
}


export const AddConsultorForm: React.FC<AddConsultorFormProps> = ({
    type,
    organizationCode,
    className = "",
    consultors,
    title,
    updateConsultors,
  }) => {
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const [modal, setModal] = useState({
        type: "",
        isVisible: false,
    });

    // updateAdmins();
    const handleOnCloseModalConsultor = () => {
        setModal({
        type: "",
        isVisible: false,
        });
    };

    const handleUser = async (code: string) => {
        const { backoffice } = await authContext.getTokenForScopes();
        switch (type) {
        case "consultor":
            handleRemoveConsultor(code, backoffice);
            break;
        }
    };

    const handleRemoveConsultor = async (code: string, backoffice: string) => {
        try {
          const response: Response = await removeOrganizationConsultor(
            organizationCode,
            code,
            backoffice
          );
          if (response.ok) {
            toast((t(`edit.area.message.consultor.delete.success`) + " " + code), { type: "success"});
            updateConsultors();
          } else {
            const data = await response.text();
            throw new Error(data);
          }
        } catch (e) {
          toast(t("edit.area.message.consultor.delete.error"), { type: "error"});
        }
      };


    return (
    <div className={`${className}`}>
        <label className={"font-weight-bold"}>{title}</label>
        <div className={"container-btn-delete-consultor"}>
            {consultors.length === 0 ? (
            <p>{`${
                type === "consultor"
                ? t("edit.area.no_found_consultor")
                : t("edit.area.no_found_user")
            }`}</p>
            ) : (
            consultors.map((consultor) => (
                <Button
                className={"btn-delete-user"}
                icon={"user remove"}
                content={consultor.name || consultor.code}
                onClick={() => handleUser(consultor.code)}
                />
            ))
            )}
        </div>
        <Button
            icon="user add"
            color="blue"
            className="d-block w-100"
            content={`${t("edit.area.add")} ${title}`}
            onClick={() =>
            setModal({
                type: MODAL_TYPES.ASSIGN_CONSULTOR,
                isVisible: true,
            })
            }
        />
        <ModalAssignConsultor
            isVisible={modal.isVisible}
            onClose={handleOnCloseModalConsultor}
            organizationCode={organizationCode}
            // onSubmit={updateAdmins}
            isCreateAdmin = {false}
            updateConsultors = {updateConsultors}
            consultors = {consultors}
        />
    </div>
    );
  };