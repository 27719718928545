import React, { useEffect, useState } from 'react';
import { Area } from '../../types/area';
import Screen from './Screen';

interface Props{
  header: string;
  areas: Area[];
  multiple?: boolean;
  onChange: (x: string) => void;
  classNameContainer?: string;
  styleContainer?: any;
}

const DropDownAreas = (props: Props) => {
  const [areaSelected, setAreaSelected] = useState("");
  const [areasFilter, setAreasFilter] = useState<any[]>();

  useEffect(() => {
    setAreaSelected("");

    setAreasFilter(props.areas
      .map(({ displayName, code }, index) => ({
        key: index,
        value: code,
        text: displayName,
      })))
  }, [props.areas])

  const onChange = (event: any, data: any) => {
    const area = data.value;
    setAreaSelected(area);
    props.onChange(area);
  }

  return <Screen {...props} areas={areasFilter} areaSelect={areaSelected} onChange={onChange}/>
}

export default DropDownAreas;