import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetAreasServicesApi,
  selectMyAreas,
  selectServices,
} from "../../assets/areas";
import { AuthContext } from "../../context/AuthContext";
import { Service } from "../../models/Service";
import Screen from "./Screen";

interface Props {
  header: string;
  services: Service[];
  onChange: (x: string) => void;
  classNameContainer?: string;
  styleContainer?: any;
  multiple?: boolean;
}

const DropDownServices = (props: Props) => {
  
  const [serviceSelect, setServiceSelect] = useState("");

  const services = props.services
    .filter(({ active }) => active)
    .map(({ displayName, code }, index) => ({
      key: index,
      value: code,
      text: displayName,
    }));

  const onChange = (event: any, data: any) => {
    const service = data.value;
    setServiceSelect(service);
    props.onChange(service);
  };

  return (
    <Screen
      {...props}
      services={services}
      onChange={onChange}
      serviceSelect={serviceSelect}
    />
  );
};
export default DropDownServices;