import React from "react";
import EmptyStateMeeting from "../EmptyStateMeeting";
import MeetingContainer from "../MeetingContainer";
interface Props {
  meetingsList: any[];
  usersArea: any;
  service: any;
  getNextEvent: any;
  refreshMeetingList: any;
}

const Screen = (props: Props) => {
  return (
    <div>
      <MeetingContainer
        codeArea={props.usersArea}
        codeService={props.service}
        arrayMeeting={props.meetingsList}
        handleGetNextEvent={props.getNextEvent}
        handleRefresh={props.refreshMeetingList}
      />
    </div>
  );
};
export default Screen;