import React, { useEffect } from 'react';

import moment from "moment";
import { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import Modal from "../../../components/Modal/Modal";
import { IEventDetailAdapter } from "../../../models/event.models";
import { IUsersAdapter } from "../../../models/user.models";
import AreaAdministrationServiceDetailsUsers from './AreaAdministrationServiceDetailsUsers';
import { useTranslation } from 'react-i18next';
import { removeMeetingForUser } from '../controllers/area_service_details.controller';
import useFetchAndLoad from '../../../hooks/useFetchAndLoad';
import { useDispatch } from 'react-redux';
import i18n from '../../../configs/i18n';

interface IProps {
    meeting: IEventDetailAdapter,
    setReload: any,
    backoffice: string,
    shared: string,
    users?: IUsersAdapter[],
}

const AreaAdministrationServiceDetailsMeetingModal = ({ meeting, users, backoffice, shared, setReload }: IProps) => {

    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);


    useEffect(() => {

    }, [t,i18n.language]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={t("detail.modal_assign_user.title")}
            content={
                <>
                    <h2>{t("detail.modal_assign_user.data_column.title")}</h2>
                    <div style={{ display: 'flex' }}>
                        <Table basic="very" style={{ margin: '20px' }}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.meeting_id")}</strong></Table.Cell>
                                    <Table.Cell>{meeting.Code}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.citizen")}</strong></Table.Cell>
                                    <Table.Cell>{meeting.Attendees[0].Name}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.date_assing_meeting")}</strong></Table.Cell>
                                    <Table.Cell>{moment(meeting.Start).format("LL")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.start")}</strong></Table.Cell>
                                    <Table.Cell>{moment(meeting.Start).format("HH:mm")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.end")}</strong></Table.Cell>
                                    <Table.Cell>{moment(meeting.End).format("HH:mm")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.service_code")}</strong></Table.Cell>
                                    <Table.Cell>{meeting.Service}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.status")}</strong></Table.Cell>
                                    <Table.Cell>{t(`detail.status.${meeting.Status.toLowerCase()}`)}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><strong>{t("detail.modal_assign_user.data_column.video_url")}</strong></Table.Cell>
                                    <Table.Cell>https://meetdev.in2tant.cloud/{meeting.Code}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <AreaAdministrationServiceDetailsUsers users={users} meeting={meeting} backoffice={backoffice} shared={shared} status={false} setReload={setReload} setOpen={setOpen} />
                    </div>
                </>
            }
            trigger={
                <Button icon color="grey">
                    <Icon name="add user" />
                </Button>
            }
            actions={
                <>
                    <Button onClick={async () => {
                        await removeMeetingForUser(meeting.Area, meeting.Code, meeting.AssignedTo, shared, dispatch, callEndpoint)
                        setOpen(false)
                        setReload(true)
                    }}>{t("detail.modal_assign_user.btn.release_meeting")}</Button>
                    <Button color="grey" onClick={() => setOpen(false)}>{t("detail.btn_cancel")}</Button>
                </>
            }
        />
    )
}

export default AreaAdministrationServiceDetailsMeetingModal;