import React, { FC, Fragment, useContext, useEffect } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { EnumRol } from "../../enum/EnumRol";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import Navigation from "./Navigation";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  userRoles: EnumRol[];
}

export const Header: FC<HeaderProps> = ({ userRoles }: HeaderProps) => {
  const layoutContext = useContext(LayoutContext);
  const { t, i18n } = useTranslation();

  
  useEffect(() => {
    
  }, [i18n.language, layoutContext.title]);

  return (
    <Fragment>
      <Navigation userRoles={userRoles} />
      <div id="header" className="p-4">
        <Breadcrumb />
        <h1>{t(layoutContext.title)}</h1>
      </div>
    </Fragment>
  );
};
