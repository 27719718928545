import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonList from "../../../../components/ButtonList";
import { MODAL_TYPES } from "../types/modal";
import ModalUpdateNumLicenses from "./ModalUpdateNumLicenses";
import ModalUpdateNumMeetings from "./ModalUpdateNumMeetings";
import ModalAssignAdmin from "./ModalAssignAdmin";
interface Props {
  organizationCode: string;
  form: any;
  administrators: any[];
  updateAdmins: () => void;
}

export default (props: Props) => {
  const history = useHistory();
  const [modal, setModal] = useState({ isVisible: false, type: "" });

  const handleModalUpdateLicenses = (isVisible: boolean) => {
    setModal({ isVisible, type: MODAL_TYPES.UPDATE_LICENSES });
  };
  const handleModalUpdateMeetings = (isVisible: boolean) => {
    setModal({ isVisible, type: MODAL_TYPES.UPDATE_MEETINGS });
  };
  const handleModalAssignAdminOrganization = (isVisible: boolean) => {
    setModal({ isVisible, type: MODAL_TYPES.ASSIGN_ADMIN });
    // props.updateAdmins();
  };

  const historyHandleClick = () => {
    history.push("/administration");
  };
  const buttons = [
    {
      icon: "arrow left",
      message: "go_back",
      onClick: historyHandleClick,
    },
    {
      icon: "address card",
      message: "administration.buttons.update_licenses_organization",
      onClick: () => handleModalUpdateLicenses(true),
    },
    {
      icon: "address card",
      message: "administration.buttons.update_meetings_organization",
      onClick: () => handleModalUpdateMeetings(true),
    },
    {
      icon: "plus",
      message: "Crear Administrador",
      onClick: () => handleModalAssignAdminOrganization(true),
    },
  ];
  return (
    <>
      <ButtonList buttons={buttons} />
      {modal.type == MODAL_TYPES.UPDATE_LICENSES && (
        <ModalUpdateNumLicenses
          isVisible={modal.isVisible}
          onClose={() => handleModalUpdateLicenses(false)}
          organizationCode={props.organizationCode}
          onSubmit={() => {}}
        />
      )}
      {modal.type == MODAL_TYPES.UPDATE_MEETINGS && (
        <ModalUpdateNumMeetings
          isVisible={modal.isVisible}
          onClose={() => handleModalUpdateMeetings(false)}
          organizationCode={props.organizationCode}
          onSubmit={() => {}}
        />
      )}
        {modal.type == MODAL_TYPES.ASSIGN_ADMIN && (
          <ModalAssignAdmin
            isVisible={modal.isVisible}
            onClose={() => handleModalAssignAdminOrganization(false)}
            organizationCode={props.organizationCode}
            // onSubmit={() => {}}
            isCreateAdmin = {true}
            updateAdmins = {props.updateAdmins}
            admins = {props.administrators}
          />
        )}

        
    </>
  );
};
