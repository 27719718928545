import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Message, Modal, Table } from "semantic-ui-react";
import { fetchDeleteEvent } from "../../../assets/events";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MeetingQueueTable } from "./BOTableMeetingQueue/BOTableMeetingQueue";
import { EventMeeting } from "../../../models/EventMeeting";

interface BOModalDeleteMeetingProps {
  onOpen: boolean;
  meeting: any;
  handleOpen: Function;
  servicesRefresh?: () => void;
}

const BOModalDeleteMeeting: FC<BOModalDeleteMeetingProps> = (
  props: BOModalDeleteMeetingProps
): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  const handleDelete = async () => {
    await dispatch(
      fetchDeleteEvent(
        props.meeting.codeMeeting || props.meeting.code,
        props.meeting.slotId,
        token
      )
    );
    props.handleOpen(false);
  };

  return (
    <Modal
      id={"modal-delete"}
      size={"mini"}
      onClose={() => {
        props.handleOpen(false);
      }}
      open={props.onOpen}
    >
      <Modal.Header>{t("detail.meeting.delete.title")}</Modal.Header>
      <Modal.Content className={"br0"}>
        <p>{t("detail.meeting.delete.question")}</p>
        <Message className={"text-center"} color={"red"}>
          {props.meeting.codeMeeting || props.meeting.code}
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.handleOpen(false);
          }}
          negative
        >
          {t("btn.cancel")}
        </Button>
        <Button
          onClick={() => {
            handleDelete();
            props.servicesRefresh && props.servicesRefresh();
          }}
          positive
        >
          {t("btn.delete")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BOModalDeleteMeeting;
