import React, { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";
import { EnumRol } from "../../enum/EnumRol";
import { AuthContext } from "../../context/AuthContext";
import { AuthorizationModel } from "../../models/Authorization";
import { Dimmer, Loader } from "semantic-ui-react";
import { LayoutContext } from "../../context/LayoutContext";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import LayoutContainer from "../Layout/LayoutContainer";
import DashboardArea from "../DashboardArea/DashboardArea";
import { AreaCreate } from "../AdminOrganitzation/AreaCreate";
import ServicesDetails from "../ServicesDetails/ServicesDetails";
import AreaDetail from "../AdminOrganitzation/AreaDetail";
import ServicesDisponibilitat from "../ServicesDisponibilitat/ServicesDisponibilitat";
import ServicesCreate from "../ServicesCreate/ServicesCreate";
import ServicesEdit from "../ServicesEdit/ServicesEdit";
import AdminOrganitzation from "../AdminOrganitzation/AdminOrganitzation";
import OrganitzationUser from "../AdminOrganitzation/OrganitzationUser/OrganitzationUser";
import RecordingDetails from "../RecordingDetails/RecordingDetials";
import Test from "../Test/Test";

import EntityManagement from "../Entity/EntityManagement";
import {
  getMyAreas,
  getServantJWTwithLicence,
  getUsersArea,
} from "../../assets/api";
import AddUser from "../AdminOrganitzation/OrganitzationUser/AddUser";
import AddUsers from "../AdminOrganitzation/OrganitzationUser/AddUsers";
import EditUser from "../AdminOrganitzation/OrganitzationUser/EditUser";
import ErrorLoading, { EnumTypeErrorLogin } from "./ErrorLoading";
import Search from "../Search";
import AreaSettings from "../AreaSettings";
import ServiceSettings from "../ServiceSettings";
import Management from "../../pages/Management";
import Administration from "../../pages/Administration/Administration";
import AdministrationAddOrganization from "../../pages/Administration/AdministrationAddOrganization";
import AdministrationEditOrganization from "../../pages/Administration/AdministrationEditOrganization";
import { setUserToken } from "../../assets/users";
import { AreaAdministrationServicesDetails } from "../../pages/AreaAdministrationServicesDetails";

interface AuthLoadingProps { }

const AuthLoading: FC<AuthLoadingProps> = ({ }: AuthLoadingProps) => {
  const authContext = useContext(AuthContext);
  const [message, setMessage] = useState("Cargando");
  const [color, setColor] = useState("white");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [callbackFunction, setCallbackFunction] = useState<any>(undefined);
  const [typeError, setTypeError] = useState<EnumTypeErrorLogin>(
    EnumTypeErrorLogin.Other
  );
  const [title, setTitle] = useState("Backoffice");
  const [breadcrumb, setBreadcrumb] = useState([{ name: "Inici", link: "/" }]);

  const history = useHistory();

  const value = {
    title: title,
    breadcrumb: breadcrumb,
    setTitle: setTitle,
    setBreadcrumb: setBreadcrumb,
  };

  useEffect(() => {
    (async () => {
      try {
        const user = authContext.account.profile;
        if (user.organization !== "") {
          await setMessage(`Cargando información del Usuario: ${user.name}`);
          authContext.onAccountStatus("Available");
          const responseArea: Response = await getMyAreas(
            user.code,
            authContext.account.access_token
          );
          if (responseArea.ok) {
            const myAreas = await responseArea.json();
            if (myAreas.length > 0) {
              const responseLicence = await getServantJWTwithLicence(
                myAreas[0].code,
                "*",
                authContext.account.access_token
              );
              if (responseLicence.ok) {
                const licence = await responseLicence.text();
                authContext.setLicenceToken(licence);
                setLoading(true);
              } else {
                await setTypeError(EnumTypeErrorLogin.Licencia);
                await setError(true);
              }
            }
          } else {
            await setTypeError(EnumTypeErrorLogin.Area);
            await setError(true);
          }
        } else {
          await setTypeError(EnumTypeErrorLogin.Organization);
          await setError(true);
        }
        if (!loading) {
          await setMessage(
            `Cargando información del Usuario: ${user.name}`
          );

          console.log("AuthContextNotification", authContext.pushNotificationToken, localStorage.getItem('firebase_token'));
          const firebaseToken = localStorage.getItem('firebase_token') || '';

          setUserToken(authContext.account.profile.code, firebaseToken,
            authContext.account.access_token)
          setLoading(true);
        }
      } catch (error) {
        await setTypeError(EnumTypeErrorLogin.API);
        await setError(true);
      }
    })();
  }, []);

  return (
    <>
      {loading ? (
        <LayoutContext.Provider value={value}>
          <BrowserRouter>
            <LayoutContainer userRoles={authContext.roles}>
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  render={() => {
                    return <Dashboard />;
                  }}
                />
                <Route
                  path="/logged"
                  exact={true}
                  render={() => {
                    return <Dashboard />;
                  }}
                />
                <PrivateRoute
                  path="/admin"
                  exact={true}
                  component={Test}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.TenantAdmin]}
                />
                <PrivateRoute
                  path="/area"
                  exact={true}
                  component={DashboardArea}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <Route
                  path="/home/search"
                  exact={true}
                  component={Search}
                  userRoles={authContext.roles}
                />
                <PrivateRoute
                  path="/area/search"
                  exact={true}
                  component={Search}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode/settings"
                  exact={true}
                  component={AreaSettings}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/area/create"
                  exact={true}
                  component={AreaCreate}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode/services/:serviceCode/detail"
                  exact={true}
                  component={() => <AreaAdministrationServicesDetails account={authContext}/>}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode"
                  exact={true}
                  component={AreaDetail}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode/services/:serviceCode/settings"
                  exact={true}
                  component={ServiceSettings}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode/services/:serviceCode/disponibilitat"
                  exact={true}
                  component={ServicesDisponibilitat}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/area/:areaCode/recording"
                  exact={true}
                  component={RecordingDetails}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/services/create"
                  exact={true}
                  component={ServicesCreate}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/services/:serviceCode/edit"
                  exact={true}
                  component={ServicesEdit}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/organitzation"
                  exact={true}
                  component={AdminOrganitzation}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/:organitzationCode/recording"
                  exact={true}
                  component={RecordingDetails}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/settings"
                  exact={true}
                  component={EntityManagement}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/:organitzationCode/user"
                  exact={true}
                  component={OrganitzationUser}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/:organitzationCode/addUser"
                  exact={true}
                  component={AddUser}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/:organitzationCode/addUsers"
                  exact={true}
                  component={AddUsers}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />
                <PrivateRoute
                  path="/organitzation/:organitzationCode/editUser/:userCode"
                  exact={true}
                  component={EditUser}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.OrganizationAdmin]}
                />

                <PrivateRoute
                  path="/administration"
                  exact={true}
                  component={Administration}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.TenantAdmin]}
                />
                <PrivateRoute
                  path="/administration/organization"
                  exact={true}
                  component={AdministrationAddOrganization}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.TenantAdmin]}
                />
                <PrivateRoute
                  path="/administration/organization/edit/:organizationCode"
                  exact={true}
                  component={AdministrationEditOrganization}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.TenantAdmin]}
                />
                <PrivateRoute
                  path="/test"
                  exact={true}
                  component={DashboardArea}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.AreaAdmin]}
                />
                <PrivateRoute
                  path="/management"
                  exact={true}
                  component={Management}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.CrossReader]}
                />
                <PrivateRoute
                  path="/management/recording"
                  exact={true}
                  component={RecordingDetails}
                  userRoles={authContext.roles}
                  adminRoles={[EnumRol.CrossReader]}
                />
              </Switch>
            </LayoutContainer>
          </BrowserRouter>
        </LayoutContext.Provider>
      ) : error ? (
        <ErrorLoading typeError={typeError} />
      ) : (
        <LayoutContext.Provider value={value}>
          <Dimmer active style={{ background: "#333" }}>
            <Loader>
              <h2 style={{ color: color }}>{message}</h2>
            </Loader>
          </Dimmer>
        </LayoutContext.Provider>
      )}
    </>

  );
};

export default AuthLoading;
