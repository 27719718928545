import React from "react";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
interface Props {
  loading: boolean;
  data: any[];
  children: any;
  emptyStateMessage: string;
  className?: string;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className={props.className}>
      {props.loading ? (
        <Segment className={"br05"} padded={"very"}>
          <Dimmer active inverted size={"massive"}>
            <Loader size="large">{t("loading.defect")}</Loader>
          </Dimmer>
        </Segment>
      ) : props.data.length > 0 ? (
        <>{props.children} </>
      ) : (
        <p>{props.emptyStateMessage}</p>
      )}
    </div>
  );
};
