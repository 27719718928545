import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button, List as Popup, Table } from "semantic-ui-react";


interface Props {
    meeting: any;
  };
  
  const CommentCell = (props: Props) => {
  
    const { t } = useTranslation();
    const information = {
      comment: props.meeting.comment ||
      t("detail.meeting.comment.no_comments"),
    };
    return (
    <Popup
      key={"comment"}
      trigger={
        <Button
          color={"red"}
          size={"small"}
          onClick={(e) => { }}
          icon={"content"}
        />
      }
      position={"right center"}
    >
      {Object.entries(information).length !== 0 && (
        <>
          <Popup.Header>
            {t("detail.meeting.comment.comment_header")}
          </Popup.Header>
          <Popup.Content>
            <p>{props.meeting.comment}</p>
          </Popup.Content>
        </>
      )}
    </Popup>);
  };
  export default CommentCell