import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getServiceInfo } from "../../../../../../../assets/api";
import { getAreasUsers } from "../../../../../../../assets/areas";
import { AuthContext } from "../../../../../../../context/AuthContext";
import { User } from "../../../../../../../models/User";
import Text from "../atomic_components/Text";


interface Props {
  value: string;
  meeting: any;
}

const AssignedCell = (props: Props) => {
  const [userName, setUserName] = useState("");
  const authContext = useContext(AuthContext);
  const { t } = useTranslation()
  const token = authContext.account.access_token;
  useEffect(() => {
    (async () => {
      if (props.value === "NotAssigned") {
        setUserName(t("detail.meeting.values.not_assigned"))
      }
      else {
        const { areaCode } = await getServiceInfo(props.meeting.serviceCode, token).then(_ => _.json());
        const serviceUsers = await getAreasUsers(areaCode, token);
        const userName: any = serviceUsers?.find((user: User) => user.code === props.value);
        setUserName(userName?.name);
      }
    })()
  }, [props.value])
  return <Text>{userName || props.value}</Text>;
};

export default AssignedCell;
