import React from "react";

import { Breadcrumb, ILayoutContext } from "../types/layout";

const defaultBreadcrumb: Breadcrumb[] = [
  {
    name: "Inici",
    link: "/",
  },
];

const defaultLayoutContext: ILayoutContext = {
  title: "Non Title",
  breadcrumb: defaultBreadcrumb,
  setTitle: (title) => {},
  setBreadcrumb: (breadcrumb) => {},
};

export const LayoutContext = React.createContext(defaultLayoutContext);
