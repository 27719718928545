import React from "react";
import Screen from "./Screen";
interface Props {
  buttons: any[];
}

export default (props: Props) => {
  return (
    <>
      <Screen {...props} />
    </>
  );
};
