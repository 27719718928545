import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "../../../../../hooks/useForm";
import { useError } from "../../../../../hooks/useError";

import { Button, Dropdown, Form, Input, Modal } from "semantic-ui-react";
import { addAttendeeToMeeting, getPhonePrefixes } from "../../../../../assets/api";
import { AuthContext } from "../../../../../context/AuthContext";
import Field from "../../../../../components/Field/Field";
import { SyntheticEvent } from "react-draft-wysiwyg";

interface Props {
  eventId: string;
  modal: boolean;
  setModal: (T: boolean) => any;
}

export const ModalAddParticipant: React.FC<Props> = ({
  eventId,
  modal,
  setModal,
}) => {
  const authContext = useContext(AuthContext);

  const { t } = useTranslation();

  const [form, setForm, handleInputChange, reset] = useForm({
    fullName: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState(false);
  const [prefix, setPrefix] = useState<any>("+34");

  const handleSubmit = async () => {
    const { fullName, email } = form;
    const phone = `${prefix}${form.phone}`;

    await addAttendeeToMeeting(
      eventId,
      fullName,
      email,
      phone,
      authContext.account.access_token
    )
      .then((response) =>  response.json())
      .then((response) => {
        if(response.StatusCode === 404) {
          throw new Error();
        }
        setModal(false);
      })
      .catch((error) => {
        error.show = true;
        error.error = error;

        setError(true);

        return error;
      });
  };

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  function validatePhone(email: string) {
    const re = /^\d{9,15}$/;
    return re.test(String(email).toLowerCase());
  }
  const isValid = (form: any) => {
    const isEmailValid = validateEmail(form.email);
    const isPhoneValid = validatePhone(form.phone);
    return isEmailValid && isPhoneValid && form.fullName != "";
  };
  const getFormat = (key: string) => {
    if (key == "phone") return "phone";
    else if (key == "email") return "email";
    else if (key == "id") return "none";
    else return "text";
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      onOpen={() => setModal(true)}
    >
      <Modal.Header>
        <h2>{t("home.addParticipant.subheader")}</h2>
      </Modal.Header>
      <Modal.Content>
        <Form className="border-0" style={{ boxShadow: "none" }}>
          {Object.entries(form).map(([key, value]) => (
            <>
              <Field
                required
                format={getFormat(key)}
                title={t(`home.addParticipant.${key}`)}
                name={key}
                label={key === "phone" && <Dropdown defaultValue='+34' options={getPhonePrefixes() } onChange={(event: SyntheticEvent, data: object) => setPrefix(data.value)} selection search/>}
                value={form[key]}
                onChange={(env) => {
                  handleInputChange(env);
                  setError(false);
                }}
                textError={t(`home.addParticipant.${key}_error`)}
                autoComplete="off"
              />
              {key == "phone" && error && (
                <span className="text-danger">
                  {t("home.addParticipant.telephone_error")}
                </span>
              )}
            </>
          ))}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon={"times"}
          content={t("home.createModal.cancel")}
          onClick={() => setModal(false)}
        />
        <Button
          disabled={!isValid(form)}
          positive
          icon={"checkmark"}
          content={t("home.addParticipant.end")}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};
