import React, { useState } from "react";
import { CustomError } from "../types/error";

const initialError: CustomError = {
  error: {
    name: "",
    message: "",
  },
  show: false,
};

export const useError = (initialState: CustomError = initialError) => {
  const [error, setError] = useState<CustomError>(initialState);

  return { error, setError };
};
