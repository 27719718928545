import { EnumAPI } from "../../../enum/EnumAPI";
import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { ResultAPI, fetch } from "./API";

const URL_SERVICE = `${process.env.REACT_APP_API_URL}/${EnumAPI.Services}`;

export const postServices = async (
  mailUser: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_SERVICE}`;
  const servicePOST = {
    email: mailUser,
    areaCode: "AT01",
    languages: [
      {
        culture: "ca",
        displayName: "Service Test",
      },
    ],
    active: true,
    displayName: "Service Test",
    code: "ST01",
  };
  const body = JSON.stringify(servicePOST);

  const result = fetch(
    urlAPI,
    token,
    "Crear Servicio",
    "Error Crear Servicio",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};

export const deleteServices = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_SERVICE}/ST01`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar Servicio",
    "Error Eliminar Servicio",
    EnumHTTPMethod.DELETE
  );
  return result;
};

export const addUserToServices = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_SERVICE}/ST01/users?code=ST01&userCode=${userCode}`;
  const result = fetch(
    urlAPI,
    token,
    "Asignar User Servicio",
    "Error Asignar User Servicio",
    EnumHTTPMethod.PUT
  );
  return result;
};

export const deleteUserToServices = async (
  userCode: string,
  token: string
): Promise<ResultAPI> => {
  const urlAPI = `${URL_SERVICE}/ST01/users/${userCode}`;
  const result = fetch(
    urlAPI,
    token,
    "Eliminar User Servicio",
    "Error Eliminar User Servicio",
    EnumHTTPMethod.DELETE
  );
  return result;
};
