import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";
import {
  postOrganizations,
  putOrganizations,
  deleteOrganizations,
  addUserToOrganizations,
  deleteUserToOrganizations,
} from "./API/OrganizationsAPI";
import {
  postAreas,
  deleteAreas,
  addAdminToAreas,
  deleteAdminToAreas,
} from "./API/AreasAPI";
import {
  postServices,
  deleteServices,
  addUserToServices,
  deleteUserToServices,
} from "./API/ServicesAPI";
import { postSlots } from "./API/SlotsAPI";
import { postEventExpress } from "./API/EventAPI";
import { ResultAPI } from "./API/API";
import { AuthorizationModel } from "../../models/Authorization";
import { useTranslation } from "react-i18next";

interface TestProps {}

const Test: FC<TestProps> = ({}: TestProps) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const token = authContext.account.access_token;
  const userCode = authContext.account.profile.code; /* T000001A */
  const mailUser = authContext.account.profile.email;
  const { setTitle, setBreadcrumb } = layoutContext;
  const [check, setCheck] = useState<ResultAPI[]>([]);

  useEffect(() => {
    setTitle("Test API");
    setBreadcrumb([
      {
        name: "Test",
      },
    ]);
  }, [t, setTitle, setBreadcrumb]);

  //   useEffect(() => {}, [check]);

  const test = async () => {
    let t: ResultAPI[] = [];
    const result = [];
    let aux;
    aux = await postOrganizations(token);
    result.push(aux);
    aux = await putOrganizations(token);
    result.push(aux);
    aux = await addUserToOrganizations(userCode, token);
    result.push(aux);
    aux = await postAreas(mailUser, token);
    result.push(aux);
    aux = await addAdminToAreas(userCode, token);
    result.push(aux);
    aux = await postServices(mailUser, token);
    result.push(aux);
    aux = await addUserToServices(userCode, token);
    result.push(aux);
    aux = await postSlots(token);
    result.push(aux);
    aux = await postEventExpress(token);
    result.push(aux);
    aux = await deleteUserToServices(userCode, token);
    result.push(aux);
    aux = await deleteServices(token);
    result.push(aux);
    aux = await deleteAdminToAreas(userCode, token);
    result.push(aux);
    aux = await deleteAreas(token);
    result.push(aux);
    aux = await deleteUserToOrganizations(userCode, token);
    result.push(aux);
    aux = await deleteOrganizations(token);
    result.push(aux);

    result.forEach((a) => {
      t.push(a);
    });
    setCheck(t);
  };

  return (
    <div id={"container-test"} className={"p-3"}>
      <Button className={"br02 m-0"} onClick={() => test()}>
        Testeo API
      </Button>
      <div className={"p-5"}>
        {check.map((l, index) => {
          return (
            <p key={index} className={`${l.data ? "green" : "red"}`}>
              {l.message}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Test;
