import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks/useForm";
import { postArea } from "../../assets/api";
import { AuthContext } from "../../context/AuthContext";
import { Languages } from "../../types/service";
import Layout from "../Layout/LayoutContainer";
import Card from "../Card/Card";
import {
  Button as ButtonSemantic,
  Dimmer,
  Form,
  Loader,
} from "semantic-ui-react";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";

interface Area {
  active: boolean;
  code: string;
  displayName: string;
  email: string;
  languages: Languages[];
  organizationCode?: string;
  phone?: string;
}

const initializeArea: Area = {
  active: true,
  code: "",
  displayName: "",
  email: "",
  languages: [
    { displayName: "", culture: "ca" },
    { displayName: "", culture: "es" },
    { displayName: "", culture: "en" },
    { displayName: "", culture: "oc" },
  ],
};

export const AreaCreate = () => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [form, setForm, handleInputChange, reset] = useForm(initializeArea);
  const { languages } = form;
  const ca = languages.find((lang: Languages) => lang.culture === "ca");
  const es = languages.find((lang: Languages) => lang.culture === "es");
  const en = languages.find((lang: Languages) => lang.culture === "en");
  const oc = languages.find((lang: Languages) => lang.culture === "oc");
  const [formLangCA, setFormLangCA, handleInputChangeCA, resetCA] = useForm(ca);
  const [formLangES, setFormLangES, handleInputChangeES, resetES] = useForm(es);
  const [formLangEN, setFormLangEN, handleInputChangeEN, resetEN] = useForm(en);
  const [formLangOC, setFormLangOC, handleInputChangeOC, resetOC] = useForm(oc);

  useEffect(() => {
    setTitle(t("create.area.title"));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("create.area.title"),
      },
    ]);

    setLoading(false);
  }, [t, setTitle, setBreadcrumb]);

  const historyHandleClick = () => {
    history.push("/organitzation");
  };

  const handleSubmit = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    const formLanguages: Languages[] = [
      { ...formLangCA },
      { ...formLangES },
      { ...formLangEN },
      { ...formLangOC },
    ];

    setForm((form: Area) => {
      form.displayName = formLangCA.displayName;
      form.languages = formLanguages;
      return form;
    });

    try {
      await postArea(form, backoffice)
        .then((response) => {
          if (response.ok) return response.json();

          throw new Error(t("create.message.add_area.error"));
        })
        .then((response) => {
          toast(t("create.message.add_area.success"), {type: "success"});
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
    historyHandleClick();
  };

  const formatString = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "");
  };

  const onChangeInputCode = ({ target }: any) => {
    target.value = formatString(target.value);
    handleInputChange({ target });
  };

  return (
    <>
      <Layout.Main>
        {loading ? (
          <Dimmer active inverted size={"massive"}>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form style={{ boxShadow: "none" }} className="border-0">
                  <Form.Field>
                    <label>{t("create.area.code")}</label>
                    <input
                      name="code"
                      value={form.code}
                      onChange={(e) => onChangeInputCode(e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("create.area.name")} (ca)</label>
                    <input
                      name="displayName"
                      value={formLangCA.displayName}
                      onChange={(e) => handleInputChangeCA(e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("create.area.name")} (es)</label>
                    <input
                      name="displayName"
                      value={formLangES.displayName}
                      onChange={(e) => handleInputChangeES(e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("create.area.name")} (en)</label>
                    <input
                      name="displayName"
                      value={formLangEN.displayName}
                      onChange={(e) => handleInputChangeEN(e)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("create.area.name")} (oc)</label>
                    <input
                      name="displayName"
                      value={formLangOC.displayName}
                      onChange={(e) => handleInputChangeOC(e)}
                    />
                  </Form.Field>
                  <ButtonSemantic
                    negative
                    content={t("create.area.cancel")}
                    className="mt-3"
                    onClick={historyHandleClick}
                  />
                  <ButtonSemantic
                    positive
                    icon="edit"
                    content={t("create.area.create")}
                    className="mt-3"
                    onClick={handleSubmit}
                  />
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </Layout.Main>
    </>
  );
};
