import React, { FC, Fragment, ReactElement, useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import { MeetingQueueTable } from "../BOTableMeetingQueue";
import { statusMeeting } from "../../../../../util/meeting";
import { Col, Row } from "react-bootstrap";
import { EventMeeting } from "../../../../../models/EventMeeting";
moment.locale("es");

interface BOListEventProps {
  meeting: any;
}

const BOListEvent: FC<BOListEventProps> = ({
  meeting,
}: BOListEventProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <p className={"p-00 p-0 m-0"}>
        {t("detail.modal_assign_user.data_column.title")}
      </p>
      <List id={"list-event-info"} className={"p-0 m-0"}>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {`${t("detail.modal_assign_user.data_column.meeting_code")}:`}
          </p>
          <p className={"p-02"}>{meeting.code}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {`${t("detail.modal_assign_user.data_column.name")}:`}
          </p>
          <p className={"p-02"}>{meeting.attendees[0].fullName}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.date") + ": "}
          </p>
          <p className={"p-02"}>
            {moment(meeting["start"]).format("DD MMM YYYY")}
          </p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.start") + ": "}
          </p>
          <p className={"p-02"}>{moment(meeting["start"]).format("HH:mm")}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.end") + ": "}
          </p>
          <p className={"p-02"}>{moment(meeting.end).format("HH:mm")}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.service_code") + ": "}
          </p>
          <p className={"p-02"}>{meeting.serviceCode}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.status") + ": "}
          </p>
          <p className={"p-02"}>{t(statusMeeting(meeting.status))}</p>
        </List.Item>
        <List.Item className={"list-item"}>
          <p className={"p-01"}>
            {t("detail.modal_assign_user.data_column.video_url") + ": "}
          </p>
          <p className={"p-02"}>{meeting.url}</p>
        </List.Item>
      </List>
    </Fragment>
  );
};
export default BOListEvent;
