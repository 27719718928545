import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { fetchMSGraph, msalInstance } from "../util/auth";
import { EnumAPI } from "../enum/EnumAPI";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";

const URL_PRESENCE = `${process.env.REACT_APP_API_URL}/${EnumAPI.Presence}`;

export type Presence = {
  id: string;
  activity: string;
};

interface PresenceState {
  arrayPresence: Presence[];
}

const initialState: PresenceState = {
  arrayPresence: [],
};

export const presenceSlice = createSlice({
  name: "presence",
  initialState,
  reducers: {
    GET_PRESENCE_DATA: (state, action: PayloadAction<Presence[]>) => {
      state.arrayPresence = action.payload;
    },
  },
});

export const { GET_PRESENCE_DATA } = presenceSlice.actions;

export const fetchGetPresence =
  (token: string): AppThunk =>
  async (dispatch) => {
    const arrayPresence: Presence[] = [];
    const url = `${URL_PRESENCE}`;
    try {
      const response: Response = await fetchMSGraph(url, token);
      if (response.ok) {
        const data: Presence[] = await response.json();
        if (data.length > 0) {
          data.map((presence: Presence) => {
            arrayPresence.push(presence);
          });
        }
      }
    } catch (error) {
      console.warn('Error "PRESENCE" - Function "fetchGetPresence" : ', error);
    }
    await dispatch(GET_PRESENCE_DATA(arrayPresence));
  };

export const selectPresence = (state: RootState) =>
  state.presence.arrayPresence;

export default presenceSlice.reducer;
