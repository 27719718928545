import Screen from "./Screen";
import React, { useState } from "react";
import { TabConfig } from "./types";
import CSS from "csstype";

interface Props {
  tabsConfig: TabConfig[];
  styleTabSelected?: CSS.Properties;
  styleTabUnselected?: CSS.Properties;
  styleTab?: CSS.Properties;
  className?: string;
}

export default (props: Props) => {
  const [currentAccessor, setCurrentAccessor] = useState(
    props.tabsConfig[0].accessor
  );
  const onClickTab = (accessor: string) => {
    setCurrentAccessor(accessor);
  };
  const styleTab = props.styleTab || {
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
  };
  const styleTabSelected = {
    ...styleTab,
    ...(props.styleTabSelected || { borderBottom: "2px solid black" }),
  };
  const styleTabUnselected = {
    ...styleTab,
    ...(props.styleTabSelected || { border: "1px solid black" }),
  };
  return (
    <Screen
      {...props}
      currentAccessor={currentAccessor}
      onClickTab={onClickTab}
      styleTabSelected={styleTabSelected}
      styleTabUnselected={styleTabUnselected}
    />
  );
};
