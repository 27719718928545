import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import { deleteUserFromArea, removeAreaAdmin,removeAreaServiceManager } from "../../../assets/api";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";

interface AddUserFormProps {
  type: string;
  area: string;
  className?: string;
  users: any[];
  title: string;
  onClick: () => any;
  handleResponseAPI: Function;
  handleLoader: Function;
}

export const AddUserForm: React.FC<AddUserFormProps> = ({
  type,
  area,
  className = "",
  users,
  title,
  onClick,
  handleResponseAPI,
  handleLoader,
}) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const handleUser = async (mail: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    switch (type) {
      case "admin":
        handleRemoveAdmin(mail, backoffice);
        break;
      case "office":
        handleRemoveOfficer(mail, backoffice);
        break;
      case "serviceManager":
          handleRemoveServiceManager(mail, backoffice);
          break;
      default :
        handleRemoveOfficer(mail, backoffice);
        break;
      
    }
  };

  const handleRemoveAdmin = async (mail: string, backoffice: string) => {
    handleLoader(true);
    try {
      const response: Response = await removeAreaAdmin(area, mail, backoffice);
      if (response.ok) {
        toast((t(`edit.area.message.admin.delete.success`) + " " + mail), { type: "success"});
        handleResponseAPI({
          type: "delete",
          time: moment().format("MM/DD/YYYY, h:mm:ss a"),
        });
      } else {
        const data = await response.text();
        throw new Error(data);
      }
    } catch (e) {
      console.warn(e.message);
      toast(t("edit.area.message.admin.delete.error"), { type: "error"});
      handleLoader(false);
    }
  };

  const handleRemoveOfficer = async (mail: string, backoffice: string) => {
    handleLoader(true);
    try {
      const response: Response = await deleteUserFromArea(
        area,
        mail,
        backoffice
      );
      if (response.ok) {
        toast((t(`edit.area.message.user.delete.success`) + " " + mail), { type: "success"});
        handleResponseAPI({
          type: "delete",
          time: moment().format("MM/DD/YYYY, h:mm:ss a"),
        });
      } else {
        const data = await response.text();
        throw new Error(data);
      }
    } catch (e) {
      console.warn(e.message);
      toast(t("edit.area.message,user.delete.error"), { type: "error"});
      handleLoader(false);
    }
  };

  const handleRemoveServiceManager= async (mail: string, backoffice: string) => {
    handleLoader(true);
    try {
      const response: Response = await removeAreaServiceManager(
        area,
        mail,
        backoffice
      );
      if (response.ok) {
        toast((t(`edit.area.message.user.delete.success`) + " " + mail), { type: "success"});
        handleResponseAPI({
          type: "delete",
          time: moment().format("MM/DD/YYYY, h:mm:ss a"),
        });
      } else {
        const data = await response.text();
        throw new Error(data);
      }
    } catch (e) {
      console.warn(e.message);
      toast(t("edit.area.message,user.delete.error"), { type: "error"});
      handleLoader(false);
    }
  };

  return (
    <div className={`${type === "admin" ? null : "mt-4"}`}>
      <label className={"font-weight-bold"}>{title}</label>
      <div className={"container-btn-delete-user"}>
        {users.length === 0 ? (
          type === "admin" && (t("edit.area.no_found_admin"))
        ) : (
          Array.from(users).map((user) => (
            <Button
              className={"btn-delete-user"}
              icon={"user remove"}
              content={user.name || user.code}
              onClick={() => handleUser(user.code)}
            />
          ))
        )}
      </div>
      <Button
        icon="user add"
        color="blue"
        className="d-block w-100"
        content={`${t("edit.area.add")} ${title}`}
        onClick={onClick}
      />
    </div>
  );
};
