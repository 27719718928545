export type Languages = {
  id: number;
  culture: string;
  displayName: string;
};

export const defaultLanguages: Languages = {
  id: -1,
  culture: "",
  displayName: "",
};
