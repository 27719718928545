import React, {
  FC,
  Fragment,
  ReactElement,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, InputOnChangeData, Table } from "semantic-ui-react";
import { User } from "../../../../../models/User";
import { EventMeeting } from "../../../../../models/EventMeeting";
import Meeting from "../../../../../pages/Dashboard/components/Meeting";
import { propTypes } from "react-bootstrap/esm/Image";
import { forEachChild } from "typescript";
import userEvent from "@testing-library/user-event";
import { boolean } from "yup";
interface BOTableAssignUserProps {
  serviceUsers: User[];
  meeting :EventMeeting
  handleAssignUser: Function;
}


const BOTableAssignUser: FC<BOTableAssignUserProps> = ({
  serviceUsers,
  meeting,
  handleAssignUser,
}: BOTableAssignUserProps): ReactElement => {
  const { t } = useTranslation();
  const [searchUser, setSearchUser] = useState<string>("");
  const [searchResults, setSearchResults] = useState<User[]>([]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setSearchUser(data.value);
  };

  const handleUsers = (
     user : User[],
  ) => {
    for (let i = 0; i < serviceUsers.length; i++) {
      if (user[0] !== serviceUsers[i]) user[i] = serviceUsers[i];
      else i = serviceUsers.length
    }
    return user;
  }


  useEffect(() => {
    console.log(serviceUsers, meeting.serviceCode)
    const results = serviceUsers.filter((user) =>
       // user.serviceCode === meeting.serviceCode
      user.name.toLowerCase().includes(searchUser.toLocaleLowerCase())
  );
  console.log("RESULTS",results);

  var num = 0;
  var count = 1;

  while (num === 0 && count < results.length) {
    if (results[0].code === results[count].code) {
      results.splice(count);
      num = 1;
    }
    ++count;
  }
    setSearchResults(results);
  }, [searchUser]);


  return (
    <Fragment>
      <div className={"container-title"}>
        <Form.Input
          className={"d-flex flex-column"}
          onChange={handleChange}
          value={searchUser}
          placeholder={t("detail.modal_assign_user.servant_column.search")}
        />
      </div>
      <div className={"container-table"}>
        <Table compact={"very"} className="">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("detail.modal_assign_user.servant_column.servant")}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("detail.modal_assign_user.servant_column.action")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {searchResults.map((user: User, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>
                    <Button
                      positive
                      size={"tiny"}
                      onClick={() => {
                        handleAssignUser(user.code);
                      }}
                    >
                      {t("detail.modal_assign_user.servant_column.btn_assign")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </Fragment>
  );
};
export default BOTableAssignUser;
