import { EnumAPI } from "../../../enum/EnumAPI";
import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { ResultAPI, fetch } from "./API";
import moment from "moment";

const URL_EVENTS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Events}`;

export const postEventExpress = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_EVENTS}`;
  const eventPOST = {
    start: `${moment().format("YYYY-MM-DD")}T10:30:00Z`,
    durations: 30,
    serviceCode: "ST01",
    observations: "TEST",
    attendees: [
      {
        isOrganizer: true,
        type: "Citizen",
        name: "Maria",
        surname: "Bravo",
        secondSurname: "Soto",
        email: "mbravo@gmail.com",
        phone: "+34600112233",
        id: "11223344A",
        idType: "DNI",
        culture: "ca",
      },
    ],
  };
  const body = JSON.stringify(eventPOST);
  const result = fetch(
    urlAPI,
    token,
    "Crear Event Express",
    "Error Crear Event Express",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};
