import React, { FC, ReactElement, useEffect } from "react";
import ModalDocumentMeeting from "../../../../../pages/Dashboard/components/Modals/ModalDocumentMeeting";
interface BOPopupDocumentationProps {
  meeting: any;
  servicesRefresh?: () => void;
  timeStatus?: any;
  handleMeetingSelected?: (x: any) => void
  isSelf?: boolean
}


const BOPopupDocumentation: FC<BOPopupDocumentationProps> = ({
  meeting,
  servicesRefresh,
  isSelf,
}: BOPopupDocumentationProps): ReactElement => {

  useEffect(() => {

  }, [meeting]);
  return (
          <ModalDocumentMeeting
          meeting={meeting}
          servicesRefresh={servicesRefresh}
          isSelf={isSelf}
        />
  );
};
export default BOPopupDocumentation;
