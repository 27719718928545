import React from "react";
import { Icon } from "semantic-ui-react";
interface Props {
  value: boolean;
  timeStatus: any
}

export default ({ timeStatus }: Props) => {
  return (
    <div>
      {timeStatus.positive && <Icon name={"check"} />}
      {timeStatus.negative && <Icon name={"times"} />}
    </div>
  );
};
