import React, { Fragment, useContext, useState } from "react";
import { Button, Checkbox, Dimmer, Form, Loader } from "semantic-ui-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../Layout/LayoutContainer";
import { LayoutContext } from "../../context/LayoutContext";
import TabListView from "../TabListView";
import { emailEventType } from "../AreaSettings/types";
import TabEmail from "./components/TabEmail";
import TabSMS from "./components/TabSMS";
import TabPreferences from "./components/TabPreferences";
import TabNotification from "./components/TabNotification";

interface EntityForm {
  file: File;
  entityName: string;
  entityEmailConfirmationBody: string;
  entityEmailLinkBody: string;
  entitySmsConfirmationBody: string;
}

interface Props {
  emailEventFields: emailEventType;
  onChangeFields: (x: any) => void;
  decodeMessages: (x: emailEventType) => void;
  init: () => void;
  loading: boolean;
  values: any;
  organitzationURL: string;
  setOrganitzationURL: (x: string) => void;
  organitzationURLInformers: string;
  setOrganitzationURLInformers: (x: string) => void;
  isRecording: boolean;
  updateRecordingsForOrganization: () => void;
  historyHandleClick: () => void;
  handleSubmit: () => void;
  match: any;
  updateType : () => void;
  updateInformersNamesForService : () => void;
  showName: boolean;
  mailConfirmation: boolean;
  updateMailConfirmation: () => void;
  smsConfirmation: boolean;
  updateSmsConfirmation: () => void;
  mailLink: boolean;
  updateMailLink: () => void;
  smsLink: boolean;
  updateSmsLink: () => void;
  mailCancelation: boolean;
  updateMailCancelation: () => void;
  mailReminder: boolean;
  updateMailReminder: () => void;
  smsReminder: boolean;
  updateSmsReminder: () => void;
  areaNotification: boolean;
  allowDocumentation: boolean;
  updateAllowDocumentation: () => void;
}

export default (props: Props) => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  const [serviceCode] = useState(props.match.params.serviceCode);
  const [areaCode] = useState(props.match.params.areaCode);

  useEffect(() => {
    setTitle(t("detail.personalitzation.title"));
    setBreadcrumb([
      {
        name: t("area.title"),
        link: "/area",
      },
      {
        name: `${t("detail.title")} ${serviceCode}`,
        link: `/area/${areaCode}/services/${serviceCode}/detail`,
      },
      {
        name: t("detail.personalitzation.title"),
      },
    ]);
    props.init();
  }, [t, setTitle, setBreadcrumb]);

  const {
    entityEmailConfirmationBody,
    entityEmailLinkBody,
    entitySmsConfirmationBody,
  } = props.values as EntityForm;
  return (
    <>
      {props.loading ? (
        <Loader active inline="centered" content="Loading" size="medium">
          {t("loading.defect")}
        </Loader>
      ) : (
        <Layout.FullScreen>
          <Form>
            <TabListView
              className="mb-4"
              tabsConfig={[
                {
                  title: t("detail.personalitzation.tab_notification"),
                  accessor: "notification",
                  contentView: (
                    <TabNotification
                      onChangeFields={props.onChangeFields}                  
                      mailConfirmation={props.mailConfirmation}
                      updateMailConfirmation={props.updateMailConfirmation}
                      smsConfirmation={props.smsConfirmation}
                      updateSmsConfirmation={props.updateSmsConfirmation}
                      mailLink={props.mailLink}
                      updateMailLink={props.updateMailLink}
                      smsLink={props.smsLink}
                      updateSmsLink={props.updateSmsLink}
                      mailCancelation={props.mailCancelation}
                      updateMailCancelation={props.updateMailCancelation}
                      areaNotification={props.areaNotification}
                      mailReminder={props.mailReminder}
                      updateMailReminder={props.updateMailReminder}
                      smsReminder={props.smsReminder}
                      updateSmsReminder={props.updateSmsReminder}
                      />
                  ),
                },
                {
                  title: t("detail.personalitzation.tab_email"),
                  accessor: "email",
                  contentView: (
                    <TabEmail
                      onChangeFields={props.onChangeFields}
                      emailEventFields={props.emailEventFields}
                    />
                  ),
                },
                {
                  title: t("detail.personalitzation.tab_sms"),
                  accessor: "sms",
                  contentView: (
                    <TabSMS
                      onChangeFields={props.onChangeFields}
                      emailEventFields={props.emailEventFields}
                    />
                  ),
                },
                {
                  title: t("detail.personalitzation.tab_preferences"),
                  accessor: "preferences",
                  contentView: (
                    <TabPreferences
                      organitzationURL={props.organitzationURL}
                      setOrganitzationURL={props.setOrganitzationURL}
                      organitzationURLInformers={props.organitzationURLInformers}
                      setOrganitzationURLInformers={props.setOrganitzationURLInformers}
                      isRecording={props.isRecording}
                      updateRecordingsForOrganization={props.updateRecordingsForOrganization}
                      updateInformersNamesForService={props.updateInformersNamesForService}
                      updateType={props.updateType}
                      showName={props.showName}
                      allowDocumentation={props.allowDocumentation}
                      updateAllowDocumentation={props.updateAllowDocumentation}
                    />
                  ),
                },
              ]}
            />

            <Button
              negative
              content={t("go_back")}
              icon="left arrow"
              labelPosition="left"
              onClick={props.historyHandleClick}
            />

            <Button submit positive onClick={props.handleSubmit}>
              {t("detail.personalitzation.button")}
            </Button>
          </Form>
        </Layout.FullScreen>
      )}
    </>
  );
};
