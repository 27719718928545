import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "semantic-ui-react";
import { addAdminOrganization, addUser,getUsersOrganization } from "../../../../assets/api";
import ButtonSubmit from "../../../../components/Field/components/ButtonSubmit";
import Field from "../../../../components/Field/Field";
import { AuthContext } from "../../../../context/AuthContext";
import { useForm } from "../../../../hooks/useForm";


interface Props {
  isVisible: boolean;
  onClose: () => void;
  organizationCode: string;
  // onSubmit: Function;
  isCreateAdmin:boolean;
  updateAdmins: ()=>void;
  admins:any[];
}

export default (props: Props) => {
  const { t } = useTranslation();
  const previousLength = props.admins.length;
  
  // useEffect(()=>{
  //   console.log("HEY ITS FUCKING WORKING!!!");
  //   console.log(props.admins);
  //   console.log("!!!!!")},[props.admins]);

  if(props.isCreateAdmin)
  {  var [form, setForm, handleInputChange] = useForm({
    name:"",
    mail:"",
    code: "",
    active:true,
  });}

  else
  {
    var [form, setForm, handleInputChange] = useForm({
      code: "",
    });
  }

  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const formFormated = Object.entries(form).map(([key, value]) => ({
    key,
    value,
    format: "text",
    required: true,
  }));

  return (
    <Modal
      id={"modal-add-admin-organization"}
      size={"mini"}
      open={props.isVisible}
      onClose={props.onClose}
    >
      <Modal.Header>
        {t(`administration.assign_admin_organization.title`)}
      </Modal.Header>
      <Modal.Content>
        <Form className="border-0" style={{ boxShadow: "none" }}>
          {formFormated.map((_: any) => {
            if (_.key=="active"){_.format="boolean"};
            return (
              <Field
                format={_.format}
                required={_.required}
                label={_.label}
                title={t(
                  `administration.assign_admin_organization.form.${_.key}`
                )}
                name={_.key}
                value={_.value}
                onChange={handleInputChange}
                textError={t(
                  `administration.assign_admin_organization.form.${_.key}_error`
                )}
              />
            );
          })}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          negative
        >
          {t("btn.cancel")}
        </Button>
        <ButtonSubmit
          title={t("administration.assign_admin_organization.buttons.submit")}
          form={formFormated}
          handleSubmit={async () => {
            const newForm = {
              ...form,
            };
            try {
              console.log(newForm);
              const response = await addAdminOrganization(
                props.organizationCode,
                newForm,
                token
              );
              if (!response.ok) throw new Error("Bad Response");

              toast((
                t("administration.assign_admin_organization.success")
              ), { type: "success"});  
              props.updateAdmins();  

              if (props.admins.length == previousLength)
              {
                addUser(newForm,token).then(await addAdminOrganization(props.organizationCode,newForm,token));
                props.updateAdmins();
              }
              props.onClose();
            } catch (error) {
              toast(t("administration.assign_admin_organization.error"), { type: "error"});
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
