import React from "react";
import { Button } from "semantic-ui-react";
import { checkForm } from "../validations";
interface Props {
  form: any;
  handleSubmit: () => void;
  title: string;
  icon?: string;
}

export default (props: Props) => {
  return (
    <Button
      disabled={!checkForm(props.form)}
      positive={checkForm(props.form)}
      icon={props.icon}
      content={props.title}
      onClick={() => {
        checkForm(props.form) && props.handleSubmit();
      }}
    />
  );
};
