import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { EnumAPI } from "../enum/EnumAPI";
import { EventMeeting } from "../models/EventMeeting";
import { fetchMSGraph, msalInstance } from "../util/auth";
import { EventObject, defaultEventObject } from "../models/EventObject";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import moment from "moment";
import { mapAsyncFlatten, toArray } from "../util/array";
import { AreaInfo } from "../models/AreaInfo";
import { getAreaInfo, getServiceInfo } from "./api";
import { ServiceInfo } from "../models/ServiceInfo";
import { getServices, getServicesCodes } from "./areas";

const URL_EVENTS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Events}`;

interface eventsState {
  Event: EventObject;
  EventMeetings: EventMeeting[];
  EventWithMeetings: EventObject[];
  DeleteEvent: boolean | null;
}

const initialState: eventsState = {
  Event: defaultEventObject,
  EventMeetings: [],
  EventWithMeetings: [],
  DeleteEvent: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    GET_EVENTS: (state, action: PayloadAction<EventObject>) => {
      state.Event = action.payload;
    },
    GET_EVENTS_HAS_MEETING: (state, action: PayloadAction<EventObject[]>) => {
      state.EventWithMeetings = action.payload;
    },
    DELETE_EVENTS: (state, action: PayloadAction<boolean | null>) => {
      state.DeleteEvent = action.payload;
    },
    fetchEventsWithServiceDay: (
      state,
      action: PayloadAction<EventMeeting[]>
    ) => {
      state.EventMeetings = action.payload;
    },
  },
});

export const {
  GET_EVENTS,
  GET_EVENTS_HAS_MEETING,
  DELETE_EVENTS,
  fetchEventsWithServiceDay,
} = eventsSlice.actions;

export const getEvent = async (eventCode: string, token: string) => {
  let event: EventObject = defaultEventObject;
  const url = `${URL_EVENTS}/${eventCode}`;
  try {
    const response: Response = await fetchMSGraph(url, token);
    if (response.ok) {
      const data: EventObject = await response.json();
      event = data;
    }
  } catch (error) {
    console.warn('Error "Event" Function fetchGetEvent: ' + error);
  }
  return event;
};

export const fetchGetEvent =
  (eventCode: string, token: string): AppThunk =>
    async (dispatch) => {
      let event: EventObject = await getEvent(eventCode, token);
      await dispatch(GET_EVENTS(event));
    };

export const getEventsWithServiceDay = async (
  form: any,
  token: string,
  callback?: (events: EventMeeting[]) => any
) => {
  const events: EventMeeting[] = [];
  const formatedForm: FormInputEventType = { ...form };

  if (form.service) formatedForm.area = undefined;
  else if (form.area) formatedForm.service = "example";
  
  if (!form.dateStart) formatedForm.dateStart = moment().format("YYYYMMDD");
  if (!form.dateEnd) formatedForm.dateEnd = formatedForm.dateStart;
  
  formatedForm.dateStart = formatedForm.dateStart && formatedForm.dateStart.replace(/-/g, "");
  formatedForm.dateEnd = formatedForm.dateEnd && formatedForm.dateEnd.replace(/-/g, "");
  const url = `${URL_EVENTS}/filter/${formatedForm.dateStart}/${formatedForm.dateEnd}/${formatedForm.service}${formatedForm.area ? `/${formatedForm.area}` : ""}`;

  try {
    return await fetchMSGraph(url, token)
    .then(response => response.json())
    .then(response => {

      response.map((event: EventMeeting) => {
        events.push(mapEvent(event));
      });

      return response;

    })
  } catch (e) {
    console.warn('Error API "events" | Function getEventsWithServiceDay: ' + e);
    return events
  }
};

const getEventsNoServices = async (form: FormInputEventType, token: string) => {
  try {
    if (!form.area) return []
    let auxForm: any = { ...form };
    auxForm.area = Array.isArray(form.area) ? form.area : [form.area];
    return await mapAsyncFlatten(auxForm.area, async (area) => {
      const response = await getAreaInfo(area, token);
      if (response.ok && response.status !== 204) {
        const data: AreaInfo = await response.json();
        auxForm.service = data.services.map(_ => _.code)
        return mapAsyncFlatten(
          auxForm.service,
          async (service) => getEventsWithServiceDay({ ...auxForm, service }, token)
        )
      }
      return []
    })
  } catch (error) {
    console.warn('Error API "Events" | Function getEventsNoServices: ' + error);
  }
  return []
}


const getEventNoArea = async (form: any, token: string) => {
  const area = await getServiceInfo(form.service, token)
    .then((_): ServiceInfo => _.json())
    .then(_ => _.areaCode);

  return getEventsWithServiceDay({ ...form, area }, token)
};

const getEventsArrayServices = async (form: any, token: string) => {
  return mapAsyncFlatten(form.service, async (service) => {
    const isAreaSelected = form.area && !Array.isArray(form.area)
    if (!isAreaSelected) {
      return getEventNoArea({ ...form, service }, token);
    } else {
      return getEventsWithServiceDay({ ...form, service }, token)
    }
  })
};



type FormInputEventType = {
  dateStart?: string;
  dateEnd?: string;
  service: string | string[];
  area?: string | string[];
};

const getEventsNoServicesNoArea = async (form: FormInputEventType, token: string) => {
  const service = await getServicesCodes(token);
  return getEventsArrayServices({ ...form, service }, token);
}

export const fetchEventsWithServiceDayApi = (form: FormInputEventType, token: string): AppThunk =>
  async (dispatch) => {
    let arrayEvents = []
    
    if (!form.service || !form.service.length) {
      arrayEvents = await getEventsNoServices(form, token);
    } else {

      
      const service = toArray(form.service)
      arrayEvents = await getEventsArrayServices({ ...form, service }, token);

      console.log("ArrayEvents", arrayEvents)
    }

    await dispatch(fetchEventsWithServiceDay(arrayEvents));
  };

export const fetchGetSlotHasEvent =
  (serviceCode: string, day: string, token: string): AppThunk =>
    async (dispatch) => {
      const events: EventObject[] = [];
      const url = `${URL_EVENTS}/service/${serviceCode}/day/${day}`;
      try {
        const response: Response = await fetchMSGraph(url, token);
        if (response.ok) {
          if (response.status !== 204) {
            const data: EventObject[] = await response.json();
            data.forEach((e: EventObject) => {
              if (e.status !== "Cancelled") {
                events.push(e);
              }
            });
          }
        }
      } catch (error) {
        console.warn(
          `Error API "events" | Funcion "fetchGetSlotHasEvent" ${error}`
        );
      }
      await dispatch(GET_EVENTS_HAS_MEETING(events));
    };

export const fetchDeleteEvent =
  (serviceCode: string, slotID: string, token: string): AppThunk =>
    async (dispatch) => {
      let deleteEvents: boolean | null = null;
      const url = `${URL_EVENTS}/${serviceCode}/slot/${slotID}`;
      try {
        const response: Response = await fetchMSGraph(
          url,
          token,
          EnumHTTPMethod.DELETE
        );
        if (response.ok) {
          deleteEvents = true;
        } else {
          deleteEvents = false;
        }
      } catch (error) {
        console.warn(
          `Error API "events" | Funcion "fetchGetSlotHasEvent" ${error}`
        );
      }
      await dispatch(DELETE_EVENTS(deleteEvents));
    };

export const cleanDeleteEvent = (): AppThunk => async (dispatch) => {
  await dispatch(DELETE_EVENTS(null));
};

const mapEvent = (data: any) => {
  let observations = "";
  if (data.observations !== null && data.observations !== undefined) {
    observations = data.observations;
  }
  let event: EventMeeting = {
    code: data.code,
    slotId: data.slotId,
    status: data.status,
    videoRoomUrl: data.videoRoomUrl,
    serviceCode: data.serviceCode,
    start: new Date(data.start),
    end: new Date(data.end),
    createDate: new Date(data.createDate),
    lastModifiedDate: new Date(data.lastModifiedDate),
    attendees: data.attendees,
    comment: data.comment,
    observations: observations,
    changeHistory: data.changeHistory,
  };
  return event;
};

export const selectEvent = (state: RootState) => state.events.Event;
export const selectEventsMeetings = (state: RootState) =>
  state.events.EventMeetings;
export const selectEventWithMeetings = (state: RootState) =>
  state.events.EventWithMeetings;
export const selectEventDeleted = (state: RootState) =>
  state.events.DeleteEvent;

export default eventsSlice.reducer;
