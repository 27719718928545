import React from "react";

interface Props {
  children: any
}

const Text = (props: Props) => {
  return <p>{props.children}</p>;
};

export default Text;
