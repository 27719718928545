import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  EventObject,
  defaultEventObject,
} from "../../../../../models/EventObject";
import { User } from "../models/User";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetEvent, selectEvent } from "../../../../../assets/events";
import { Button, Grid, Icon, Modal as UIModal } from "semantic-ui-react";
import BOTableAssignUser from "./BOTableAssignUser";
import {
  fetchServiceInfo,
  getServicesByUserApi,
  selectServiceInfo,
  selectServiceUsers,
  selectServicesByUser,
  getUsersByService,
} from "../../../../../assets/services";
import BOListEvent from "./BOListEvent";
import meetings, {
  postAssignMeeting,
  selectMeetingsAssignUser,
} from "../../../../../assets/meetings";
import { useTranslation } from "react-i18next";
import { MeetingQueueTable } from "../BOTableMeetingQueue";
import { Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../../../../context/AuthContext";
import { EventMeeting } from "../../../../../models/EventMeeting";
import { discardMeeting, getService } from "../../../../../assets/api";
import { toast } from "react-toastify";
interface BOModalAssignUserProps {
  meeting: EventMeeting;
  servicesRefresh?: () => void;
}


const BOModalAssignUser: FC<BOModalAssignUserProps> = (
  props: BOModalAssignUserProps
): ReactElement => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assignUserResponse = useSelector(selectMeetingsAssignUser);
  const serviceInfo = useSelector(selectServiceInfo);
  const userService = useSelector(selectServicesByUser);
  const [open, setOpen] = useState(false);
  const [servUsers, setServUsers] = useState<any>([]);
  const serviceUsers = useSelector(selectServiceUsers);


  useEffect(() => {
    (async () => {
      if (props.meeting.serviceCode) {
        await dispatch(getServicesByUserApi(props.meeting.serviceCode, token));
        await dispatch(fetchServiceInfo(props.meeting.serviceCode, token));
      }
    })();
    const getUsers = async() => await getUsersByService(props.meeting.serviceCode, token);
    let promise = new Promise ((resolve, reject) => {
      try {
        resolve(getUsers());
      } catch (error) {
        reject(error);
      }
    })
    promise.then(function (getUsers) {
      setServUsers(getUsers);
    });
  }, [!servUsers]);


  const handleAssignUser = async (userCode: string) => {
    await dispatch(
      postAssignMeeting(
        serviceInfo.areaCode,
        props.meeting.code,
        userCode,
        props.meeting.status.includes('Express'),
        token
      )
    );
    props.servicesRefresh && props.servicesRefresh();
    setOpen(false);
  };


  const handleReleaseMeeting = async () => {
    // console.log(`Bearer ${token}`);
    const serviceDetails: any = await getService(
      props.meeting.serviceCode,
      token
    ).then((_) => _.json());

    const discardMeetingResponse: any = await discardMeeting(
      props.meeting.code,
      serviceDetails.areaCode,
      props.meeting.assignedTo || "",
      token
    );

    if (discardMeetingResponse.ok) {
       props.servicesRefresh && await props.servicesRefresh();
      toast(t("detail.modal_assign_user.toast.discard_success"), { type: "success"});
      setOpen(false);
    } else {
      toast(t("detail.modal_assign_user.toast.discard_error"), { type: "error"});
    }
  };
  return (
    <UIModal
      id={"modal-assign-user"}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color={"grey"} icon={"add user"} />}
    >
      <UIModal.Header>{t("detail.modal_assign_user.title")}</UIModal.Header>
      <UIModal.Content className={"p-0"}>
        <Container className={"p-0"}>
          <Row className={"pr-3 pl-3 pb-3"}>
            <Col md={8} className={"p-3"}>
              <BOListEvent meeting={props.meeting} />
            </Col>
            <Col md={4} className={"p-3"}>
              <BOTableAssignUser
                serviceUsers={servUsers}
                meeting={props.meeting}
                handleAssignUser={handleAssignUser}
              />
            </Col>
          </Row>
        </Container>
      </UIModal.Content>
      <UIModal.Actions>
        {props.meeting.assignedTo &&
          props.meeting.assignedTo !== "NotAssigned" && (
            <Button
              onClick={handleReleaseMeeting}
              content={t("detail.modal_assign_user.btn.release_meeting")}
            />
          )}
        <Button
          negative
          onClick={() => {
            setOpen(false)
            }}
          content={t("btn.cancel")}
        />
      </UIModal.Actions>
    </UIModal>
  );
};
export default BOModalAssignUser;
