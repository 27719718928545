import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Message, Modal, Input, Icon } from "semantic-ui-react";
import { getUsersOrganization } from "../../../assets/api";
import { AuthContext } from "../../../context/AuthContext";
import { UserInfo, defaultUserInfo } from "../../../models/User";
import { toast } from "react-toastify";
import { tryCatch } from "../../../util/utils";

interface ModalAddUserAreaProps {
  open: boolean;
  handleOpen: Function;
  handleSubmit: Function;
  type: "admin" | "user"| "serviceManager";
}

const ModalAddUserArea: React.FC<ModalAddUserAreaProps> = ({
  open,
  handleOpen,
  handleSubmit,
  type,
}: ModalAddUserAreaProps) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [userValue, setUserValue] = useState<string>("");
  const [usersOrg, setUsersOrg] = useState<UserInfo[]>([]);
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [usersSelected, setUsersSelected] = useState<UserInfo[]>([]);

  const getToken = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    return backoffice;
  };

  useEffect(() => {
    (async () => {
      const token = await getToken();
      const users: UserInfo[] = await getUsersOrganization(
        false,
        false,
        false,
        false,
        token
      );
      setUsersOrg(users);
    })();
  }, []);

  useEffect(() => {
    if (userValue.length > 0) {
      const result: UserInfo[] = usersOrg.filter((user: UserInfo) => {
        return tryCatch(
          () => user.mail.toLowerCase().includes(userValue.toLowerCase()),
          false
        )
      });
      setUsers(result);
    } else {
      setUsers([]);
    }
  }, [usersOrg, userValue]);

  const handleChangeUserValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserValue(event.currentTarget.value);
  };

  const handleOptionClick = (value: UserInfo) => {
    if (!usersSelected.find((_) => _.code == value.code)) {
      setUsersSelected([...usersSelected, value]);
    }
  };

  const handleCancel = () => {
    handleOpen(false);
    setUserValue("");
    setUsersSelected([]);
  };

  const handleConfirm = async () => {
    if (usersSelected.length > 0) {
      await handleSubmit(usersSelected);
      setUserValue("");
      setUsersSelected([]);
    } else {
      toast(t(`edit.area.modal_add_${type}s.error`), { type: "error"});
    }
  };

  const handleClickDelete = (user: UserInfo) => {
    setUsersSelected(usersSelected.filter((_) => _.code != user.code));
  };

  return (
    <Modal
      id={"modal-add-admin-area"}
      size={"tiny"}
      open={open}
      onClose={handleCancel}
    >
      <Modal.Header>{t(`edit.area.modal_add_${type}s.title`)}</Modal.Header>
      <Modal.Content>
        <p>{t(`edit.area.modal_add_${type}s.info`)}</p>
        <div className={"container-search"}>
          <Input
            className={""}
            icon={"search"}
            placeholder={t(`edit.area.modal_add_${type}s.users`)}
            value={userValue}
            onChange={handleChangeUserValue}
          />
        </div>

        <div className={"container-mail"}>
          <div className={"box-mail"}>
            {users
              .filter((user) => !usersSelected.find((_) => user.code == _.code))
              .map((user: UserInfo) => (
                <p onClick={() => handleOptionClick(user)}>{user.mail}</p>
              ))}
          </div>
        </div>
        {usersSelected.length > 0 && (
          <div className="border-top mt-2">
            <p className={"title-add br0"}>
              {t(`edit.area.modal_add_${type}s.users_added_title`)}
            </p>
            <div className="d-flex flex-wrap">
              {usersSelected.map((userSelected, index) => (
                <Button
                  key={index}
                  className={"btn-add m-1"}
                  icon
                  labelPosition={"right"}
                  onClick={() => handleClickDelete(userSelected)}
                  color={"green"}
                >
                  {`${userSelected.mail}`}
                  <Icon name="delete" />
                </Button>
              ))}
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button icon="check" onClick={handleCancel}>
          {t("edit.area.modal_add_users.cancel")}
        </Button>
        <Button
          positive
          icon="check"
          content={t("edit.area.modal_add_users.add")}
          onClick={handleConfirm}
        ></Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalAddUserArea;
