import React from "react";

interface HeaderProps {
  children?: React.ReactNode;
}

interface BodyProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => (
  <div className="card-header">{props.children}</div>
);
const Body: React.FC<BodyProps> = (props) => (
  <div className="card-body">{props.children}</div>
);

interface ICard {
  color?: string;
}

interface CardComponents {
  Header?: React.FC<HeaderProps>;
  Body: React.FC<BodyProps>;
}

const Card: React.FC<ICard> & CardComponents = ({
  children,
  color = "#F5F5F5",
}) => (
  <>
    <div
      className="card rounded-0"
      style={{ background: color, border: "1px solid #ddd" }}
    >
      {children}
    </div>
  </>
);

Card.Header = Header;
Card.Body = Body;

export default Card;
