function validateEmpty(value: string, required: boolean) {
  return value != "" || !required;
}

export function validateEmail(email: string, required: boolean) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email
    ? re.test(String(email).toLowerCase())
    : validateEmpty(email, required);
}
export function validatePhone(phone: string, required: boolean) {
  const re = /^\d{9,15}$/;
  return phone
    ? re.test(String(phone).toLowerCase())
    : validateEmpty(phone, required);
}
export function validatePostalCode(value: string, required: boolean) {
  const re = /^\d{5}$/;
  return value ? re.test(String(value)) : validateEmpty(value, required);
}
export function validateNumber(value: string, required: boolean) {
  const re = /^\d+$/;
  return value ? re.test(String(value)) : validateEmpty(value, required);
}

export function validateText(value: string, required: boolean) {
  return validateEmpty(value, required);
}

export const evaluate = (props: any, setIsError?: any) => {
  const required = props.required || false;
  if (props.format == "phone") {
    const evaluation = !validatePhone(props.value, required);
    if (setIsError) {
      setIsError(evaluation);
    } else return evaluation;
  } else if (props.format == "email") {
    const evaluation = !validateEmail(props.value, required);
    if (setIsError) {
      setIsError(evaluation);
    } else return evaluation;
  } else if (props.format == "number") {
    const evaluation = !validateNumber(props.value, required);
    if (setIsError) {
      setIsError(evaluation);
    } else return evaluation;
  }
  else if (props.name === "postalCode") {
    const evaluation = !validatePostalCode(props.value, required);
    if (setIsError) {
      setIsError(evaluation);
    } else return evaluation;
  } else if (props.format == "text") {
    const evaluation = !validateText(props.value, required);
    if (setIsError) {
      setIsError(evaluation);
    } else return evaluation;
  } else {
    if (setIsError) {
      setIsError(false);
    } else return false;
  }
};

export function checkForm(value: any) {
  return Object.values(value).every((_) => !evaluate(_));
}
