import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getServiceInfo } from '../../../../../../../../../assets/api';
import { postAssignMeeting } from '../../../../../../../../../assets/meetings';
import { AuthContext } from '../../../../../../../../../context/AuthContext';
import { EnumRol } from '../../../../../../../../../enum/EnumRol';
import { Meeting } from '../../../../../../../../../types/dashboard';
import Button from '../atomic_components/Button';
interface Props {
  meeting: any;
  timeStatus: any;
  isDisabled?: boolean;
  servicesRefresh?: () => void;
};

const SelfAssignAction = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const userCode = authContext?.account?.profile?.code;
  const token = authContext.account.access_token;

  const getIsDisabled = () => {
    let notScheduleMeeting = props.timeStatus.negative;
    const isSelfAssigned = props.meeting.assignedTo === userCode
    const isInvalidStatus = props.meeting.status === t("detail.status.cancelled") || props.meeting.status === t("detail.status.finalized")
    return isSelfAssigned || isInvalidStatus || notScheduleMeeting;
  }

  const isDisabled = getIsDisabled();

  const onClick = async (e: any) => {
    try {
      e.preventDefault();
      const serviceInfo = await getServiceInfo(props.meeting.serviceCode, token).then(_ => _.json());
      const { shared } = await authContext.getTokenForScopes();

      await dispatch(postAssignMeeting(
        serviceInfo.areaCode,
        props.meeting.code,
        userCode,
        false,
        shared
      ))

      props.servicesRefresh && props.servicesRefresh()
    } catch (error) {
      console.log("error")
    }
  }

  return (<Button
    disabled={isDisabled}
    onClick={onClick}
    icon={"check"}
  />);
};


export default SelfAssignAction;