import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyD0T9BgSrbuKlYjizVHMKS2lbRY6QJI1NY",
  authDomain: "in2tant-1623a.firebaseapp.com",
  databaseURL: "https://in2tant-1623a.firebaseio.com",
  projectId: "in2tant-1623a",
  storageBucket: "in2tant-1623a.appspot.com",
  messagingSenderId: "654118554537",
  appId: "1:654118554537:web:6258c39ef1536d2616d2b8"
};
export default firebaseConfig;