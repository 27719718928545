import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { getService } from "../../../assets/api";
import { AuthContext } from "../../../context/AuthContext";
import { ServiceInfo } from "../../../models/ServiceInfo";
import { getNameServiceLanguage } from "../../../util/languages";

interface TableCellNameProps {
  codeService: string;
}

export const TableCellName: React.FC<TableCellNameProps> = ({
  codeService,
}: TableCellNameProps) => {
  const authContext = useContext(AuthContext);
  const [serviceName, setServiceName] = useState<string>("");

  useEffect(() => {
    (async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      try {
        let r: Response = await getService(codeService, backoffice);
        if (r.ok) {
          let s: ServiceInfo = await r.json();
          let l = getNameServiceLanguage(s);
          setServiceName(l.displayName);
        }
      } catch (error) {}
    })();
  }, [codeService]);

  return <Table.Cell>{serviceName.toLocaleUpperCase()}</Table.Cell>;
};
