import React from "react";
import { Form } from "semantic-ui-react";

interface Props {
  title: string;
  value: string;
  onChange: (s: any) => void;
  id: string;
  textarea?: boolean;
}

export default (props: Props) => {
  return (
    <Form.Field key={props.id}>
      <label style={{ marginBottom: "20px", marginTop: "20px" }}>
        {props.title}
      </label>
      <input
        value={props.value}
        onChange={(organitzationURL: any) =>
          props.onChange(organitzationURL.target.value)
        }
        id={props.id}
      />
    </Form.Field>
  );
};
