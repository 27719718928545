import React, { useContext } from 'react';
import { AuthContext } from '../../../../../../../../../context/AuthContext';
import { Meeting } from '../../../../../../../../../types/dashboard';
import { MODAL_TYPES } from '../../../type/modalTypes';
import Button from '../atomic_components/Button';
interface Props {
  timeStatus?: any;
  handleMeetingSelected: (x: any) => void
  meeting: Meeting & { status: string, assignedTo: string }
  setOpenModal: (x: any) => void,
  isSelf?: boolean
};

const RefuseAction = (props: Props) => {
  const authContext = useContext(AuthContext);
  const userCode = authContext?.account?.profile?.code;
  const isSelfAssigned = props.meeting.assignedTo === userCode
  const onClick = (e: any) => {
    e.preventDefault();
    props.handleMeetingSelected(props.meeting);
    props.setOpenModal({
      type: MODAL_TYPES.REFUSE,
      isOpen: true,
    });
  }

  return (<Button
    disabled={(props.isSelf && !isSelfAssigned)}
    onClick={onClick}
    icon={"history"}
  />);
};

export default RefuseAction;
