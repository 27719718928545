import { EnumAPI } from "../../../enum/EnumAPI";
import { EnumHTTPMethod } from "../../../enum/EnumHTTPMethod";
import { ResultAPI, fetch } from "./API";
import moment from "moment";

const URL_SLOTS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Slots}`;

export const postSlots = async (token: string): Promise<ResultAPI> => {
  const urlAPI = `${URL_SLOTS}/batch`;
  const slotPOST = {
    serviceCode: "ST01",
    slotsInfo: [
      {
        day: moment().format("YYYYMMDD"),
        roomAvail: 1,
        slotDuration: 30,
        firstSlotTime: "08:00",
        slotCount: 2,
        slotBreak: 30,
      },
    ],
  };
  const body = JSON.stringify(slotPOST);
  const result = fetch(
    urlAPI,
    token,
    "Crear Slot",
    "Error Crear Slot",
    EnumHTTPMethod.POST,
    body
  );
  return result;
};
