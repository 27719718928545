import axios from 'axios';
import { loadAbort } from '../util/loadabort.utilities';

export const getUsersFromServiceCode = (service: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.get(
            `${process.env.REACT_APP_API_URL}/Services/${service}/users`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const getUsersFromAreaCode = (area: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.get(
            `${process.env.REACT_APP_API_URL}/Areas/${area}/users`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const putUsersService = (service: string, code: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.put(
            `${process.env.REACT_APP_API_URL}/Services/${service}/users?code=${service}&userCode=${code}`,
            "",
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
    
}

export const getPresence = (token: string) => {
    const controller = loadAbort();

    return {
        call: axios.get(
            `${process.env.REACT_APP_API_URL}/Presence`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const deleteUserFromArea = (user: string, service: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.delete(
            `${process.env.REACT_APP_API_URL}/Services/${service}/users/${user}`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}