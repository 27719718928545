import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import areas, { fetchServicesByUser, selectServices } from "../../assets/areas";
import {
  fetchEventsWithServiceDayApi,
  selectEventsMeetings,
} from "../../assets/events";
import { fetchGetMeeting } from "../../assets/meetings";
import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";
import Screen from "./Screen";
import { useHistory } from "react-router-dom";

interface Props {
  match: any
}
const AreaSearch = (props: Props) => {
  const history = useHistory();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const token = authContext.account.access_token;

  const { t } = useTranslation();

  const { setTitle, setBreadcrumb } = layoutContext;
  const meetings = useSelector(selectEventsMeetings);

  const dispatch = useDispatch();
  const serviceSelector = useSelector(selectServices)
  const services: string[] = serviceSelector
    ?.filter(({ active }) => active)
    .map(({ displayName, code }, index) => (code));
  const initialHistory = props.match?.path?.split("/")[1];
  const initialHistoryPath = "/" + (initialHistory !== 'home' ? initialHistory : "");
  const isInitialHistoryHome = initialHistory === "home";
  const userCode = authContext.account.profile.code;
  const [form, setForm] = useState<any>({
    area: [],
    service: [],
    dateStart: "",
    dateEnd: "",
  });

  useEffect(() => {
    (async () => {
      if (isInitialHistoryHome) {
        const backOfficeToken = await (await authContext.getTokenForScopes()).backoffice
        await dispatch(fetchServicesByUser(userCode, backOfficeToken));
      }
    })();
  }, [initialHistory]);


  useEffect(() => {
    (async () => {
      if (!!services.length && !!areas.length) {
        await dispatch(fetchGetMeeting(form.area, form.service, token,));
      }
    })();
  }, [form.area, form.service]);

  useEffect(() => {
    (async () => {
      if (isInitialHistoryHome) {
        const auxForm = {
          ...form,
          dateStart: moment().format("YYYY-MM-DD"),
          dateEnd: moment().format("YYYY-MM-DD"),
          service: services
        }
        setForm(auxForm)
        onHandleClickSearch(auxForm)
      } else {
        const auxForm = {
          ...form,
          dateStart: moment().format("YYYY-MM-DD"),
          dateEnd: moment().format("YYYY-MM-DD")
        }

        setForm(auxForm)
        onHandleClickSearch(auxForm)
      }
    })();
  }, [serviceSelector]);

  useEffect(() => {
    setTitle(t("area.search.title"));
    setBreadcrumb([
      {
        name: t(`breadcrump.${initialHistory}`),
        link: initialHistoryPath,
      },
      {
        name: t("area.search.title"),
      },
    ]);
  }, [t, setTitle, setBreadcrumb]);

  const onHandleClickSearch = async (paramForm?: any) => {
    const auxForm: any = paramForm || form;
    setIsButtonClicked(true);
    console.log("1er", auxForm.dateStart)
    
    if (auxForm.dateStart > auxForm.dateEnd) {
      toast(t("area.search.message.error.not_valid_dates"), { type: "error"});
    } else {
      /*const date1 = new Date(auxForm.dateStart);
      const date2 = new Date(auxForm.dateEnd)
      const year1 = date1.getFullYear();
      const month1 = date1.getMonth();
      const day1 = date1.getDate();

      const year2 = date2.getFullYear();
      const month2 = date2.getMonth();
      const day2 = date2.getDate();
      const formattedDate1 = `${year1}-${month1}-${day1}`;
      const formattedDate2 = `${year2}-${month2}-${day2}`;*/
      
      console.log("11111:", auxForm.dateStart)
      auxForm.dateStart = auxForm.dateStart || moment().format("YYYY-MM-DD");
      console.log("22222", auxForm.dateEnd)
      auxForm.dateEnd = auxForm.dateEnd || moment().format("YYYY-MM-DD");
      if (!auxForm.service.length) {
        auxForm.service = services;
      }
      setForm(auxForm);
    }
    await dispatch(fetchGetMeeting(form.area, form.service, token));
    await dispatch(fetchEventsWithServiceDayApi(auxForm, token));

  };

  const historyHandleClick = () => {
    history.push(initialHistoryPath);
  };

  const refreshMeeting = async () => {
    await dispatch(fetchGetMeeting(form.area, form.service, token));
    await dispatch(fetchEventsWithServiceDayApi(form, token));
  };

  return (
    <Screen
      {...props}
      form={form}
      initialHistory={initialHistory}
      setForm={setForm}
      refreshMeeting={refreshMeeting}
      onHandleClickSearch={onHandleClickSearch}
      historyHandleClick={historyHandleClick}
      isButtonClicked={isButtonClicked}
      meetings={meetings}
    />
  );
};

export default AreaSearch;