export interface Documentation {
  name: string;
  createdDate: string;
  modifiedDate: string;
  link: string;
};

export interface DocumentApiResponse {
  result: Documentation[];
  errors: any[];
  hasErrors: boolean;
};
export const defaultDocumentApiResponse:DocumentApiResponse = {
  result: [],
  errors: [],
  hasErrors: false,
}