import React, { useContext, useEffect, useState } from "react";
import { getAreaInfo, getServiceInfo } from "../../../../../../../assets/api";
import { AuthContext } from "../../../../../../../context/AuthContext";
import Text from "../atomic_components/Text";

interface Props {
  value: any
}

const AreaCell = (props: Props) => {
  const [areaDisplay, setAreaDisplay] = useState("");

  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  useEffect((() => {
    (async () => {
      try {
        const response = await getServiceInfo(props.value, token).then(_ => _.json());
        setAreaDisplay(response.areaCode);
      } catch (error) {
        setAreaDisplay(props.value || "");
      }
    })()
  }), [props.value])
  return <Text>{areaDisplay || props.value}</Text>;
};

export default AreaCell;
