import React from "react";
import { useHistory } from "react-router-dom";
import ButtonList from "../../../../components/ButtonList";
interface Props {}

export default (props: Props) => {
  const history = useHistory();
  const buttons = [
    {
      icon: "address card",
      message: "administration.buttons.add_organization",
      onClick: () => {
        history.push("/administration/organization");
      },
    },
  ];
  return <ButtonList buttons={buttons} />;
};
