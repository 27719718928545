import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Form, Button, Checkbox } from "semantic-ui-react";
import { encode } from "js-base64";

import { AuthContext } from "../../context/AuthContext";
import { AuthContextType } from "../../types/auth";

import { Service } from "../../types/service";
import { getService, deleteService, putService, getAdminOrganization } from "../../assets/api";
import { putServiceNameLength } from "../../assets/personalitzation"

import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import LayoutContainer from "../Layout/LayoutContainer";
import ModalDeleteService from "./ModalDeleteService";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";

const ServicesEdit = () => {
  const auth = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  const { t } = useTranslation();

  const [loading, isLoading] = useState<boolean>(true);
  const [openDeleteService, setOpenDeleteService] = useState<boolean>(false);
  const [organitzation, setOrganitzation] = useState<string>("");

  const defaultsService: Service = {
    languages: [],
    code: "",
    areaCode: "",
    active: false,
    displayName: "",
    email: "",
    recordMeetingEnabled: true,
    uri: "",
    serviceType: null,
    sendPreviousDocumentation: false,
  };
  const [services, setServices] = useState<Service>(defaultsService);
  const { serviceCode } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    setTitle(t("edit.service.title"));
    setBreadcrumb([
      {
        name: t("area.title"),
        link: "/area",
      },
      {
        name: t("edit.service.title"),
      },
    ]);

    getServices();
  }, [t, setTitle, setBreadcrumb]);

  const getServices = async () => {
    if (loading) {
      const token = await auth.getTokenForScopes();

      await getService(serviceCode, token.backoffice)
        .then(async (response) => response.json())
        .then((response: Service) => {
          setServices(response);
          isLoading(false);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const getDisplayName = (culture: string) => {
    if (services.languages.length > 0) {

      const serv = services.languages.filter(
        (language) => language.culture === culture
      );
      if (serv[0] === undefined) {
        services.languages.push({culture: culture, displayName: ''});
      }
      else return serv[0].displayName;
    }
    return;
  };

  const handleChange = (event: any, culture: string) => {
    const currentServices = services;
    const languages = [...currentServices.languages];
    const language = languages.filter(
      (language) => language.culture === culture
    )[0];
    const index = languages.indexOf(language);

    language.displayName = event.target.value;
    languages[index] = language;
    currentServices.languages = languages;
    

    setServices(currentServices);
  };

  const checkValuesOfServices = (services: Service) => {
    if (
      services.languages[0].displayName.length > 0 &&
      services.languages[1].displayName.length > 0 &&
      services.languages[2].displayName.length > 0 &&
      services.languages[3].displayName.length > 0
    )
      return true;

    return false;
  };

  const updateService = async () => {
    const token = await auth.getTokenForScopes();
    console.log("TOKEN", token);

    const validServices = checkValuesOfServices(services);
    console.log("ValidServices", validServices);

    if (validServices) {
      console.log("services", services);
      putService(services, token.backoffice)
        .then((response: Response) => {
          console.log("response", response)
          if (!response.ok)
            throw new Error(
              t("message.edit.service.error") + ` (${services.code})`
            );
          return response.json();
        })
        .then((response: Service) => {
          history.push(
            `/area/${services.areaCode}/services/${services.code}/detail`
          );
          toast((
            t("message.edit.service.success") + ` (${services.code})`
          ), { type: "success"});
        })
        .catch((e) => {
          toast((e.message), { type: "error"});
        });

        const body = {
          subjectData: encode(""),
          bodyData: encode(services.languages[0].displayName),
        };
        //const body = encode(services.languages[0].displayName);

        await getAdminOrganization(token.backoffice)
        .then(async (response: any) => {
          if (response.ok) return await response.json();
          throw new Error("Error");
        })
        .then(async (response: any) => {
          console.log("RESPONSE",response.code);
          console.log("token", token.backoffice);
          setOrganitzation(response.code);
          const result = await putServiceNameLength(response.code, services.areaCode, services.code, "ca", body, token.backoffice)
            console.log("api resp", result);
            if(!result) {
              toast(t("edit.service.length_warning"), {type: "error"});
            }
          
        });

        console.log("orgCode", organitzation);
    } else {
      toast(t("message.edit.service.uncomplete"), { type: "error"});
    }
  };

  const isActive = (event: SyntheticEvent, data: any) => {
    const currentServices = services;
    currentServices.active = data.checked;

    setServices(currentServices);
  };

  const updateType = (event: SyntheticEvent, data: any) => {
    const currentServices = services;
    currentServices.serviceType = data.value || null;

    setServices({ ...currentServices });
  };

  const handleOpenDeleteService = (value: boolean) => {
    setOpenDeleteService(value);
  };

  const handleDeleteService = async (serviceArea: string) => {
    const { backoffice } = await auth.getTokenForScopes();

    try {
      const response = await deleteService(serviceArea, backoffice);
      if (response.ok) {
        toast(t("edit.service.delete.success"), { type: "success"});
        history.replace("/area");
      } else {
        throw new Error(t("edit.service.delete.error"));
      }
    } catch (e) {
      // toast((e), { type: "error"});
    }
  };

  const checkIfServicesHasRecordersEnabled = () => {
    if(services.recordMeetingEnabled)
      return (
        <>
          <Form.Field>
            <label>{t("edit.service.type")}</label>
          </Form.Field>
          <div className="d-flex">
            <Form.Field>
              <Checkbox
              radio
              label={t("edit.service.information_service")}
              name="checkboxRadioGroup"
              value="C"
              checked={services.serviceType === "C" || services.serviceType === null }
              onChange={updateType}
              />
            </Form.Field>
            <Form.Field className="ml-3">
              <Checkbox
              radio
              label={t("edit.service.assisted_processing")}
              name="checkboxRadioGroup"
              value="T"
              checked={services.serviceType === "T"}
              onChange={updateType}
              />
            </Form.Field>
          </div>
        </>
      );
  }

  return (
    <LayoutContainer.Main>
      {!loading && (
        <div>
          { console.log(services) }
          <Form className="border-0" style={{ boxShadow: "none" }}>
            <Form.Field>
              <label>{t("edit.service.name")} (ca)</label>
              <input
                defaultValue={getDisplayName("ca")}
                autoComplete="off"
                onChange={(event) => handleChange(event, "ca")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("edit.service.name")} (es)</label>
              <input
                defaultValue={getDisplayName("es")}
                autoComplete="off"
                onChange={(event) => handleChange(event, "es")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("edit.service.name")} (en)</label>
              <input
                defaultValue={getDisplayName("en")}
                autoComplete="off"
                onChange={(event) => handleChange(event, "en")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("edit.service.name")} (oc)</label>
              <input
                defaultValue={getDisplayName("oc")}
                autoComplete="off"
                onChange={(event) => handleChange(event, "oc")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("edit.service.active")}</label>
              <Checkbox
                label={t("edit.service.label_active")}
                defaultChecked={services.active}
                onChange={(event, data) => isActive(event, data)}
              ></Checkbox>
            </Form.Field>
            <Button color="blue" onClick={history.goBack}>
              {t("go_back")}
            </Button>
            <Button positive type="submit" onClick={updateService}>
              {t("edit.service.save")}
            </Button>
            <Button
              negative
              type="submit"
              style={{ float: "right" }}
              onClick={() => handleOpenDeleteService(true)}
            >
              {t("edit.service.delete.btn_title")}
            </Button>
          </Form>
        </div>
      )}
      <ModalDeleteService
        open={openDeleteService}
        handleOpen={handleOpenDeleteService}
        handleDelete={handleDeleteService}
      />
    </LayoutContainer.Main>
  );
};

export default ServicesEdit;
