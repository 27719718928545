import { AxiosCall } from "../models/axios.models";
import { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";

const useFetchAndLoad = () => {
    const [loading, setLoading] = useState(false);
    let controller: AbortController;

    const callEndpoint = async (axiosCall: AxiosCall<any>) => {
        if(axiosCall.controller) controller = axiosCall.controller;
        
        let result = {} as AxiosResponse<any>;
        setLoading(true);

        try{
            result = await axiosCall.call;
        }catch(error: any){
            setLoading(false);
            throw error;
        }

        setLoading(false);
        return result;
    };

    const cancelEndpoint = () => {
        setLoading(false);
        controller && controller.abort();
    }

    useEffect(() => {
        return () => {
            cancelEndpoint();
        }
    }, []);

    return { loading, callEndpoint };
};

export default useFetchAndLoad;