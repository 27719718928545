import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { getAreaInfo } from "../../assets/api";
import { AuthContext } from "../../context/AuthContext";
import { AreaInfo } from "../../models/AreaInfo";
import { getNameAreaLanguage } from "../../util/languages";

interface AreaNameProps {
  codeArea: string;
}

export const AreaName: React.FC<AreaNameProps> = ({ codeArea }) => {
  const authContext = useContext(AuthContext);
  const [AreaName, setAreaName] = useState<string>("");

  useEffect(() => {
    (async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      try {
        let r: Response = await getAreaInfo(codeArea, backoffice);
        if (r.ok) {
          let s: AreaInfo = await r.json();
          let l = getNameAreaLanguage(s);
          setAreaName(l.displayName);
        }
      } catch (error) {}
    })();
  }, [codeArea]);

  return <p>{AreaName.toLocaleUpperCase()}</p>;
};
