export enum EnumMeetingStatus {
  SCHEDULED = "Scheduled",
  SCHEDULED_EXPRESS = "ScheduledExpress",
  SCHEDULED_PROGRAMMED = "ScheduledProgrammed",
  SCHEDULED_CUSTOM = "ScheduledCustom",
  WAITING_FOR_CITIZEN = "WaitingForCitizen",
  CITIZEN_WAITING = "CitizenWaiting",
  IN_PROGRESS = "InProgress",
  FINALIZED = "Finalized",
  CANCELLED = "Cancelled",
  NOT_ATTENDEED = "NotAttendeed",
}
