type ErrorFunction = (y?: Error) => any

export const tryCatch = (func: () => any, onError: any | ErrorFunction) => {
  try {
    return func();
  } catch (error) {
    if (typeof onError === 'function') {
      return onError(error);
    }
    else {
      return onError;
    }
  }
}