import React, { useContext } from "react";
import ButtonList from "../../../../../components/ButtonList";
import { AuthContext } from "../../../../../context/AuthContext";
interface Props {}

export default ({}: Props) => {
  const authContext = useContext(AuthContext);

  const profileName = authContext.account.profile.organization;
  return <></>;
};
