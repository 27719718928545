import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import { EnumAPI } from "../enum/EnumAPI";
import { UserInfo } from "../models/User";
import { fetchMSGraph } from "../util/auth";

const URL_ORGANIZATIONS = `${process.env.REACT_APP_API_URL}/${EnumAPI.Organizations}`;

interface OrganizationsState {
  users: UserInfo[];
}

const initialState: OrganizationsState = {
  users: [],
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    GET_USERS: (state, action: PayloadAction<UserInfo[]>) => {
      state.users = action.payload;
    },
  },
});

export const { GET_USERS } = organizationsSlice.actions;

export const fetchGetAllUserInOrganitzations =
  (token: string): AppThunk =>
  async (dispatch) => {
    let users: UserInfo[] = [];
    const url = `${URL_ORGANIZATIONS}/users`;
    try {
      const response: Response = await fetchMSGraph(url, token);
      if (response.ok) {
        const data: UserInfo[] = await response.json();
        data.forEach((user) => {
          users.push(user);
        });
      }
    } catch (error) {
      console.warn(
        'Error "ORGANIZATIONS" Function "fetchGetAllUserInOrganitzations": ' +
          error
      );
    }
    await dispatch(GET_USERS(users));
  };

export const selectOrganizationsAllUsers = (state: RootState) =>
  state.organizations.users;

export default organizationsSlice.reducer;
