import React from "react";
import { useTranslation } from "react-i18next";
import { statusMeeting } from "../../../../util/meeting";
import { statusOtp } from "../../../../util/otpAvailable";
import Screen from "./Screen";
interface Props {
  data: any[];
  translationHeader?: (x: string) => string;
}

export default (props: Props) => {
  const { t } = useTranslation();

  const formatData = () => {
    const data = props.data.map((_) => {
      console.log("DATA", _)
      const param = { ..._ };
      param["meetingType"] = t(statusMeeting(param["meetingType"]));
      param["meetingStatus"] = t(statusMeeting(param["meetingStatus"]));
      param["isOtpAvailable"] = t(statusOtp(param["isOtpAvailable"]));
      return param;
    });
    return data;
  };

  return <Screen {...props} data={formatData()} />;
};
