export const statusMeeting = (status: string) => {
  let response = "";
  switch (status) {
    case "Scheduled":
      response = "detail.status.scheduled";
      break;
    case "ScheduledExpress":
      response = "detail.status.scheduledexpress";
      break;
    case "ScheduledProgrammed":
      response = "detail.status.scheduledprogrammed";
      break;
    case "ScheduledCustom":
      response = "detail.status.scheduled";
      break;
    case "WaitingForCitizen":
      response = "detail.status.waitingforcitizen";
      break;
    case "CitizenWaiting":
      response = "detail.status.citizenwaiting";
      break;
    case "InProgress":
      response = "detail.status.inprogress";
      break;
    case "NotAttendeed":
      response = "detail.status.not_attended";
      break;
    case "Finalized":
      response = "detail.status.finalized";
      break;
    case "Cancelled":
      response = "detail.status.cancelled";
      break;
    case "Down":
      response = "detail.status.down";
      break;
    case "Completed":
      response = "detail.status.completed";
      break;
    case "Unassigned":
      response = "detail.status.unassigned";
      break;
    default:
      response = status;
      break;
  }
  return response;
};
