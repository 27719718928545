import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import areasReducer from "../assets/areas";
import eventsReducer from "../assets/events";
import servicesReducer from "../assets/services";
import slotsReducer from "../assets/slots";
import meetingsReducer from "../assets/meetings";
import presenceReducer from "../assets/presence";
import organizationsReducer from "../assets/organizations";

export const store = configureStore({
  reducer: {
    areas: areasReducer,
    events: eventsReducer,
    services: servicesReducer,
    slots: slotsReducer,
    meetings: meetingsReducer,
    presence: presenceReducer,
    organizations: organizationsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
