import React, { FC } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { EnumRol } from "../enum/EnumRol";

interface PrivateRouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  userRoles: EnumRol[];
  adminRoles: EnumRol[];
  path: string;
  exact: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  userRoles,
  adminRoles,
  ...rest
}) => {
  const handleRol = () => {
    let response = false;
    adminRoles.forEach((admin) => {
      if (userRoles.includes(admin)) {
        response = true;
      }
    });
    return response;
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (handleRol()) {
          return <Component {...rest} {...props} />;
        } else {
          return <Redirect to={"/"} />;
        }
      }}
    />
  );
};
export default PrivateRoute;
