import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../context/LayoutContext";

export const Breadcrumb: React.FC<{}> = () => {
  const layoutContext = useContext(LayoutContext);

  const { breadcrumb } = layoutContext;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb bg-transparent p-0">
        {breadcrumb.map((item: any, index: number) => {
          if (item.link !== undefined)
            return (
              <li key={index} className="breadcrumb-item">
                <Link to={item.link}>{item.name}</Link>
              </li>
            );

          return (
            <li
              key={index}
              className="breadcrumb-item active"
              aria-current="page"
            >
              {item.name}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
