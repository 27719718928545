import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../context/AuthContext";
import {
  deleteUserFromArea,
  removeAreaAdmin,
  removeOrganizationAdmin,
} from "../../../../assets/api";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import moment from "moment";
import { MODAL_TYPES } from "../types/modal";
import ModalAssignAdmin from "./ModalAssignAdmin";
import { propTypes } from "react-bootstrap/esm/Image";

interface AddUserFormProps {
  type: string;
  organizationCode: string;
  className?: string;
  title: string;
  updateAdmins: () => void;
  admins:any[];
}

export const AddUserForm: React.FC<AddUserFormProps> = ({
  type,
  organizationCode,
  className = "",
  admins,
  title,
  updateAdmins,
}) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    type: "",
    isVisible: false,
  });

  // updateAdmins();
  const handleOnCloseModalAdmin = () => {
    setModal({
      type: "",
      isVisible: false,
    });
  };

  const handleUser = async (code: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    switch (type) {
      case "admin":
        handleRemoveAdmin(code, backoffice);
        break;
    }
  };

  const handleRemoveAdmin = async (code: string, backoffice: string) => {
    try {
      const response: Response = await removeOrganizationAdmin(
        organizationCode,
        code,
        backoffice
      );
      if (response.ok) {
        toast((t(`edit.area.message.admin.delete.success`) + " " + code), { type: "success"});
        updateAdmins();
      } else {
        const data = await response.text();
        throw new Error(data);
      }
    } catch (e) {
      toast(t("edit.area.message.admin.delete.error"), { type: "error"});
    }
  };

  return (
    <div className={`${className}`}>
      <label className={"font-weight-bold"}>{title}</label>
      <div className={"container-btn-delete-user"}>
        {admins.length === 0 ? (
          <p>{`${
            type === "admin"
              ? t("edit.area.no_found_admin")
              : t("edit.area.no_found_user")
          }`}</p>
        ) : (
          admins.map((admin) => (
            <Button
              className={"btn-delete-user"}
              icon={"user remove"}
              content={admin.name || admin.code}
              onClick={() => handleUser(admin.code)}
            />
          ))
        )}
      </div>
      <Button
        icon="user add"
        color="blue"
        className="d-block w-100"
        content={`${t("edit.area.add")} ${title}`}
        onClick={() =>
          setModal({
            type: MODAL_TYPES.ASSIGN_ADMIN,
            isVisible: true,
          })
        }
      />
      <ModalAssignAdmin
        isVisible={modal.isVisible}
        onClose={handleOnCloseModalAdmin}
        organizationCode={organizationCode}
        // onSubmit={updateAdmins}
        isCreateAdmin = {false}
        updateAdmins = {updateAdmins}
        admins = {admins}
      />
    </div>
  );
};
