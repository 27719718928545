export type Attendee = {
  fullName: string;
  culture: string;
  email: string;
  phone: string;
  type: string;
  isOrganizer: boolean;
  personId: string;
  personIdType: string;
};

export const defaultAttendee: Attendee = {
  fullName: "",
  culture: "",
  email: "",
  phone: "",
  type: "",
  isOrganizer: true,
  personId: "",
  personIdType: "",
};

export const defaultAttendee1: Attendee = {
  fullName: "Ana Arango",
  culture: "es",
  email: "aarango@gmail.com",
  phone: "600112233",
  type: "",
  isOrganizer: true,
  personId: "10234569A",
  personIdType: "DNI",
};
export const defaultAttendee2: Attendee = {
  fullName: "Bruno Bera",
  culture: "es",
  email: "bbera@gmail.com",
  phone: "677889944",
  type: "",
  isOrganizer: true,
  personId: "78451296B",
  personIdType: "DNI",
};
export const defaultAttendee3: Attendee = {
  fullName: "Carlos Carrillo",
  culture: "es",
  email: "ccarrillo@gmail.com",
  phone: "644556677",
  type: "",
  isOrganizer: true,
  personId: "23568974C",
  personIdType: "DNI",
};
