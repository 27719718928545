import { AuthorizationBase } from "./AuthorizationBase";
import { Log, UserManager, WebStorageStateStore, User } from "oidc-client";
import { authConfig } from "../configs/authConfig";
import {
  AuthorizationModel,
  defaultsAuthorizationModel,
  UserInfoAPI,
} from "../models/Authorization";
import { fetchMSGraph, getAuthRolesADFS } from "../util/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { EnumRol } from "../enum/EnumRol";
import { toast } from "react-toastify";

export class AuthorizationADFS extends AuthorizationBase {
  private userManager: UserManager;

  constructor() {
    super();

    this.userManager = new UserManager({
      ...authConfig.adfs.identity,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    Log.logger = console;
    Log.level = Log.DEBUG;

    this.licenceHealthCheck();
  }

  onSignIn = () => {
    this.userManager.signinRedirect();
  };

  onSignOut = (error?: boolean) => {
    this.userManager.signoutRedirect();

    if (error) window.location.pathname = "/error/login";
  };

  getAccount = async () => {
    let authModel: AuthorizationModel = defaultsAuthorizationModel;

    try {
      const user = await this.userManager.getUser();

      if (user !== null) {
        const response = await fetchMSGraph(
          `${process.env.REACT_APP_API_URL}/User/info`,
          user.access_token
        );

        if (response.ok) {
          const data: UserInfoAPI = await response.json();
          const roles: EnumRol[] = getAuthRolesADFS(data);
          this.roles = roles;
          authModel.access_token = user.access_token;
          authModel.id_token = user.id_token;
          authModel.profile = {
            code: data.code,
            organization: data.organizationCode,
            email: data.mail,
            name: data.name,
            roles: roles,
          };
        } else {
          this.onSignOut(true);
        }
      }
    } catch (error) {
      console.log(JSON.stringify(error));

      toast(("No s'ha pogut iniciar sessió"), { type: "error"});
      window.location.pathname = "/error/login";
    }
    return JSON.stringify(authModel);
  };

  setLicenceToken = (licence: string) => {
    this.licence = licence;
  };

  licenceHealthCheck = () => {
    console.info(
      "The system subscribe to event for determine if the token has been expired"
    );

    this.userManager.events.addAccessTokenExpiring(() => {
      this.userManager
        .signinSilent({
          scope: authConfig.adfs.identity.scope,
          response_type: authConfig.adfs.identity.response_type,
        })
        .then((user: Oidc.User) => {
          console.info("Token refresh. The system renew the token", user);
          this.account.access_token = user.access_token;

          console.info("This is the new token", this.getTokenForScopes());
        })
        .catch((error: Error) => {
          this.userManager.getUser().then((user: any) => {
            console.error(
              "Token expired. The system can't refresh the token",
              user,
              error
            );
          });
        });
    });
  };

  async getTokenForScopes() {
    const token = this.account.access_token;
    return { backoffice: token, shared: token };
  }

  async onAccountStatus(status: string) {
    const { backoffice } = await this.getTokenForScopes();
    const body = JSON.stringify({ activity: status });

    const result = await fetchMSGraph(
      process.env.REACT_APP_API_URL + "/Presence",
      backoffice,
      EnumHTTPMethod.POST,
      body
    ).then((response) => response.json());

    this.accountStatus = result;
  }
}
