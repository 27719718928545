import React from "react";
import Screen from "./Screen";
interface Props {
  meetingsList: any[];
  usersArea: any;
  service: any;
  getNextEvent: any;
  refreshMeetingList: any;
}

export default (props: Props) => {
  return <Screen {...props} />;
};
