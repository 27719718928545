import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import LayoutContainer from "../../../components/Layout/LayoutContainer";

import Field from "../../../components/Field/Field";
import { Button, Form} from "semantic-ui-react";
import { checkForm } from "../../../components/Field/validations";
import { AddUserForm } from "./components/AddUserForm";
import { AddConsultorForm } from "./components/AddConsultorForm";
import ButtonListEditAdministration from "./components/ButtonListEditAdministration";

interface Props {
  handleSubmit: any;
  form: any;
  handleInputChange: any;
  organitzationCode: string;
  administrators: any[];
  match: any;
  updateAdministrators: () => void;
  consultors: any[];
  updateConsultors: () => void;
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const [organizationCode] = useState(props.match.params.organizationCode);
  return (
    <>
      <LayoutContainer.Main>
        <Form className="border-0" style={{ boxShadow: "none" }}>
          {props.form.map((_: any) => {
            return (
              <Field
                format={_.format}
                required={_.required}
                label={_.label}
                title={t(`administration.edit_organization.form.${_.key}`)}
                name={_.key}
                value={_.value}
                onChange={props.handleInputChange}
                textError={t(
                  `administration.edit_organization.form.${_.key}_error`
                )}
              />
            );
          })}

        </Form>
        <AddUserForm
          type="admin"
          organizationCode={organizationCode}
          title={t("edit.area.admins")}
          className="my-4"
          admins={props.administrators}
          updateAdmins={props.updateAdministrators}
        />
        <AddConsultorForm
          type="consult"
          organizationCode={organizationCode}
          title={t("edit.area.consultors")}
          className="my-4"
          consultors={props.consultors}
          updateConsultors={props.updateConsultors}
        />
        <Button
          disabled={!checkForm(props.form)}
          positive={checkForm(props.form)}
          icon="checkmark"
          content={t("administration.edit_organization.button_create")}
          onClick={() => {
            checkForm(props.form) && props.handleSubmit();
          }}
        />
      </LayoutContainer.Main>
      <LayoutContainer.Article>
        <ButtonListEditAdministration
          organizationCode={props.organitzationCode}
          form={props.form}
          administrators={props.administrators}
          updateAdmins = {props.updateAdministrators}
        />
      </LayoutContainer.Article>
    </>
  );
};
