import React from "react";
import { useTranslation } from "react-i18next";
import { Button as ButtonSemantic, Modal } from "semantic-ui-react";
interface Props {
  setModal: (x: boolean) => void;
  modal: boolean;
  onAccept: () => void;
  headerText?: string;
}

const Screen = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={() => props.setModal(false)} open={props.modal} closeIcon>
      <Modal.Header>
        <h2>{t("home.discardModal.header")}</h2>
      </Modal.Header>
      <Modal.Content>
        <h3>{props.headerText || t("home.discardModal.subheader")}</h3>
      </Modal.Content>
      <Modal.Actions>
        <ButtonSemantic
          negative
          content={t("home.discardModal.cancel")}
          onClick={() => props.setModal(false)}
        />
        <ButtonSemantic
          icon="checkmark"
          content={t("home.discardModal.end")}
          positive
          onClick={async () => {
            props.onAccept();
            props.setModal(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
export default Screen;