import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";
import { AuthContext } from "../../../context/AuthContext";
import { UserInfo } from "../../../models/User";

interface TableOrganitzationUserProps {
  arrayUsers: UserInfo[];
}

const TableOrganitzationUser: FC<TableOrganitzationUserProps> = ({
  arrayUsers,
}: TableOrganitzationUserProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const editUser = (user: UserInfo) => {
    history.push(
      "/organitzation/" +
        authContext.account.profile.organization +
        "/editUser/" +
        user.code,
      user
    );
  };

  return (
    <div className={"container-table"}>
      <Table compact={"very"} key={"table-user"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("organitzation.user.panel.table.name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("organitzation.user.panel.table.code")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("organitzation.user.panel.table.edit")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrayUsers.length > 0 ? (
            arrayUsers.map((user: UserInfo, index) => {
              return (
                <Table.Row key={"u-" + index}>
                  <Table.Cell className={"align-middle"}>
                    {user.name}
                  </Table.Cell>
                  <Table.Cell className={"align-middle"}>
                    {user.code}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      color={"orange"}
                      icon={"user"}
                      onClick={() => editUser(user)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                {t("organitzation.user.panel.table.no_found")}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};
export default TableOrganitzationUser;
