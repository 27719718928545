import React from "react";
import { useHistory } from "react-router-dom";
import ButtonList from "../../../../components/ButtonList";
interface Props {}

export default (props: Props) => {
  const history = useHistory();

  const historyHandleClick = () => {
    history.push("/administration");
  };

  const buttons = [
    {
      icon: "arrow left",
      message: "go_back",
      onClick: historyHandleClick,
    },
  ];
  return <ButtonList buttons={buttons} />;
};
