import React, { useContext } from "react";
import { discardMeeting, serviceGetInfo } from "../../../../../assets/api";
import { AuthContext } from "../../../../../context/AuthContext";
import { AuthorizationModel } from "../../../../../models/Authorization";
import { EventMeeting } from "../../../../../models/EventMeeting";
import { ServiceInfo } from "../../../../../models/ServiceInfo";
import { Meeting } from "../../../../../types/dashboard";
import Screen from "./Screen";
interface Props {
  setModal: (x: boolean) => void;
  modal: boolean;
  meeting?: EventMeeting & Meeting;
  onAccept?: () => void;
  headerText?: string;
}

const ModalDiscardMeeting = (props: Props) => {
  const authContext = useContext(AuthContext);
  const onAccept = async () => {
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));

    const name = account.profile.code;

    const { shared } = await authContext.getTokenForScopes();
    if (props.meeting) {
      const service: ServiceInfo = await serviceGetInfo(
        props.meeting.serviceCode,
        shared
      );
      await discardMeeting(
        props.meeting.meetingID || props.meeting.code,
        service.areaCode,
        name,
        shared
      );
    }
    props.onAccept && props.onAccept();
  };
  return <Screen {...props} onAccept={onAccept} />;
};

export default ModalDiscardMeeting;
