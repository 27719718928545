import React, { useContext, useEffect, useState } from "react";
import { assignMeetingQueue, getNextQueue } from "../../../../../assets/api";
import ButtonList from "../../../../../components/ButtonList";
import { AuthContext } from "../../../../../context/AuthContext";
import { AuthorizationModel } from "../../../../../models/Authorization";
import { MeetingListType } from "../../../../../types/dashboard";
interface Props {
  meetings: MeetingListType[];
  setModalCreate: (x: boolean) => void;
  setProgramedCreate: (x: boolean) => void;
  getMeetingAndAssign: Function;
  service: string,
  area: string
}

export default ({ meetings, service, area, setModalCreate, setProgramedCreate, getMeetingAndAssign }: Props) => {

  const authContext = useContext(AuthContext);

  // const getMeetingAndAssign = async () => {
  //   const account: AuthorizationModel = await authContext
  //     .getAccount()
  //     .then((response) => JSON.parse(response));

  //     const name = account.profile.code;
  //     const { shared } = await authContext.getTokenForScopes();

  //     const nextQueuedMeeting = await getNextQueue(
  //       service,
  //       area,
  //       name,
  //       shared
  //     ).then((response) => {
  //       if (response.ok) return response.json();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //     const { meetingID } = nextQueuedMeeting[0];
  //     await assignMeetingQueue(area, meetingID, name, shared)
    

  // }

  const [buttons, setButtons] = useState<any[]>([]);
  const addButtonExpress = () => {
    const response = {
      icon: "address card",
      message: "home.create",
      onClick: () => setModalCreate(true),
    };
    return response;
  };

  const addButtonProgrammed = () => {
    const response = {
      icon: "address card",
      message: "home.create_programmed",
      onClick: () => setProgramedCreate(true),
    };
    return response;
  }

  const addButtonGetMeeting = () => {
    const response = {
      icon: "address book",
      message: "home.retrieve_event",
      onClick: () => getMeetingAndAssign(),
    };
    return response;
  };

  useEffect(() => {
    (async () => {
      const arrayButtons: any[] = [
        await addButtonGetMeeting(),
        await addButtonProgrammed(),
        await addButtonExpress(),
      ];
      setButtons(arrayButtons);
    })();
  }, [meetings]);

  return (
    <>
      <ButtonList buttons={buttons} />
    </>
  );
};
