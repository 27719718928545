import React from "react";

import { IAttendeeAdapter, ISubjectAdapter } from "../../../models/event.models";

import { Popup } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

interface IProps{
    code: string,
    comment: string,
    attendees: IAttendeeAdapter[],
    subjects1: ISubjectAdapter[],
    subjects2: ISubjectAdapter[],
    subjects3: ISubjectAdapter[]
}

const AreaAdministrationServiceDetailsMeetingsAttendee = ({code, attendees, comment, subjects1, subjects2, subjects3}: IProps) => {

    const { t } = useTranslation();
    console.log("SUBJECTS", subjects1, subjects2, subjects3, attendees);

    return <Popup header={t("detail.meeting.details_code.meeting_data")} content={
        <>
            {attendees.map((attendee, index) => {
                return (
                    <div style={{ marginTop: '10px', marginBottom: '10px'}}>
                        <span style={{display:'block', marginBottom: '5px'}}><b>{t("detail.meeting.details_code.citizen_title")} {index + 1}</b></span>
                        <b>{ t("detail.meeting.details_code.fullName") }</b> {attendee.Name}
                        <br />
                        <b>{ t("detail.meeting.details_code.personId") }</b> {attendee.ID}
                        <br />
                        <b>{ t("detail.meeting.details_code.phone") }</b> {attendee.Phone}
                        <br />
                        <b>{ t("detail.meeting.details_code.email") }</b> {attendee?.Mail}
                    </div>
                )
            })}
            <b>{t("detail.meeting.details_code.observations")}</b> <br/> {comment}
            <br />
            {(subjects1 && subjects1.length > 0) && (
                <>
                <b>{t("detail.meeting.details_code.subjects1")}</b> {subjects1[0]?.description}
                <br />
                </> 
            )}
            {(subjects2 && subjects2.length > 0) && (
                <>
            <b>{t("detail.meeting.details_code.subjects2")}</b> {subjects2[0]?.description}
            <br /> </> )}
            {(subjects3 && subjects3.length > 0) && (
                <>
            <b>{t("detail.meeting.details_code.subjects3")}</b> {subjects3[0]?.description} </>)}
        </>
    } trigger={<p>{code}</p>} />

}

export default AreaAdministrationServiceDetailsMeetingsAttendee;