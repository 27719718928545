import { fetchMSGraph } from "../util/auth";

import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";

export const registerUsers = async (users: any, token: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/User/batch`;

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    users
  );
  return response;
};

export const getUserInfo = async (token: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/User/info`;

  const response = await fetchMSGraph(endpoint, token,);
  return response;
};

export const setUserToken = async (userCode: string, token: string, tokenEndpoint: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/User/pushnotification`;
  const body = { userCode, token }
  const response = await fetchMSGraph(
    endpoint,
    tokenEndpoint,
    EnumHTTPMethod.POST,
    JSON.stringify(body)
  );
};
