import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
interface Props {
  buttons: any[];
}

export default ({ buttons }: Props) => {
  return (
    <>
      {buttons.map((button) => {
        if (button.message.length > 0)
          return (
            <Button
              message={button.message}
              icon={button.icon}
              onClick={button.onClick}
              disabled={button.disabled}
            />
          );
      })}
    </>
  );
};
