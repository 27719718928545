import { Languages } from "./Languages";
import { Service } from "./Service";

export type AreaInfo = {
  email: string;
  phone: string | null;
  url: string | null;
  organizationCode: string;
  languages: Languages[];
  services: Service[];
  active: boolean;
  displayName: string;
  code: string;
};

export const defaultAreaInfo: AreaInfo = {
  active: true,
  code: "",
  displayName: "",
  email: "",
  languages: [],
  organizationCode: "",
  phone: null,
  services: [],
  url: null,
};
