import React, { useContext, useEffect, useState } from "react";
import { getOrganizations } from "../../assets/personalitzation";
import { AuthContext } from "../../context/AuthContext";
import Screen from "./Screen";

interface Props {
  header: string;
  onChange: (x: string[]) => void;
  classNameContainer?: string;
  styleContainer?: any;
}

export default (props: Props) => {
  const [organizationSelect, setOrganizationSelect] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [token, setToken] = useState("");
  const authContext = useContext(AuthContext);

  const getToken = async () => {
    if (token) return token;
    else {
      const newToken = await (await authContext.getTokenForScopes()).backoffice;
      setToken(newToken);
      return newToken;
    }
  };

  useEffect(() => {
    (async () => {
      const token = await getToken();
      const userCode = authContext.account.profile.code; /* T000001A */
      const organizations = await getOrganizations(userCode, token).then((organization) => {
        try {
          return organization.json();
        } catch (error) {
          return [];
        }
      });
      setOrganizations(organizations);
    })();
  }, []);

  const onChange = (event: any, data: any) => {
    const area = data.value;
    setOrganizationSelect(area);
    props.onChange(area);
  };

  const getFormatOrganization = () => {
    return organizations.map((_: any, index: number) => ({
      key: index,
      value: _.code,
      text: _.displayName,
    }));
  };

  return (
    <Screen
      {...props}
      areas={getFormatOrganization()}
      onChange={onChange}
      areaSelect={organizationSelect}
    />
  );
};
