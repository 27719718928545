import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
interface Props {
  header: string;
  children: any;
}

export default (props: Props) => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  return (
    <div>
      <button
        className="col px-0 mt-4 bg-transparent border-0 "
        onClick={() => setIsContentVisible(!isContentVisible)}
      >
        <div
          className="d-flex justify-content-between"
          style={{ borderBottom: "1px solid black" }}
        >
          <div />
          <label>{props.header}</label>
          <Icon name={isContentVisible ? "chevron up" : "chevron down"} />
        </div>
      </button>
      <div className={`${isContentVisible ? "visible" : "invisible"}`}>
        {props.children}
      </div>
    </div>
  );
};
