import React from "react";
import Screen from "./Screen";
import { AutoGridLabelType } from "./type";
interface Props {
  labels: AutoGridLabelType[];
  numRows: number;
  renderCell: (label: AutoGridLabelType) => any;
}

export default (props: Props) => {
  const labelsReverse = [...props.labels].reverse();
  const elementsPerRow = Math.ceil(props.labels.length / props.numRows);
  const newLabels = Array(Math.ceil(props.numRows))
    .fill(0)
    .map(function (_, i) {
      return [...labelsReverse]
        .slice(i * elementsPerRow, i * elementsPerRow + elementsPerRow)
        .reverse();
    })
    .reverse();
  return <Screen {...props} labels={[...newLabels]} />;
};
