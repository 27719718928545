import React, { useContext } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import * as firebase from "firebase/app";
import { FirebaseOptions } from "firebase/app";
import Login from "./components/Login/Login";
import { ToastContainer } from "react-toastify";
import { firebaseConfig } from "./constants/firebase";
import { AuthContext } from "./context/AuthContext";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const authContext = useContext(AuthContext);
  const version = `${process.env.REACT_APP_VERSION_TAG}`;

  Notification.requestPermission().then((permition) => {
    if (permition === "granted") {
      const app = firebase.initializeApp(firebaseConfig);
      const messaging: any = getMessaging(app);
      const currentToken = getToken && getToken(messaging)

      return currentToken;
    }
  }).then((currentToken) => {
    console.log("currentToken", currentToken)

    if (currentToken) {
      localStorage.removeItem('firebase_token');
      localStorage.setItem('firebase_token', currentToken);

      authContext.setPushNotificationToken(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });


  return (
    <div className="App">
      <Login />
      <ToastContainer position="top-center" />
      <div className="p-3 float-right">
        <small className="text-secondary">Version {version}</small>
      </div>
    </div>
  );
};

export default App;
