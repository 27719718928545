import React, { useEffect, useState } from "react";
import ButtonList from "../../../../../components/ButtonList";
import { useTranslation } from "react-i18next";

interface Props {
  setModalDiscard: (x: boolean) => void;
  setModalFinalize: (x: boolean) => void;
}

export default ({ setModalDiscard, setModalFinalize }: Props) => {
  const [buttons, setButtons] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const arrayButtons = [
      {
        icon: "history",
        message: t("home.refuse"),
        onClick: () => setModalDiscard(true),
      },
      {
        icon: "checkmark",
        message: t("home.end"),
        onClick: () => setModalFinalize(true),
      },
    ];
    setButtons(arrayButtons);
  }, [t]);

  return (
    <>
      <ButtonList buttons={buttons} />
    </>
  );
};
