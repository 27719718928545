import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceInfo, selectServiceInfo } from "../../assets/services";
import BOCalendar from "./BOCalendar";
import BOModalDisponibilitatDelete from "./BOModalDisponibilitatDelete";
import BOModalDisponibilitat from "./BOModalDisponibilitat";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../context/LayoutContext";
import Layout from "../Layout/LayoutContainer";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../Button/Button";
import FullCalendar, { CalendarApi } from "@fullcalendar/react";
import moment from "moment";
import { fetchGetSlotsCalendarMonth } from "../../assets/slots";
import { AuthContext } from "../../context/AuthContext";
import { getNameServiceLanguage } from "../../util/languages";
import i18n from "../../configs/i18n";

// import moment from "moment";

interface RouterParams {
  serviceCode: string;
  areaCode: string;
}
interface ServicesDisponibilitatProps
  extends RouteComponentProps<RouterParams> {}

let forceCache: any;
const ServicesDisponibilitat: FC<ServicesDisponibilitatProps> = ({
  match,
}: ServicesDisponibilitatProps): ReactElement => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { setTitle, setBreadcrumb } = layoutContext;
  const calendarRef = useRef<FullCalendar>(null);
  const serviceInfo = useSelector(selectServiceInfo);
  const [serviceCode] = useState(match.params.serviceCode);
  const [areaCode] = useState(match.params.areaCode);
  const [isLoading, setIsLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {

  }, [t, i18n.language]);
  
  useEffect(() => {
    setBreadcrumb([
      {
        name: t("area.title"),
        link: "/area",
      },
      {
        name: `${t("detail.title")} ${serviceCode}`,
        link: `/area/${areaCode}/services/${serviceCode}/detail`,
      },
      {
        name: t("detail.btn_availability"),
      },
    ]);
    (async () => {
      await dispatch(fetchServiceInfo(serviceCode, token));
    })();
  }, [dispatch, serviceCode, setBreadcrumb, t]);

  useEffect(() => {
    const serviceLenguage = getNameServiceLanguage(serviceInfo);
    setTitle(
      `${t(
        "service_availability.title"
      )} - ${serviceLenguage.displayName.toLowerCase()} (${serviceInfo.code.toUpperCase()})`
    );
  }, [serviceInfo, setTitle]);

  const handleEventsApi = async (c: CalendarApi) => {
    const month = moment(c.getDate()).format("MM");
    const year = moment(c.getDate()).format("YYYY");
    handleLoading(true);

    dispatch(fetchGetSlotsCalendarMonth(serviceCode, year, month, token, t));
  
    handleLoading(false);
  };

  const handleToday = async () => {
    const c = calendarRef.current?.getApi();
    if (c !== undefined) {
      c.today();
      await handleEventsApi(c);
    }
    forceCache && forceCache();
  };

  const handleOpenAdd = (e: boolean) => {
    setOpenAdd(e);
  };

  const handleOpenDelete = (e: boolean) => {
    setOpenDelete(e);
  };

  const handleLoading = (e: boolean) => {
    setIsLoading(e);
  };

  const handleBackPage = () => {
    history.push(`/area/${areaCode}/services/${serviceCode}/detail`);
  };

  const arrayButtons = [
    {
      icon: "plus",
      message: "service_availability.btn_add",
      onClick: () => handleOpenAdd(true),
    },
    {
      icon: "eraser",
      message: t("btn.delete"),
      onClick: () => handleOpenDelete(true),
    },
    {
      icon: "arrow left",
      message: "go_back",
      onClick: handleBackPage,
    },
  ];

  return (
    <Fragment>
      <Layout.Main>
        <Container id={"container-service-disponibilitat"} className={""}>
          <Row className={"container-calendar"}>
            <Col className={"d-flex justify-content-center"}>
              <BOCalendar
                loading={isLoading}
                calendarRef={calendarRef}
                handleEventsApi={handleEventsApi}
                forceChacheRef={(ref: any) => {
                  forceCache = ref;
                }}
              ></BOCalendar>
            </Col>
          </Row>
        </Container>
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button, index) => (
          <Button
            key={index}
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
      <BOModalDisponibilitat
        serviceCode={serviceCode}
        openAdd={openAdd}
        handleOpen={handleOpenAdd}
        handleToday={handleToday}
      />
      <BOModalDisponibilitatDelete
        serviceCode={serviceCode}
        openDelete={openDelete}
        handleOpen={handleOpenDelete}
        handleLoading={handleLoading}
        handleToday={handleToday}
      />
    </Fragment>
  );
};
export default ServicesDisponibilitat;
