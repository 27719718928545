import { Account, UserAgentApplication } from "msal";
import { LoginType, MsalProvider } from "@microsoft/mgt";
import { MsalInstance } from "../types/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { EnumRol } from "../enum/EnumRol";
import { UserInfoAPI } from "../models/Authorization";

import { isExpired, decodeToken } from "react-jwt";
import { UserManager } from "oidc-client";
import { useContext } from "react";
import { AuthContext } from "../context/auth";

const userAgentApplication = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    redirectUri: window.location.href,
    authority: process.env.REACT_APP_AUTHORITY,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
});

const configurationMsalInstance: MsalInstance = {
  userAgentApplication: userAgentApplication,
  scopes: ["user.read"],
  loginType: LoginType.Redirect,
};

export const requiresInteraction = (errorMessage: string): boolean => {
  if (!errorMessage || !errorMessage.length) return false;

  return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
  );
};

const updateToken = () => {
  const storage = Object.keys(window.localStorage);
  let key: string | undefined;

  key = storage.map((item) => {
    if (item.includes("oidc")) {
      return item;
    }
  })[0];

  userAgentApplication.logout();
};

const validateToken = (token: string) => {
  const test =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlpJd2FJdS10Y2ZUS2k1VVQwQ0t0S2pIOHdaMCIsImtpZCI6IlpJd2FJdS10Y2ZUS2k1VVQwQ0t0S2pIOHdaMCJ9.eyJhdWQiOiJhcGk6Ly9iYWNrb2ZmaWNlLWFwaS5pbjJ0YW50LmNsb3VkIiwiaXNzIjoiaHR0cDovL2ZzLmluMnRhbnQuY2xvdWQvYWRmcy9zZXJ2aWNlcy90cnVzdCIsImlhdCI6MTYyMzE1MDYxNCwibmJmIjoxNjIzMTUwNjE0LCJleHAiOjE2MjMxNTQyMTQsInByZWZlcnJlZF91c2VybmFtZSI6Ik8wMDAwMDFBIiwiUm9sZXMiOiJEb21haW4gVXNlcnMiLCJhcHB0eXBlIjoiUHVibGljIiwiYXBwaWQiOiI2MDcyOTg0Ny1kNzlkLTQ4OTktOWYzNi0zNzUyODIyZjc3M2QiLCJhdXRobWV0aG9kIjoidXJuOm9hc2lzOm5hbWVzOnRjOlNBTUw6Mi4wOmFjOmNsYXNzZXM6UGFzc3dvcmRQcm90ZWN0ZWRUcmFuc3BvcnQiLCJhdXRoX3RpbWUiOiIyMDIxLTA2LTA4VDExOjEwOjExLjAyN1oiLCJ2ZXIiOiIxLjAifQ.Tzv4-55DFgbGJuBN8COOwc25ddHNFFFBPlK26oCo79PcnnwHkxPOTXWiyyrWwmHz2vXThkwHaoWe_YPSfC2I-QIFQGk4pGBvHOjptO_5ADxh2VGxCz5_a4_rZcHXdjNbcWeYQnF_TLODIY14z_wmroZfoPx0P4KD9JJs3KgXWik_dXsw81C5mDW9oJFTIaVmSGIm52S53vi-KgRpT7ofBmPLsTKyxGXVonhqYq7Yr4UPvN17sqmonb42frb2ssUiIWbQ7kUCxhWPZth6GE2Rf01iQ0Lfffxm_Z3MnmeoJBy1dU7ek55lWa6e0fmlTiHNASXPgUUZXI6trUNG9jxvIQ";

  const expired = isExpired(token);
  const decoded = decodeToken(token);

  if (expired) {
    updateToken();
  }

  return token;
};

export const fetchMSGraph = async (
  url: string,
  accessToken: string,
  method: EnumHTTPMethod = EnumHTTPMethod.GET,
  body?: any
): Promise<any> => {
  // const token = validateToken(accessToken);
  //console.log("Access Token", accessToken)

  const response = await fetch(url, {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    
    method: method,
    body: body,
  });

  return response;
};

// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const isEdge = ua.indexOf("Edge/") > -1;
export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  return msie || msie11;
};

export const AUTH_SCOPES = {
  OPENID: "openid",
  OFFLINE_ACCESS: "offline_access",
  PROFILE: "profile",
  USER_READ: "user.read",
};

export const AUTH_REQUESTS = {
  LOGIN: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.USER_READ],
  },
  EMAIL: {
    scopes: [],
  },
  REFRESH_TOKEN: {
    scopes: [process.env.REACT_APP_CLIENT_ID],
  },
};

export const msalInstance = new MsalProvider(configurationMsalInstance);

export const getAuthRoles = (account: any): EnumRol[] => {
  const arrayRoles: EnumRol[] = [];
  if ("roles" in account.idToken) {
    if (Array.isArray(account.idToken.roles)) {
      account.idToken.roles.forEach((role: EnumRol) => {
        arrayRoles.push(role);
      });
    }
  }

  return arrayRoles;
};

export const getAuthRolesADFS = (data: any): EnumRol[] => {
  const arrayRoles: EnumRol[] = [];

  arrayRoles.push(EnumRol.Employee);
  Object.entries(EnumRol).map(([rol, value]) => {
    if (data[`is${rol}`]) {
      arrayRoles.push(value);
    }
  });
  // if (data.isTenantAdmin) {
  //   arrayRoles.push(EnumRol.TenantAdmin);
  // }
  // if (data.isOrganizationAdmin) {
  //   arrayRoles.push(EnumRol.OrganizationAdmin);
  // }
  // if (data.isAreaAdmin) {
  //   arrayRoles.push(EnumRol.AreaAdmin);
  // }
  // if (data.isCrossReader) {
  //   arrayRoles.push(EnumRol.CrossReader);
  // }

  return arrayRoles;
};
