import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Message, Modal } from "semantic-ui-react";

interface RouterParams {
  areaCode: string;
}

interface ModalDeleteAreaProps {
  open: boolean;
  handleOpen: Function;
  handleDelete: Function;
}

const ModalDeleteArea: React.FC<ModalDeleteAreaProps> = ({
  open,
  handleOpen,
  handleDelete,
}: ModalDeleteAreaProps) => {
  const { t } = useTranslation();
  const areaCode = useRouteMatch<RouterParams>().params.areaCode;

  const handleCancel = () => {
    handleOpen(false);
  };

  const handleConfirm = () => {
    handleDelete(areaCode);
    handleOpen(false);
  };

  return (
    <Modal
      id={"modal-container"}
      className={""}
      size={"mini"}
      onClose={handleCancel}
      open={open}
    >
      <Modal.Header>{t("edit.area.delete.btn_title")}</Modal.Header>
      <Modal.Content className={"modal-content br0"}>
        <div className={"modal-text"}>
          <p>{t("edit.area.delete.message")}</p>
          <Message className={"modal-message"} color={"red"}>
            <p className={"text-center"}>{areaCode}</p>
          </Message>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel} negative>
          {t("btn.cancel")}
        </Button>
        <Button onClick={handleConfirm} positive>
          {t("btn.delete")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalDeleteArea;
