import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useError } from "../../../hooks/useError";
import { AuthContext } from "../../../context/AuthContext";
import LayoutContainer from "../../../components/Layout/LayoutContainer";
import { LayoutContext } from "../../../context/LayoutContext";
import ButtonListAdministration from "./components/ButtonListAdministration";
import { OrganitzationInfo } from "../../../models/OrganitzationInfo";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export type UserService = {
  active: boolean;
  areaCode: string;
  code: string;
  displayName: string;
};

interface Button {
  icon?: string;
  message: string;
  onClick: any;
  disabled?: boolean;
}

export interface renderList {
  service: UserService;
  count: number;
}

interface Props {
  organizations: OrganitzationInfo[];
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const userCode = authContext.account.profile.code;
  const { setTitle, setBreadcrumb } = layoutContext;
  const { error, setError } = useError();
  const { t } = useTranslation();

  return (
    <>
      <LayoutContainer.Main>
        <h2>{t("administration.organization_select")}</h2>
        <table className="table mt-4">
          <tbody>
            {props.organizations.length > 0 ? (
              props.organizations.map((organitzation) => (
                <tr>
                  <td className="p-3">{organitzation.code}</td>
                  <td className="p-3">
                    <p>{organitzation.displayName}</p>
                  </td>
                  <td className="text-right p-3">
                    <Link
                      to={`/administration/organization/edit/${organitzation.code}`}
                    >
                      <Icon name="eye" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("organitzation.panel_org.no_found")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </LayoutContainer.Main>
      <LayoutContainer.Article>
        <ButtonListAdministration />
      </LayoutContainer.Article>
    </>
  );
};
