import React, { FC, ReactElement, useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import { MeetingsCount } from "../../../assets/meetings";
import { Service } from "../../../models/Service";
import ItemService from "./ItemService";
interface ListServiceProps {
  arrayService: Service[];
  areaCode: string;
  countServiceMeeting: MeetingsCount[];
}

export interface ServiceCount extends Service {
  count: number;
}

const ListService: FC<ListServiceProps> = ({
  arrayService,
  areaCode,
  countServiceMeeting,
}: ListServiceProps): ReactElement => {
  const [arrayServiceCount, setArrayServiceCount] = useState<ServiceCount[]>(
    []
  );
  useEffect(() => {
    const array: ServiceCount[] = [];
    if (countServiceMeeting.length > 0) {
      arrayService.map((service: Service) => {
        let serviceCount: ServiceCount = {
          ...service,
          count: 0,
        };
        countServiceMeeting.map((c: MeetingsCount) => {
          if (service.code === c.code) {
            serviceCount = {
              ...service,
              count: c.count,
            };
          }
        });
        array.push(serviceCount);
      });
    } else {
      arrayService.map((service: Service) => {
        let serviceCount: ServiceCount = {
          ...service,
          count: 0,
        };
        array.push(serviceCount);
      });
    }
    setArrayServiceCount(array);
  }, [arrayService, countServiceMeeting]);
  return (
    <List divided className="list-area flex-grow-1">
      {arrayServiceCount.map((service: ServiceCount, index) => {
        return (
          <ItemService key={index} service={service} areaCode={areaCode} />
        );
      })}
    </List>
  );
};

export default ListService;
