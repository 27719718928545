import React from 'react';
import { Button as SemanticUiButton, } from "semantic-ui-react";
interface Props {
  disabled?: boolean;
  onClick: any;
  icon: string;
};

const Button = (props: Props) => {
  return (<SemanticUiButton
    color={!props.disabled ? "red" : "grey"}
    disabled={props.disabled}
    size={"small"}
    onClick={props.onClick}
    icon={props.icon}
  />);
};

export default Button;