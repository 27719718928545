import { Attendee } from "./Attendee";
import { ChangeHistory } from "./ChangeHistory";
import { ISubjectAdapter } from "./event.models";

export type EventObject = {
  slotId: string;
  start: string;
  end: string;
  createDate: string;
  lastModifiedDate: string;
  status: string;
  videoRoomUrl: string;
  serviceCode: string;
  observations: string;
  comment: string;
  attendees: Attendee[];
  isOtpAvailable?: boolean;
  changeHistory: ChangeHistory[];
  code: string;
  isDocumentationAvailable: boolean;
  subjects1?: ISubjectAdapter[];
  subjects2?: ISubjectAdapter[];
  subjects3?: ISubjectAdapter[];
};

export const defaultEventObject: EventObject = {
  slotId: "",
  start: "",
  end: "",
  createDate: "",
  lastModifiedDate: "",
  status: "",
  videoRoomUrl: "",
  serviceCode: "",
  observations: "",
  attendees: [],
  changeHistory: [],
  comment: "",
  code: "",
  isOtpAvailable: false,
  isDocumentationAvailable: false,
  subjects1: [],
  subjects2: [],
  subjects3: []
};
