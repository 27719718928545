import { fetchMSGraph } from "../util/auth";

import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";

export async function logoutMeetingServant(token: any) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/LogoutMeetingServant/`;

  const response = await fetchMSGraph(endpoint, token.Value);

  return response;
}
