import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/Card/Card";
interface Props {}

export default ({}: Props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body>
        <h2>{t("home.card.no_event_assigned")}</h2>
        <hr />
        <h3>{t("home.card.retrieve_event_using_button")}</h3>
      </Card.Body>
    </Card>
  );
};
