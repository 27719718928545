import React from "react";
import { useTranslation } from "react-i18next";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
import { Checkbox, Form } from "semantic-ui-react";
interface Props {
  sendExtern: any;
  onChangeFields: any;
  updateSendExternNotifications: any;
  organitzationNotification: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Field>


        <Checkbox
          label={t("area.personalitzation.send_extern")}
          labelPlacement="start"
          toggle
          defaultChecked={props.sendExtern}
          disabled={!props.organitzationNotification}
          onChange={props.updateSendExternNotifications}
        />
      </Form.Field>
    </div>
  );
};
