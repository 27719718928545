import { Languages } from "./Languages";

export type ServiceInfo = {
  areaCode: string;
  displayName: string;
  code: string;
  uri: string;
  email: string;
  languages: Languages[];
  active: boolean;
};

export const defaultServiceInfo: ServiceInfo = {
  areaCode: "",
  displayName: "",
  code: "",
  uri: "",
  email: "",
  languages: [],
  active: false,
};
