import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import LayoutContainer from "../../../components/Layout/LayoutContainer";

import Field from "../../../components/Field/Field";
import { Button, Form } from "semantic-ui-react";
import { checkForm } from "../../../components/Field/validations";
import ButtonListAdministration from "./components/ButtonListAdministration";

export type UserService = {
  active: boolean;
  areaCode: string;
  code: string;
  displayName: string;
};

export interface renderList {
  service: UserService;
  count: number;
}

interface Props {
  handleSubmit: any;
  form: any;
  handleInputChange: any;
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <LayoutContainer.Main>
        <Form className="border-0" style={{ boxShadow: "none" }}>
          {props.form.map((_: any) => {
            return (
              <Field
                format={_.format}
                required={_.required}
                label={_.label}
                title={t(`administration.add_organization.form.${_.key}`)}
                name={_.key}
                value={_.value}
                onChange={props.handleInputChange}
                textError={t(
                  `administration.add_organization.form.${_.key}_error`
                )}
              />
            );
          })}
        </Form>

        <Button
          disabled={!checkForm(props.form)}
          positive={checkForm(props.form)}
          icon="checkmark"
          content={t("administration.add_organization.button_create")}
          onClick={() => {
            checkForm(props.form) && props.handleSubmit();
          }}
        />
      </LayoutContainer.Main>
      <LayoutContainer.Article>
        <ButtonListAdministration />
      </LayoutContainer.Article>
    </>
  );
};
