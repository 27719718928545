import React from "react";
import { useTranslation } from "react-i18next";
import BOTableMeeting from "../../../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting";
import EmptyStateMeeting from "../EmptyStateMeeting";
interface Props {
  meetings: any[];
  contentMeeting?: (string | any)[];
  servicesRefresh?: () => void;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <h2>{t("home.assigned_meetings")}</h2>
      {props.meetings.length > 0 ? (
        <BOTableMeeting {...props} />
      ) : (
        <EmptyStateMeeting />
      )}
    </div>
  );
};
