import React from "react";
import { Tab } from "semantic-ui-react";

import "./styles/component.css"

interface TabElement{
    title: string,
    children: any
}

interface IProps{
    tabs: TabElement[]
}

const TabList = ({ tabs }: IProps) => {

    const panes = tabs.map(
        (tab) => { 
            return {
                menuItem: tab.title,
                render: () => <Tab.Pane>{tab.children}</Tab.Pane>
            } 
        })

    return (
        <Tab 
            menu={{ secondary: true, pointing: true }}
            panes={panes}
        />
    )
}

export default TabList;