import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Dropdown } from "semantic-ui-react"; // Importa Dropdown de semantic-ui-react
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";

interface Props {
  organitzationURL: any;
  setOrganitzationURL: any;
  isRecording: any;
  updateRecordingsForOrganization: any;
  organitzationURLInformers: any;
  setOrganitzationURLInformers: any;
  sendReminders: any;
  updateSendingReminders: any;
  setReminderTime: any;
  reminderTime: any;
  onChangeFields: any;
  
}

export default (props: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  // Opciones para el Dropdown
  const reminderOptions = [
    { key: '24', text: '24', value: 24 },
    { key: '48', text: '48', value: 48 }
  ];

  return (
    <div>
      <TextField
        title={t("organitzation.personalitzation.url")}
        value={props.organitzationURL}
        onChange={(smsWithLinktoEvent: any) =>
          props.setOrganitzationURL(smsWithLinktoEvent)
        }
        id="entityOrganitzationURL"
      />

      <TextField
        title={t('organitzation.personalitzation.url_informers')}
        value={props.organitzationURLInformers}
        onChange={(smsWithLinktoEvent: any) => props.setOrganitzationURLInformers(smsWithLinktoEvent)}
        id='entityOrganitzationURL'
      />

      {show &&
        <Form.Field>
          <label style={{ marginBottom: "10px", marginTop: "10px" }}>
            {t("organitzation.personalitzation.recording")}
          </label>
          <Checkbox
            toggle
            defaultChecked={props.isRecording}
            onChange={props.updateRecordingsForOrganization}
          />
        </Form.Field>
      }

      <label><strong>{t("organitzation.personalitzation.sending_reminders")}</strong></label>
      <Form.Field>
        <Checkbox
          labelPlacement="start"
          toggle
          defaultChecked={props.sendReminders}
          onChange={props.updateSendingReminders}
        />
      </Form.Field>


      <label><strong>{t("organitzation.personalitzation.reminder_time")}</strong></label>
      <Form.Field style={{ display: 'flex', alignItems: 'center', width: "125px" }}> 
        <Dropdown
          fluid
          selection
          options={reminderOptions.map(option => ({
            key: option.key,
            text: option.text,
            value: option.value
          }))}
          onChange={(e, { value }) => props.setReminderTime(value as number)}
          value={props.reminderTime}
        />
        <span style={{ marginLeft: '5px' }}>{t("organitzation.personalitzation.hours")}</span> 
      </Form.Field>


    </div>
  );
};


