import React from "react";
import Text from "../atomic_components/Text";


interface Props {
  value: any[];
}

export default (props: Props) => {
  return <Text>{props.value?.[0]?.fullName}</Text>;
};
