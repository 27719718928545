import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getNextQueue,
  getService,
  getUserServices,
  serviceGetInfo,
} from "../../../../assets/api";
import { getEvent, selectEventsMeetings } from "../../../../assets/events";
import { AuthContext } from "../../../../context/AuthContext";
import { EnumMeetingStatus } from "../../../../enum/EnumMeetingStatus";
import { AuthorizationModel } from "../../../../models/Authorization";
import { Queue } from "../../../../types/dashboard";
import { UserService } from "../../Dashboard";
import Screen from "./Screen";
import { toast } from "react-toastify";

interface Props {
  meetings: any[];
  updateMeetingAssigned: any;
  refreshMeetingList: any;
  service: any;
  getNextEvent: any;
  contentMeeting?: (string | any)[];
  disabledMeeting: (x: any) => boolean;
  onClickMeeting: (x: any) => void;
}

export default (props: Props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const updateMeetingAssigned = async () => {
    const { backoffice, shared } = await authContext.getTokenForScopes();
    
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));

    const name = account.profile.code;
    
    const responseServices: Response = await getUserServices(name, backoffice);
    
    if (!responseServices.ok) throw new Error(t("home.error.userServices"));
    
    const dataServices: UserService[] = await responseServices.json();
    props.updateMeetingAssigned(dataServices);
  };
  const servicesRefresh = () => {
    updateMeetingAssigned();
    props.refreshMeetingList();
  };
  return (
    <Screen
      {...props}
      meetings={props.meetings.filter((_) => _.serviceCode == props.service)}
      servicesRefresh={servicesRefresh}
    />
  );
};
