import React from "react";
import { Button } from "../Button/Button";
import { TabConfig } from "./types";

interface Props {
  tabsConfig: TabConfig[];
  onClickTab: (x: string) => void;
  currentAccessor: string;
  styleTabSelected: any;
  styleTabUnselected: any;
  className?: string;
}

export default (props: Props) => {
  return (
    <div className={`w-100 ${props.className || ""}`}>
      <div className="w-100 row">
        {props.tabsConfig.map((tabConfig: TabConfig) => {
          const additionalStyles = {
            width: `${(100 / props.tabsConfig.length).toFixed()}%`,
            ...(tabConfig.accessor == props.currentAccessor
              ? props.styleTabSelected
              : props.styleTabUnselected),
          };
          return (
            <div
              style={additionalStyles}
              onClick={() => props.onClickTab(tabConfig.accessor)}
            >
              <h3 style={{ fontSize: 18, fontWeight: 600 }}>
                {tabConfig.title}
              </h3>
            </div>
          );
        })}
      </div>
      <div className="mt-4">
        {props.tabsConfig.map((_) => (
          <div
            style={{
              display: _.accessor == props.currentAccessor ? "block" : "none",
              marginBottom: 10,
            }}
          >
            {_.contentView}
          </div>
        ))}
      </div>
    </div>
  );
};
