import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import {
  fetchPostSlots,
  selectPostSlots,
  cleanPostSlots,
} from "../../assets/slots";
import { formarDateMoment } from "../../util/date";
import { SlotInfo } from "../../models/SlotInfo";
import moment from "moment";
import BOFormCalendar from "./BOFormCalendar";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import i18n from "../../configs/i18n";

export type DataDisponibilitat = {
  serviceCode: string;
  slotsInfo: SlotInfo[];
};

export interface BOModalDisponibilitatProps {
  serviceCode: string;
  openAdd: boolean;
  handleOpen: Function;
  handleToday: Function;
}

const BOModalDisponibilitat: FC<BOModalDisponibilitatProps> = ({
  serviceCode,
  openAdd,
  handleOpen,
  handleToday,
}: BOModalDisponibilitatProps): ReactElement => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const responsePostSlots = useSelector(selectPostSlots);
  const [open, setOpen] = useState<boolean>(openAdd);

  useEffect(() => {
    setOpen(openAdd);
  }, [openAdd]);

  useEffect(() => {
    
  }, [t, i18n.language]);

  useEffect(() => {
    (async () => {
      if (responsePostSlots.response !== null) {
        console.log("RESPONSE POST SLOTS:", responsePostSlots);
        if (responsePostSlots.response === true) {
          toast(t("message.availability.create.success"), { type: "success"});
          await handleOpen(false);
          await handleToday();
        } else {
          if(responsePostSlots.disponibility_error === true) {
            toast(t("message.availability.create.error_dispo"), { type: "error"});
            await handleOpen(false);
            await handleToday();
          }
          else if (responsePostSlots.max_excedeed_error === true) {
            toast(t("message.availability.create.error_max_dispo"), { type: "error"});
            await handleOpen(false);
            await handleToday();
          }
          else {
            toast(t("message.availability.create.error"), { type: "error"});
            await handleOpen(false);
            await handleToday();
          }
        }
      }
    })();
  }, [responsePostSlots]);

  useEffect(() => {
    return () => {
      dispatch(cleanPostSlots());
    };
  }, []);

  const submit = async (values: any) => {
    const start = moment(values.dayStart);
    delete values["dayStart"];
    const end = moment(values.dayEnd);
    delete values["dayEnd"];
    const day = end.diff(start, "days");
    const arrayDay: SlotInfo[] = [];
    for (let index = 0; index <= day; index++) {
      let a = start.clone();
      a = a.add(index, "days");
      let p = formarDateMoment(a, "");
      let c = a.format("d").toString();
      let slot: SlotInfo = {
        day: p,
        ...values,
      };

      c !== "6" && c !== "0" ? arrayDay.push(slot) : console.log(p);
    }
    await dispatch(fetchPostSlots(token, serviceCode, arrayDay));
  };

  return (
    <Modal
      id={"modal-disponibilitat"}
      size={"small"}
      onClose={() => handleOpen(false)}
      open={open}
    >
      <Modal.Header>
        {t("service_availability.modal_add_availability.title")}
      </Modal.Header>
      <Modal.Content className={"br0"}>
        <BOFormCalendar
          handleSubmit={submit}
          handleOpen={handleOpen}
        ></BOFormCalendar>
      </Modal.Content>
    </Modal>
  );
};
export default BOModalDisponibilitat;
